import { Collapse } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import styles from './help.module.sass'
import AeroButton from '../../AeroComponents/Button'
import { DETAILS_HEADS, DETAILS_ITEMS, FAQ_HEADS } from './constants'

import { pushEventToMixpanel } from '../../Services/MixpanelService'
import MobileHeader from '../../AeroComponents/MobileHeader'
const { Panel } = Collapse

const Help = ({ handleHamburger }) => {
	const [currentKey, setCurrentKey] = useState(1)
	const updateKey = key => setCurrentKey(key)

	useEffect(() => {
		pushEventToMixpanel('Help section', {})
	}, [])

	const sendMixPanelSupportTicketEvent = () => {
		pushEventToMixpanel('Service desk portal opened', {})
	}

	return (
		<div className='module-container'>
			<MobileHeader header='Help' handleHamburger={handleHamburger} />
			<div style={{ textAlign: 'center' }}>
				<div style={{ fontSize: '32px' }} className='module-title'>
					Hello! How can we help you?
				</div>
				<div className={styles['subheading']}>
					Facing challenges or have feedback to share? Create a support ticket{' '}
					<a target='_blank' href='https://aerologix.atlassian.net/servicedesk/customer/portal/3-' onClick={sendMixPanelSupportTicketEvent}>
						here
					</a>{' '}
					or drop an email to <a href='mailto:maps.support@aerologix.com'>maps.support@aerologix.com</a>{' '}
				</div>
			</div>

			<div className={styles['content-wrapper']}>
				<div className={styles['faq-wrapper']}>
					<div style={{ fontSize: '24px', fontWeight: '700', marginLeft: '17px', marginBottom: '10px' }}>FAQs</div>
					<Collapse onChange={updateKey} activeKey={currentKey} accordion ghost bordered={false} expandIconPosition='end' style={{ background: '#F5F5F5' }}>
						{FAQ_HEADS?.map(item => (
							<Panel
								header={
									<span style={{ fontSize: '16px' }}>
										{Number(currentKey) === Number(item.key) ? (
											<strong>
												<u>{item.headerText}</u>
											</strong>
										) : (
											item.headerText
										)}
									</span>
								}
								key={item.key}
							>
								{item.panelItems?.map(panelItem => (
									<div style={{ marginBottom: '10px' }} key={panelItem.key}>
										<AeroButton otherstyles={{ whiteSpace: 'normal', textAlign: 'left' }} type='link' size='small'>
											{panelItem.text}
										</AeroButton>
									</div>
								))}
							</Panel>
						))}
					</Collapse>
				</div>
				<div className={styles['detail-wrapper']}>
					<div className={styles['detail-heading']}>{DETAILS_HEADS[currentKey]}</div>
					{DETAILS_ITEMS[currentKey]?.map(item => (
						<div key={item.key}>
							<div className={styles['detail-question']}>{item.questionText}</div>
							<div className={styles['detail-answer']}>{item.answerText}</div>
						</div>
					))}
					{currentKey === '2' ? (
						<div className={styles['note-wrapper']}>
							<ExclamationCircleFilled className={styles['warning-icon']} />
							<div>
								<div style={{ fontWeight: '700', fontSize: '16px' }}>Note:</div>
								<div style={{ fontWeight: '500', fontSize: '14px' }}>
									Canceling will halt the autopayment process, but your subscription will remain active until the expiry date of your plan. Refunds will not be
									provided. It's crucial to download or back up any essential data or projects before your plan expires, as access to specific features and
									storage may be restricted or terminated after expiration.
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default Help
