import React from 'react'
import styles from '../subscription.module.sass'
import { Form, Radio } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import BillingCycleBox from './billing-cycle-box'
import AeroInput from '../../../AeroComponents/Input'
import AeroButton from '../../../AeroComponents/Button'

const PaidPlanModalRightBillingContent = props => {
	const {
		updateDuration,
		currentDuration,
		currentPaidPlan,
		discountList,
		freeTrialAvailed,
		planStartDate,
		freeTrialStartDate,
		freeTrialEndDate,
		form,
		captureDiscountCode,
		discountPercentage,
		checkDiscountCode,
		updateRightContentPhase,
		country,
		planCharge,
		planEndDate,
	} = props

	return (
		<>
			<div className={styles['billing-cycle-title']}>Select Billing Cycle</div>
			<Radio.Group onChange={updateDuration} value={currentDuration === 'YEARLY' ? 'annual' : 'monthly'}>
				<div>
					<BillingCycleBox
						type='annual'
						freeTrialAvailed={freeTrialAvailed}
						amount={currentPaidPlan.charge.YEARLY}
						discountPercentage={discountList.filter(item => item.type === currentPaidPlan.type)[0].percentage}
					/>
					<BillingCycleBox type='monthly' freeTrialAvailed={freeTrialAvailed} amount={currentPaidPlan.charge.MONTHLY} />
				</div>
			</Radio.Group>
			<div style={{ marginTop: '20px' }}>
				{freeTrialAvailed ? (
					<>
						Subscription will start on <strong>{planStartDate.format('MMMM D, YYYY')}</strong> and will renew on <strong>{planEndDate.format('MMMM D, YYYY')}</strong>.{' '}
					</>
				) : (
					<>
						Your free trial will begin on <strong>{freeTrialStartDate.format('MMMM D, YYYY')}</strong> and will end on{' '}
						<strong>{freeTrialEndDate.format('MMMM D, YYYY')}</strong>.
					</>
				)}
			</div>
			{freeTrialAvailed ? null : (
				<div style={{ marginTop: '20px' }}>
					You can cancel anytime before <strong>{freeTrialEndDate.format('MMMM D, YYYY')}</strong> to avoid being charged and we'll send an email reminder 7 days before
					the trial ends.
				</div>
			)}

			<div style={{ marginTop: '20px' }}>
				<Form form={form} layout='vertical'>
					<Form.Item name='discount' label='Discount Code (optional)'>
						<AeroInput
							onChange={captureDiscountCode}
							placeholder='Enter code here'
							suffix={
								<>
									{discountPercentage ? (
										<span style={{ color: '#6CBE44' }}>
											<CheckOutlined /> Code applied
										</span>
									) : (
										<span onClick={checkDiscountCode} style={{ color: '#00B3FF', cursor: 'pointer' }}>
											Apply Code
										</span>
									)}
								</>
							}
						/>
						<div style={{ fontSize: '12px', color: '#545454' }}>*Applicable on first billing cycle</div>
					</Form.Item>
				</Form>
			</div>
			<div style={{ marginTop: '30px' }}>
				<AeroButton onClick={() => updateRightContentPhase(2)} block type='primary'>
					{freeTrialAvailed ? <>Pay USD {country === 1 ? `${(planCharge + planCharge * 0.1).toFixed(2)} (including 10% GST)` : planCharge}</> : 'Try for USD 0'}
				</AeroButton>
			</div>
			<div style={{ textAlign: 'center' }}>
				USD<span style={{ fontSize: '32px', fontWeight: '600' }}>{country === 1 ? (planCharge + planCharge * 0.1).toFixed(2) : planCharge}</span> after{' '}
				{freeTrialAvailed ? planEndDate.format('MMMM D, YYYY') : 'free trial ends'}
			</div>
		</>
	)
}

export default PaidPlanModalRightBillingContent
