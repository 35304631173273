import React from 'react'
import { NavLink } from 'react-router-dom'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

const MobileBarItem = props => {
	const { menu, handleHamburger, currentPathName } = props
	return (
		<NavLink key={menu.pathName} style={{ display: 'flex' }} to={menu.pathName}>
			<div onClick={handleHamburger} className={menu.pathName === currentPathName ? 'mobile-sidebar-menu-selected' : 'mobile-sidebar-menu'}>
				<AeroImage
					otherstyles={{ position: 'relative', bottom: '3px' }}
					src={IMAGES_SRC[menu.pathName === currentPathName ? `${menu.icon}_SELECTED` : menu.icon]}
					alt='menu-icon'
				/>
				<span style={{ marginLeft: '15px' }}>{menu.displayValue}</span>
			</div>
		</NavLink>
	)
}

export default MobileBarItem
