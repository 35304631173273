import React, { useEffect, useRef, useState } from 'react'
import { Form, Space, notification, Skeleton, Divider } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAllMaps, createProcessing, shareProcessing, deleteProcessing } from '../../Stores/MyMaps/action'
import styles from './mymaps.module.sass'
import MyMapsHeader from './components/my-maps-header'
import MapCard from './components/map-card'
import AeroModal from '../../AeroComponents/Modal'
import AeroInput from '../../AeroComponents/Input'
import AeroButton from '../../AeroComponents/Button'
import { goTo } from '../../Services/NavigationService'
import { validateEmailAddress } from '../../Services/UtilityService'
import GeneralConfirmationContent from '../../AeroComponents/Modal/general-confirmation-content'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import ProcessedDownloadModalContent from './components/processed-download-modal-content'
import AeroNotificationContent from '../../AeroComponents/Notification'
import GracePeriodAlert from '../Subscription/alerts/grace-period-alert'
import GracePeriodNoteAlert from '../Subscription/alerts/grace-period-note-alert'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import MobileHeader from '../../AeroComponents/MobileHeader'

const MyMaps = props => {
	const {
		profileData,
		getAllMaps,
		allMapsData,
		createProcessing,
		processingCreated,
		shareProcessing,
		processingShared,
		deleteProcessing,
		processingDeleted,
		handleHamburger,
	} = props
	const [form] = Form.useForm()
	const [newMapModal, setNewMapModal] = useState(false)
	const [allMapsDetails, setAllMapsDetails] = useState(null)
	const [allMapsCount, setAllMapsCount] = useState(0)
	const [waitForApi, setWaitForApi] = useState(false)
	const [currentCard, setCurrentCard] = useState(null)
	const [shareModal, setShareModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [reports, setReports] = useState(null)
	const [archivedProducts, setArchivedProducts] = useState(null)
	const [models, setModels] = useState(null)
	const [cloud, setCloud] = useState(null)
	const [curves, setCurves] = useState(null)
	const [orthomosaic, setOrthomosaic] = useState(null)
	const [others, setOthers] = useState(null)
	const [downloadProcessedDataModal, setDownloadProcessedDataModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [currentMapPage, setCurrentMapPage] = useState(1)
	const searchRef = useRef()

	const toggleNewMapModal = () => {
		setNewMapModal(prevState => !prevState)
	}

	useEffect(() => {
		setWaitForApi(true)
		getAllMaps({ pageNumber: currentMapPage, pageSize: 12, searchToken: '' })
	}, [])

	useEffect(() => {
		if (allMapsData && waitForApi) {
			setWaitForApi(false)
			setLoading(false)
			const prevValue = allMapsDetails ? allMapsDetails : []

			if ((prevValue.length && currentMapPage === 1) || searchRef.current?.input.value) {
				setAllMapsDetails(allMapsData.data)
				setAllMapsCount(allMapsData.count)
			} else {
				setAllMapsDetails([...prevValue, ...allMapsData.data])
				setAllMapsCount(allMapsData.count)
			}
		}
	}, [allMapsData])

	useEffect(() => {
		if (processingCreated && waitForApi) {
			form.resetFields()
			setWaitForApi(false)
			pushEventToMixpanel('New Map', {})
			goTo(`edit-map/${processingCreated.processingId}`)
		}
	}, [processingCreated])

	useEffect(() => {
		if (processingShared && waitForApi) {
			pushEventToMixpanel('Map shared', {
				'Map Name': currentCard.processingName,
			})
			form.resetFields()
			setWaitForApi(false)
			setShareModal(false)
			notification.open({
				message: <AeroNotificationContent content='Map successfuly shared' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		}
	}, [processingShared])

	useEffect(() => {
		if (processingDeleted && waitForApi) {
			pushEventToMixpanel('Map Deleted', {
				'Map Name': currentCard.processingName,
			})
			setWaitForApi(false)
			setDeleteModal(false)
			const arrIndex = allMapsDetails.findIndex(item => item.processingId === currentCard.processingId)
			let data = allMapsDetails
			data.splice(arrIndex, 1)
			setAllMapsDetails(data)
			setAllMapsCount(prevState => prevState - 1)
			notification.open({
				message: <AeroNotificationContent content='File deleted successfully' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		}
	}, [processingDeleted])

	const recordName = values => {
		const body = {
			nm_processing: values.nm_processing,
			ab_processing_type: 'FULL_PROCESSING',
			bl_gcp: 'false',
		}
		setWaitForApi(true)
		createProcessing(body)
	}

	const recordEmail = values => {
		const body = {
			emailId: values.email,
			processingId: currentCard.processingId,
		}
		setWaitForApi(true)
		shareProcessing(body)
	}

	const openShareModal = val => {
		setCurrentCard(val)
		setShareModal(true)
	}

	const openDeleteModal = val => {
		setCurrentCard(val)
		setDeleteModal(true)
	}

	const callDelete = () => {
		setWaitForApi(true)
		deleteProcessing({ processingId: currentCard.processingId })
	}

	const openProcessedDataDownload = processingId => {
		var myHeaders = new Headers()

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		}

		fetch(`${process.env.REACT_APP_MAPPA_BE_BASE_URL}projects/processings/${processingId}/list-reports-shared/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				setReports(result.data.reports)
			})
			.catch(error => console.log('error', error))
		fetch(`${process.env.REACT_APP_MAPPA_BE_BASE_URL}projects/processings/${processingId}/list-products-shared/`, requestOptions)
			.then(response => response.text())
			.then(result => {
				let products = JSON.parse(result).data.products
				products = products.sort((a, b) => a.name.localeCompare(b.name))
				const archivedProducts = JSON.parse(result).archived_products
				const models = products.filter(products => products.name.includes('Model'))
				const curves = products.filter(products => products.name.includes('Curves'))
				const cloud = products.filter(products => products.name.includes('Cloud'))
				const orthomosaic = products.filter(products => products.name.includes('Orthomosaic'))
				const others = products.filter(
					products => !products.name.includes('Model') && !products.name.includes('Curves') && !products.name.includes('Cloud') && !products.name.includes('Orthomosaic'),
				)
				setArchivedProducts(archivedProducts)
				setModels(models)
				setCurves(curves)
				setCloud(cloud)
				setOrthomosaic(orthomosaic)
				setOthers(others)
				setDownloadProcessedDataModal(true)
			})
			.catch(error => console.log('error', error))
	}

	const loadMoreData = () => {
		if (loading) return
		setLoading(true)
		setCurrentMapPage(prevState => prevState + 1)
		setWaitForApi(true)
		getAllMaps({ pageNumber: currentMapPage + 1, pageSize: 12, searchToken: '' })
	}

	const searchMaps = () => {
		const val = searchRef.current.input.value
		if (val) {
			setWaitForApi(true)
			getAllMaps({ pageSize: 12, searchToken: val })
		}
	}

	const goToBuyMode = () => goTo('subscription', true)

	const sendMixpanelDownloadEvent = (name, type) => {
		pushEventToMixpanel('Output Downloaded', {
			'Map Name': name,
			'Output type': type,
		})
	}

	return (
		<>
			<div className='module-container'>
				<MobileHeader header='My Maps' handleHamburger={handleHamburger} />
				{profileData?.active_plan?.status === 'GRACE' && profileData?.endDate < moment() ? (
					<div style={{ display: 'flex' }}>
						<GracePeriodAlert
							cancellationDate={profileData?.active_plan?.cancellationDate}
							gracePeriodEndDate={profileData?.active_plan?.gracePeriodEndDate}
							goToBuyMode={goToBuyMode}
						/>
						<GracePeriodNoteAlert gracePeriodEndDate={profileData?.active_plan?.gracePeriodEndDate} />
					</div>
				) : null}
				{profileData ? (
					<MyMapsHeader profileData={profileData} toggleNewMapModal={toggleNewMapModal} name={profileData.first_name} searchMaps={searchMaps} searchRef={searchRef} />
				) : null}

				<div className={styles['maps-heading']}>Processed Maps</div>

				<div id='mapsDiv' className={styles['maps-wrapper']} style={{ overflow: 'auto', height: '75vh' }}>
					{allMapsDetails && (
						<InfiniteScroll
							dataLength={allMapsDetails.length}
							next={() => loadMoreData()}
							hasMore={allMapsDetails.length < allMapsCount}
							loader={
								<Skeleton
									style={{ margin: '20px' }}
									paragraph={{
										rows: 3,
									}}
									active
								/>
							}
							endMessage={<Divider></Divider>}
							scrollableTarget={'mapsDiv'}
						>
							<Space size='large' style={{ display: 'flex', flexWrap: 'wrap' }}>
								{allMapsDetails.map(item => (
									<MapCard
										demo={item.userId === 0}
										openProcessedDataDownload={openProcessedDataDownload}
										openDeleteModal={openDeleteModal}
										openShareModal={openShareModal}
										key={item.processingId}
										data={item}
										profileData={profileData}
									/>
								))}
							</Space>
						</InfiniteScroll>
					)}
				</div>
			</div>
			<AeroModal title='Create Map' footer={false} visible={newMapModal} onCancel={toggleNewMapModal}>
				<Form form={form} onFinish={recordName} layout='vertical'>
					<Form.Item
						name='nm_processing'
						rules={[
							{
								required: true,
								message: 'File name is required!',
								whitespace: true,
							},
						]}
						label='File Name'
					>
						<AeroInput placeholder='Type here' />
					</Form.Item>
					<Form.Item style={{ marginTop: '20px' }}>
						<AeroButton htmlType='submit' type='primary' block>
							Create and Continue
						</AeroButton>
					</Form.Item>
				</Form>
			</AeroModal>
			{currentCard && (
				<AeroModal title={`Share '${currentCard.processingName}'`} footer={false} visible={shareModal} onCancel={() => setShareModal(false)}>
					<div className={styles['warning-banner-wrapper']}>
						<ExclamationCircleFilled style={{ fontSize: '30px' }} />
						<div style={{ marginLeft: '20px', fontSize: '14px' }}>
							When creating a shared link, anyone on the web with access to the link will have access to its processing.
						</div>
					</div>
					<Form form={form} onFinish={recordEmail} layout='vertical'>
						<Form.Item
							name='email'
							rules={[
								{
									required: true,
									message: 'Email is required!',
									whitespace: true,
								},
								{ validator: validateEmailAddress },
							]}
							label='Email ID'
						>
							<AeroInput placeholder='Type here' />
						</Form.Item>
						<Form.Item style={{ marginTop: '20px' }}>
							<AeroButton htmlType='submit' type='primary' block>
								Share
							</AeroButton>
						</Form.Item>
					</Form>
				</AeroModal>
			)}
			{currentCard && (
				<AeroModal footer={false} visible={deleteModal} onCancel={() => setDeleteModal(false)}>
					<GeneralConfirmationContent
						primaryBtnAction={() => setDeleteModal(false)}
						primaryBtnText='No, Cancel'
						secondaryBtnAction={callDelete}
						secondaryBtnText='Yes, Delete'
						topAsset={IMAGES_SRC.BIG_EXCLAMATION_ICON}
						heading='Are you sure you want to delete this map?'
						description='You will no longer be able to view this map again. '
					/>
				</AeroModal>
			)}
			<AeroModal title='Download Data' footer={false} onCancel={() => setDownloadProcessedDataModal(false)} visible={downloadProcessedDataModal}>
				<ProcessedDownloadModalContent
					models={models}
					curves={curves}
					cloud={cloud}
					orthomosaic={orthomosaic}
					others={others}
					reports={reports}
					sendMixpanelDownloadEvent={sendMixpanelDownloadEvent}
					processingName={currentCard?.processingName}
				/>
			</AeroModal>
		</>
	)
}

const mapStateToProps = state => {
	return {
		profileData: state.UserSessionReducer.profileData,
		allMapsData: state.MyMapsReducer.allMapsData,
		processingCreated: state.MyMapsReducer.processingCreated,
		processingShared: state.MyMapsReducer.processingShared,
		processingDeleted: state.MyMapsReducer.processingDeleted,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllMaps: bindActionCreators(getAllMaps, dispatch),
		createProcessing: bindActionCreators(createProcessing, dispatch),
		shareProcessing: bindActionCreators(shareProcessing, dispatch),
		deleteProcessing: bindActionCreators(deleteProcessing, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MyMaps)
