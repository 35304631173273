import React, { useState } from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { goTo } from '../../Services/NavigationService'
import AeroModal from '../../AeroComponents/Modal'
import ContactSupportContent from './components/contact-support-content'

const ForgotPasswordSent = () => {
	const [contactSupportModal, setContactSupportModal] = useState(false)
	const toggleContactSupport = () => setContactSupportModal(prevState => !prevState)
	return (
		<>
			<div style={{ textAlign: 'center', padding: '40px' }}>
				<AeroImage alt='email-country' src={IMAGES_SRC.EMAIL_SENT_ICON} />
				<div className='signup-success-title recovery-form'>We've sent you an email with a new password</div>
				<div className='signup-success-subtitle' style={{ fontWeight: '400' }}>
					Check your spam and promotions folder if it doesn't appear in your main mailbox.
				</div>
				<div className='signup-success-footer'>
					Didn’t receive mail? &nbsp;
					<span onClick={() => goTo('forgot-password')} style={{ cursor: 'pointer', color: '#00B3FF' }}>
						Resend Password
					</span>
				</div>
				<div className='signup-success-footer'>
					If you need further assistance &nbsp;
					<span onClick={toggleContactSupport} style={{ cursor: 'pointer', color: '#00B3FF' }}>
						Contact Support
					</span>
				</div>
			</div>
			<AeroModal footer={false} title='Contact Support' visible={contactSupportModal} onCancel={toggleContactSupport}>
				<ContactSupportContent toggleContactSupport={toggleContactSupport} />
			</AeroModal>
		</>
	)
}

export default ForgotPasswordSent
