import React from 'react'
import { Button, Drawer } from 'antd'
import { CloseOutlined, PoweroffOutlined } from '@ant-design/icons'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { BTN_LOG_OUT, SIDE_MENU_LIST } from './side-bar-menu-constants'
import MobileBarItem from './mobile-bar-item'
import AeroImage from '../../AeroComponents/Image'

const MobileSidebar = props => {
	const { handleHamburger, mobileSidebarVisible, logOut } = props
	const currentPathName = window.location.pathname
	return (
		<Drawer
			bodyStyle={{ background: '#F1F3F4' }}
			onClose={handleHamburger}
			closeIcon={<CloseOutlined rootStyle={{ position: 'relative', left: '300px', top: '15px' }} />}
			placement='left'
			title={<AeroImage src={IMAGES_SRC.SIDEBAR_LOGO} otherstyles={{ position: 'relative', right: '25px', top: '15px' }} />}
			open={mobileSidebarVisible}
			key='left'
		>
			{SIDE_MENU_LIST.map(menu => (
				<MobileBarItem key={menu.key} menu={menu} currentPathName={currentPathName} handleHamburger={handleHamburger} />
			))}
			<div rootStyle={{ position: 'fixed', bottom: '1%', textAlign: 'center' }}>
				<Button
					onClick={() => logOut()}
					type='primary'
					rootStyle={{ background: '#FFFFFF', border: '2px solid #00B3FF', borderRadius: '12px', height: '48px', width: '290%' }}
				>
					<PoweroffOutlined rootStyle={{ color: '#1890ff', fontSize: '16px', position: 'relative', bottom: '4px', right: '5px' }} />
					<span rootStyle={{ marginLeft: '5px', color: '#1890ff', fontSize: '16px' }}>{BTN_LOG_OUT}</span>
				</Button>
			</div>
		</Drawer>
	)
}

export default MobileSidebar
