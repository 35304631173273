/*eslint-disable*/
import React from 'react'

import { Statistic, message } from 'antd'
import { connect } from 'react-redux'
import { getOtp } from '../../../Stores/UserSession/action'
import { bindActionCreators } from 'redux'

const { Countdown } = Statistic

class ResendOtp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			deadline: Date.now() + 1000 * 60,
			stageState: 1,

		}
	}


	componentWillReceiveProps(nextProps) {
		if (this.props.getOtpData !== nextProps.getOtpData) {
			if (nextProps.getOtpData) {
				if ( typeof (nextProps.getOtpData) === 'string' && nextProps.getOtpData.includes("error")) {
					this.setState({  deadline: Date.now() + 1000 * 1 })
				}
				else{
					this.props.setOtpKey(nextProps.getOtpData)
					this.setState({ stageState: 2, deadline: Date.now() + 1000 * 60 })
				}
			}
		}

	}

	checkPhoneLength = mobileNumber => {
		const mob = /^[0-9]{1}[0-9]{7,10}$/
		if (mob.test(mobileNumber) === false) {
			message.error('Mobile number invalid!')
			return false
		} else {
			return true
		}
	}


	checkIsdCode = isdCode => {
		const code = /^\d{1,3}$/
		if (code.test(isdCode) === false) {
			message.error('ISD code is invalid!')
			return false
		} else {
			return true
		}
	}

	checkState = () => {
		if (this.state.stageState === 1) {
			const phoneNumber = this.props.mobileNumber
			const isdCode = this.props.isdCode
			const isdCheck = this.checkIsdCode(isdCode)
			const lengthCheck = this.checkPhoneLength(phoneNumber)
			if (lengthCheck && isdCheck) {
				this.props.getOtp({ dialingCode:   '+' + isdCode , mobileNumber: phoneNumber })
				this.setState({ stageState: 2 })
			}
		} else {
			message.error('Please wait for a few more seconds!')
		}
	}

	countdownCheck = () => {

		this.setState({ stageState: 1 })
	}
	formatCountdown = (time) => `Resend(${time} seconds)`;

	render() {
		const { deadline, stageState } = this.state
		return (
			<div onClick={this.checkState} style={{ cursor: 'pointer', color: "#00B3FF" }}>
				{stageState === 1 ? 'Send Code' : <>   <Countdown style={{

				}} valueStyle={{ fontSize: '14px', color: "#ABA8B0" }} value={deadline} prefix="Resend(" format={"ss"} suffix="s)" onFinish={this.countdownCheck} />   </>}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		getOtpData: state.UserSessionReducer.getOtpData,
		 
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getOtp: bindActionCreators(getOtp, dispatch),
		 
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ResendOtp)
