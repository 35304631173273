import { get, post, put } from '../../Api/http-interceptor'
import * as ACTION from './actionTypes'

export const getAllSubscriptionsApi = () => {
	return get(ACTION.GET_ALL_SUBSCRIPTIONS)
}

export const sendCardTokenApi = body => {
	return post(ACTION.SEND_CARD_TOKEN, body)
}

export const getActivePlanDetailsApi = queryParams => {
	return get(ACTION.GET_ACTIVE_PLAN_DETAILS, null, queryParams)
}

export const getPaymentTransactionsApi = queryParams => {
	return get(ACTION.GET_PAYMENT_TRANSACTIONS, null, queryParams)
}

export const getGigapixelTransactionsApi = queryParams => {
	return get(ACTION.GET_GIGAPIXEL_TRANSACTIONS, null, queryParams)
}

export const cancelPlanApi = body => {
	return put(ACTION.CANCEL_PLAN, body)
}

export const validateDiscountCodeApi = queryParams => {
	return get(ACTION.VALIDATE_DISCOUNT_CODE, null, queryParams)
}

export const confirmPaymentApi = () => {
	return get(ACTION.CONFIRM_PAYMENT)
}

export const cancelPaymentApi = () => {
	return put(ACTION.CANCEL_PAYMENT, null)
}

export const sendCardTokenForFreeTrialApi = body => {
	return post(ACTION.SEND_CARD_TOKEN_FOR_FREE_TRIAL, body)
}
