import { Button, Form, Input, Select, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactCountryFlag from 'react-country-flag'
import InfoBox from './components/info-box'
import AeroButton from '../../AeroComponents/Button'
import { getCountryList, registerUser } from '../../Stores/UserSession/action'
import { validateEmailAddress, validateLettersAndSpaces, validatePassword, passwordValidations, validateMobileNumber, validateISDCode } from '../../Services/UtilityService'
import { gotToHome, goToWithQuery, getQueryParameters } from '../../Services/NavigationService'
import AeroInput from '../../AeroComponents/Input'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import ResendOtp from './components/resend-otp'

const Signup = props => {
	const { countryListData, getCountryList, registerUser, registrationDone } = props
	const [countryList, setCountryList] = useState(null)
	const [waitForApi, setWaitForApi] = useState(false)
	const [registerBody, setRegisterBody] = useState(null)
	const [isValidPassword, setIsValidPassword] = useState(null)
	const [isd, setIsd] = useState('')
	const [mobileNumber, setMobileNumber] = useState(null)
	const [otpKey, setOtpKey] = useState(null)
	const [form] = Form.useForm()
	useEffect(() => {
		setWaitForApi(true)
		getCountryList()
	}, [])

	useEffect(() => {
		if (countryListData && waitForApi) {
			setWaitForApi(false)
			const mappedData = countryListData.filter(item => item.active)
			setCountryList(mappedData)
		}
	}, [countryListData])

	const recordValues = values => {
		if (!otpKey?.otp_key) {
			message.error('Please verify otp.')
			return
		}

		if (!otpKey?.otp_key.includes(mobileNumber)) {
			message.error('Dont change mobile number after verifying otp.')
			return
		}
		const { firstName, lastName, emailId, password, country, otp } = values
		const body = {
			firstName: firstName,
			lastName: lastName,
			middleName: '',
			email: emailId,
			password: password,
			confirmPassword: password,
			countryId: country,
			mobileNumber: mobileNumber,
			isdCode: '+' + isd,
			otpKey: otpKey?.otp_key,
			otp: otp,
		}
		setWaitForApi(true)
		setRegisterBody(body)
		registerUser(body)
	}

	useEffect(() => {
		if (registrationDone && waitForApi) {
			const country = countryList.filter(item => item.id === registerBody.countryId)
			let queryParams = getQueryParameters()
			let queryString = new URLSearchParams(queryParams).toString()
			let mixPanelObj = {
				'First Name': registerBody.firstName,
				'Last Name': registerBody.lastName,
				Country: country[0].name,
				$email: registerBody.email,
				mobile: registerBody.mobileNumber,
				...queryParams,
			}

			pushEventToMixpanel('User Signup', mixPanelObj)
			setWaitForApi(false)
			goToWithQuery(`signup-successfull`, '?' + queryString)
		}
	}, [registrationDone])

	const correctIcon = <CheckCircleFilled style={{ color: '#6CBE44' }} />
	const wrongIcon = <CloseCircleFilled style={{ color: '#F75151' }} />

	const validationsForPassword = e => {
		let password = e.target.value
		// Hide if password is empty
		if (password === '') {
			setIsValidPassword(null)
			return
		}
		let validations = passwordValidations(password)
		setIsValidPassword(validations)
	}

	const getValidationIconAndText = (result, text) => {
		return (
			<>
				{' '}
				{result ? correctIcon : wrongIcon} <span style={result ? { color: '#6CBE44' } : { color: '#F75151' }}> {text} </span>
			</>
		)
	}

	const autoSelectDialcode = countryId => {
		let dialcode = countryList
			.find(item => {
				return item.id === countryId
			})
			?.dialcode?.slice(1)
		setIsd(dialcode)
		form.setFieldsValue({ isdcode: dialcode })
	}

	return (
		<div className='login-wrapper'>
			<div className='login-form-wrapper'>
				<div className='login-title'>Sign Up</div>
				<Form form={form} onFinish={recordValues} layout='vertical'>
					<Space direction='vertical' size='large' style={{ width: '100%' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Form.Item
								rules={[{ required: true, message: 'Please input first name!', whitespace: true }, { validator: validateLettersAndSpaces }]}
								name='firstName'
								label='First Name'
							>
								<AeroInput placeholder='Type here' />
							</Form.Item>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<Form.Item
								rules={[{ required: true, message: 'Please input last name!', whitespace: true }, { validator: validateLettersAndSpaces }]}
								name='lastName'
								label='Last Name'
							>
								<AeroInput placeholder='Type here' />
							</Form.Item>
						</div>
						<Form.Item
							rules={[{ required: true, message: 'Please input email!', whitespace: true }, { validator: validateEmailAddress }]}
							name='emailId'
							label='Email ID'
						>
							<AeroInput type='email' placeholder='Type here' />
						</Form.Item>

						<Form.Item
							rules={[{ required: true, message: 'Please input password!' }, { validator: validatePassword }]}
							name='password'
							label='Password'
							// extra={<div className='signup-password-helper-txt'>Password must have one uppercase letter, one number, one special character</div>}
						>
							<Input.Password size='large' style={{ borderRadius: '6px' }} placeholder='Type here' onKeyUp={validationsForPassword} />
						</Form.Item>
						<div className='signup-password-helper-txt'>Password must have one uppercase letter, one number, one special character</div>
						{isValidPassword ? (
							<div>
								<ul style={{ listStyle: 'none', padding: '0px' }}>
									{isValidPassword.map(item => {
										return <li> {getValidationIconAndText(item.result, item.text)}</li>
									})}
								</ul>
							</div>
						) : (
							<></>
						)}
						<Form.Item rules={[{ required: true, message: 'Please select your country!' }]} name='country' label='Country of Residence'>
							<Select
								size='large'
								showSearch
								placeholder='Type to search'
								optionFilterProp='children'
								filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}
								onChange={autoSelectDialcode}
							>
								{countryList?.map(item => (
									<Select.Option key={item.name} value={item.id}>
										<ReactCountryFlag countryCode={item.code} /> <span style={{ marginLeft: '5px' }}>{item.name}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							label={
								<span>
									<span style={{ color: '#ff4d4f' }}>*&nbsp;</span> Phone no{' '}
								</span>
							}
							rules={[
								{
									required: true,
								},
							]}
						>
							<Input.Group compact>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'Please input your isd code',
										},
										{
											validator: validateISDCode,
										},
									]}
									name='isdcode'
									label=''
									noStyle
								>
									<Input
										style={{
											width: '20%',
										}}
										size='large'
										placeholder='+61'
										prefix='+'
										value={isd}
										defaultValue={isd}
										onChange={e => {
											setIsd(e.target.value)
										}}
									/>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'Please input your phone number',
										},
										{
											validator: validateMobileNumber,
										},
									]}
									name={['mobileNumber', 'number']}
									label=''
									noStyle
								>
									<Input
										style={{
											width: '80%',
										}}
										type='number'
										size='large'
										placeholder='Type here'
										addonAfter={<ResendOtp mobileNumber={mobileNumber} isdCode={isd} setOtpKey={setOtpKey} />}
										onChange={e => {
											setMobileNumber(e.target.value)
										}}
									/>
								</Form.Item>
							</Input.Group>
						</Form.Item>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							name={'otp'}
							label='Otp'
						>
							<Input size='large' placeholder='Type here' />
						</Form.Item>

						<Form.Item>
							<AeroButton htmlType='submit' type='primary' block>
								Sign Up
							</AeroButton>
						</Form.Item>
					</Space>
				</Form>
				<div className='signup-footer-txt'>
					By clicking "Sign Up", I agree to Aerologix's{' '}
					<a target='_blank' href='https://www.aerologixmaps.com/terms/-'>
						Terms of Use
					</a>{' '}
					and acknowledge that I've read the{' '}
					<a target='_blank' href='https://www.aerologixmaps.com/policy/'>
						Privacy Policy
					</a>
				</div>
				<div style={{ textAlign: 'center', marginTop: '30px' }}>
					<div className='login-bottom-btn' style={{ color: '#84848A' }}>
						Existing User?{' '}
						<Button onClick={() => gotToHome()} style={{ color: '#00B3FF' }} type='text'>
							Log In
						</Button>
					</div>
				</div>
			</div>
			<InfoBox />
		</div>
	)
}

const mapStateToProps = state => {
	return {
		countryListData: state.UserSessionReducer.countryListData,
		registrationDone: state.UserSessionReducer.registrationDone,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getCountryList: bindActionCreators(getCountryList, dispatch),
		registerUser: bindActionCreators(registerUser, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Signup)
