/*eslint-disable*/
import React, { Component } from 'react'
import MatImage from '../Image'
import { IMAGES_SRC } from '../Image/image.constants'
import { Link } from 'react-router-dom'
import { gotToHome } from '../../Services/NavigationService'

class PageNotFound extends Component {
	render() {
		return (
			<div style={{ textAlign: 'center' }}>
				<div style={{ fontSize: '33px', fontWeight: '300', color: '#373A3C', marginTop: '50px', marginBottom: '20px' }}>Error: 404 Page Not Found</div>
				<MatImage src={IMAGES_SRC.NOT_FOUND_ICON} />
				<div style={{ fontSize: '16px', marginTop: '30px' }}>
					An error ocurred and your request couldn’t be completed. <br />
					Either check the URL or{' '}
					<span onClick={() => gotToHome()} style={{ cursor: 'pointer', color: '#00B3FF' }}>
						Go Home
					</span>
				</div>
			</div>
		)
	}
}

export default PageNotFound
