import React, { useState } from 'react'
import { Spin } from 'antd'

const AeroImage = props => {
	const { className, src, width, height, alt, otherstyles } = props
	const [loaded, setLoaded] = useState(false)

	return (
		<>
			<Spin spinning={!loaded}></Spin>
			<img
				className={className}
				src={src}
				width={width}
				height={height}
				alt={!!alt ? alt : ''}
				{...props}
				style={loaded ? { ...otherstyles } : { display: 'none' }}
				onLoad={() => setLoaded(true)}
			/>
		</>
	)
}

export default AeroImage
