import React from 'react'
import AeroImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { Space } from 'antd'

const InfoBox = () => {
	return (
		<div className='login-info-banner-wrapper'>
			<div className='login-info-box'>
				<div className='login-info-header'>
					<AeroImage className='login-info-header-asset' alt='info' src={IMAGES_SRC.SMALL_INFO_ICON} />
					<div className='login-info-title'>Information for existing Aerologix Users</div>
				</div>

				<div style={{ marginTop: '15px' }}>
					<div className='login-info-tips-wrapper'>
						<ul>
							<Space direction='vertical' size='large'>
								<li>
									If you already have an Aerologix <span className='highlighted-txt'>Pilot</span> or <span className='highlighted-txt'>Client</span> account, you
									can log in with the same credentials
								</li>
								<li>
									<span className='highlighted-txt'>Enterprise clients</span> and <span className='highlighted-txt'>Collaborators</span> cannot use the same
									credentials to log in
								</li>
							</Space>
						</ul>
					</div>
				</div>
			</div>

			<AeroImage otherstyles={{ width: '-webkit-fill-available' }} alt='banner-asset' src={IMAGES_SRC.LOGIN_INFO_ASSET} />
		</div>
	)
}

export default InfoBox
