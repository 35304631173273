import { Collapse, Space } from 'antd'
import React from 'react'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import AeroImage from '../../../AeroComponents/Image'
import AeroButton from '../../../AeroComponents/Button'
const { Panel } = Collapse

const ProcessedDownloadModalContent = props => {
	const { models, curves, cloud, orthomosaic, others, reports,sendMixpanelDownloadEvent,processingName } = props
	return (
		<Collapse expandIconPosition='end' bordered={false}>
			<Panel header='DIGITAL MODELS' key='1'>
				{models && models?.length
					? models.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<AeroButton href={item.url} style={{ color: '#096DD9' }} type='link' onClick={()=>{sendMixpanelDownloadEvent(processingName,'DIGITAL MODELS')}} > 
									<Space>
										<AeroImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</AeroButton>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='LEVEL CURVES' key='2'>
				{curves && curves?.length
					? curves.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<AeroButton href={item.url} style={{ color: '#096DD9' }} type='link' onClick={()=>{sendMixpanelDownloadEvent(processingName,'LEVEL CURVES')}} >
									<Space>
										<AeroImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</AeroButton>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='POINT CLOUD' key='3'>
				{cloud && cloud?.length
					? cloud.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<AeroButton href={item.url} style={{ color: '#096DD9' }} type='link' onClick={()=>{sendMixpanelDownloadEvent(processingName,'POINT CLOUD')}} >
									<Space>
										<AeroImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</AeroButton>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='ORTHOMOSAIC' key='4'>
				{orthomosaic && orthomosaic?.length
					? orthomosaic.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<AeroButton href={item.url} style={{ color: '#096DD9' }} type='link' onClick={()=>{sendMixpanelDownloadEvent(processingName,'ORTHOMOSAIC')}} >
									<Space>
										<AeroImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</AeroButton>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='REPORTS' key='5'>
				{reports && reports?.length
					? reports.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<AeroButton href={item.url} style={{ color: '#096DD9' }} type='link' onClick={()=>{sendMixpanelDownloadEvent(processingName,'REPORTS')}} >
									<Space>
										<AeroImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</AeroButton>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='OTHERS' key='6'>
				{others && others?.length
					? others.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<AeroButton href={item.url} style={{ color: '#096DD9' }} type='link' onClick={()=>{sendMixpanelDownloadEvent(processingName,'OTHERS')}} >
									<Space>
										<AeroImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</AeroButton>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
		</Collapse>
	)
}

export default ProcessedDownloadModalContent
