import React, { useEffect, useState } from 'react'
import { notification, Form, message } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAllSubscriptions, getActivePlanDetails, cancelPlan, validateDiscountCode } from '../../Stores/Subscription/action'
import { getProfile } from '../../Stores/UserSession/action'
import AeroTextSwitch from '../../AeroComponents/TextSwitch'
import AeroModal from '../../AeroComponents/Modal'
import SubscriptionPlansList from './components/subscription-plans-list'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import AeroButton from '../../AeroComponents/Button'
import PaidPlanModalContent from './components/paid-plan-modal-content'
import SubscriptionDetails from './components/subscription-details'
import GeneralNotificationContent from '../../AeroComponents/Modal/general-notification-content'
import { capitalize } from '../../Services/UtilityService'
import GeneralConfirmationContent from '../../AeroComponents/Modal/general-confirmation-content'
import AeroNotificationContent from '../../AeroComponents/Notification'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import MobileHeader from '../../AeroComponents/MobileHeader'
import styles from './subscription.module.sass'

const Subscription = props => {
	const {
		getAllSubscriptions,
		subscriptionPlans,
		profileData,
		activePlanData,
		getActivePlanDetails,
		cancelPlan,
		planCancelled,
		validateDiscountCode,
		discountCodeValidated,
		handleHamburger,
		source,
		updateModalStatus,
		getProfile,
	} = props
	const [form] = Form.useForm()
	const [waitForApi, setWaitForApi] = useState(false)
	const [subscriptionData, setSubscriptionData] = useState(null)
	const [currentDuration, setCurrentDuration] = useState('YEARLY')
	const [paidPlanModal, setPaidPlanModal] = useState(false)
	const [currentPaidPlan, setCurrentPaidPlan] = useState(null)
	const [activePlanDetails, setActivePlanDetails] = useState(null)
	const [buyMode, setBuyMode] = useState(props.location?.state ? props.location?.state : false)
	const [isCancelled, setIsCancelled] = useState(false)
	const [inGrace, setInGrace] = useState(false)
	const [paymentSuccessModal, setPyamentSuccessModal] = useState(false)
	const [cancelModal, setCancelModal] = useState(false)
	const [paymentsTransaction, setPaymentsTransaction] = useState(true)
	const [discountCode, setDiscountCode] = useState(null)
	const [discountPercentage, setDiscountPercentage] = useState(null)
	const [isDowngrade, setIsDowngrade] = useState(false)
	const [oldPlan, setOldPlan] = useState(null)
	const [profileDetails, setProfileDetails] = useState(profileData)
	const [otherPlans, setOtherPlans] = useState([])
	const [appSubscriptionSkipModal, setAppSubscriptionSkipModal] = useState(false)
	const [discountList, setDiscountList] = useState([])
	const [rightContentPhase, setRightContentPhase] = useState(1)

	const planStartDate = moment()
	const planEndDate = moment().add(15, 'days')

	const handleDurationChange = val => setCurrentDuration(val)
	const togglePaymentSuccessModal = () => setPyamentSuccessModal(prevState => !prevState)
	const toggleAppSubscriptionSkipModal = () => setAppSubscriptionSkipModal(prevState => !prevState)
	const toggleCancelModal = () => setCancelModal(prevState => !prevState)
	const callPaymentsTransaction = val => setPaymentsTransaction(val)
	const captureDiscountCode = e => setDiscountCode(e.target.value)
	const updateRightContentPhase = val => setRightContentPhase(val)

	useEffect(() => {
		setWaitForApi(true)
		if (profileData?.active_plan && !buyMode) {
			getActivePlanDetails({ plan_id: profileData.active_plan.id })
		} else {
			if (buyMode) setBuyMode(false)
			getAllSubscriptions()
		}
	}, [])

	useEffect(() => {
		if (profileData) {
			setProfileDetails(profileData)
		}
	}, [profileData])

	const updateChargeToObj = (yearlyIterableList, monthlyIterableList) => {
		const yearlyList = yearlyIterableList.map(item => {
			return { ...item, charge: { YEARLY: item.charge, MONTHLY: monthlyIterableList.filter(monthly => monthly.type === item.type)[0].charge } }
		})
		const monthlyList = monthlyIterableList.map(item => {
			return { ...item, charge: { YEARLY: yearlyList.filter(yearly => yearly.type === item.type)[0].charge.YEARLY, MONTHLY: item.charge } }
		})

		return { yearlyList: yearlyList, monthlyList: monthlyList }
	}

	useEffect(() => {
		if (subscriptionPlans && waitForApi) {
			setWaitForApi(false)
			let finalList = subscriptionPlans.response['YEARLY'].filter(item => item.bought)
			if (!finalList?.length) {
				finalList = subscriptionPlans.response['MONTHLY'].filter(item => item.bought)
			}
			let yearlyIterableList = subscriptionPlans.response['YEARLY']
			const discList = yearlyIterableList.map(item => {
				return {
					type: item.type,
					percentage: item.discountPercentage,
				}
			})
			setDiscountList(discList)
			let monthlyIterableList = subscriptionPlans.response['MONTHLY']
			const updatedChargeObj = updateChargeToObj(yearlyIterableList, monthlyIterableList)
			yearlyIterableList = updatedChargeObj.yearlyList
			monthlyIterableList = updatedChargeObj.monthlyList
			if (finalList && finalList.length) {
				if (finalList[0].type !== 'FREE TRIAL') {
					yearlyIterableList = subscriptionPlans.response['YEARLY'].filter(item => item.type !== 'FREE TRIAL')
					monthlyIterableList = subscriptionPlans.response['MONTHLY'].filter(item => item.type !== 'FREE TRIAL')
					const updatedChargeObj = updateChargeToObj(yearlyIterableList, monthlyIterableList)
					yearlyIterableList = updatedChargeObj.yearlyList
					monthlyIterableList = updatedChargeObj.monthlyList
				}
			}
			setSubscriptionData({ MONTHLY: monthlyIterableList, YEARLY: yearlyIterableList })
		}
	}, [subscriptionPlans])

	useEffect(() => {
		if (activePlanData && waitForApi) {
			setWaitForApi(false)
			setBuyMode(false)
			const expDate = moment.utc(activePlanData.endDate).local()
			if (activePlanData.status === 'GRACE' && expDate > moment()) {
				if (profileDetails?.active_plan?.pendingPlan?.price < activePlanData.amount) setIsDowngrade(true)
				setIsCancelled(true)
			}
			if (activePlanData.status === 'GRACE' && expDate < moment()) {
				setInGrace(true)
			}
			setActivePlanDetails(activePlanData)
		}
	}, [activePlanData])

	const openPaidPlanModal = item => {
		form.resetFields()
		if (discountPercentage) setDiscountPercentage(null)
		if (discountCode) setDiscountCode(null)
		setCurrentPaidPlan(item)
		setPaidPlanModal(true)
	}

	const goToBuyMode = () => {
		setWaitForApi(true)
		setBuyMode(true)
		getAllSubscriptions()
	}

	const goToDetailsMode = () => {
		setPaymentsTransaction(true)
		setBuyMode(false)
	}

	const handlePostSubscription = plan => {
		setWaitForApi(true)
		getProfile().then(() => {
			if (discountPercentage) setDiscountPercentage(null)
			if (discountCode) setDiscountCode(null)
			const currentPlanAmount = oldPlan ? oldPlan.amount : 0
			const newPlanAmount = plan.amount
			if (newPlanAmount > currentPlanAmount) {
				if (isCancelled) setIsCancelled(false)
				getActivePlanDetails({ plan_id: plan.id })
			} else {
				getActivePlanDetails({ plan_id: activePlanDetails.id })
			}
			setPaymentsTransaction(true)
			setPaidPlanModal(false)
			togglePaymentSuccessModal()
			notification.open({
				message: <AeroNotificationContent content='Please refresh the page to see the latest transactions' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		})
	}

	useEffect(() => {
		if (planCancelled && waitForApi) {
			toggleCancelModal()
			notification.open({
				message: <AeroNotificationContent content='Subscription plan cancelled' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
			pushEventToMixpanel('Subscription Cancelled', {
				'Subscription Type': planCancelled.type,
				'Payment Term. (monthly/ annual)': planCancelled.duration,
			})
			getActivePlanDetails({ plan_id: activePlanDetails.id })
		}
	}, [planCancelled])

	useEffect(() => {
		if (discountCodeValidated && waitForApi) {
			setWaitForApi(false)
			if (discountCodeValidated.valid) {
				setDiscountPercentage(discountCodeValidated.percentage)
			}
		}
	}, [discountCodeValidated])

	const handlePlanCancel = () => toggleCancelModal()

	const callCancel = () => {
		setWaitForApi(true)
		cancelPlan({ plan_id: activePlanDetails.id })
	}

	const checkDiscountCode = () => {
		if (discountCode) {
			setWaitForApi(true)
			validateDiscountCode({ coupon: discountCode, subscriptionPlanId: currentPaidPlan.id })
		} else {
			message.warning('Please input the coupon code!')
		}
	}

	const handlePaymentCancelled = () => {
		updateRightContentPhase(1)
		setPaidPlanModal(false)
		message.warning('Payment Cancelled!')
	}

	const callSubscriptionBoughtMixpanelEvent = () => {
		pushEventToMixpanel('Subscription Bought', {
			'Subscription Type': currentPaidPlan.type,
			'Payment Term. (monthly/ annual)': currentDuration,
		})
	}

	const capturePlan = (selectedData, duration) => {
		setCurrentPaidPlan(selectedData)
		setOtherPlans(subscriptionData[duration].filter(item => item.id !== selectedData.id && item.type !== selectedData.type && item.type !== 'FREE TRIAL'))
	}

	const updateDuration = e => {
		const duration = e.target.value === 'annual' ? 'YEARLY' : 'MONTHLY'
		const selectedPlan = subscriptionData[duration].filter(item => item.type === currentPaidPlan.type)
		setCurrentDuration(duration)
		capturePlan(selectedPlan[0], duration)
	}

	return (
		<div style={{ margin: '15px' }}>
			<MobileHeader header='Subscription' handleHamburger={handleHamburger} />
			{activePlanDetails && !buyMode ? (
				activePlanDetails && (
					<SubscriptionDetails
						paymentsTransaction={paymentsTransaction}
						callPaymentsTransaction={callPaymentsTransaction}
						inGrace={inGrace}
						isCancelled={isCancelled}
						handlePlanCancel={handlePlanCancel}
						goToBuyMode={goToBuyMode}
						data={activePlanDetails}
						isDowngrade={isDowngrade}
						profilePlan={profileData?.active_plan}
					/>
				)
			) : (
				<>
					<div className='module-container'>
						{source !== 'app' ? (
							<div className='module-title'>
								{buyMode ? (
									<AeroButton type='link' onClick={goToDetailsMode}>
										<AeroImage alt='go back' src={IMAGES_SRC.GO_BACK_ICON} />
									</AeroButton>
								) : null}{' '}
								Subscription Plans
							</div>
						) : (
							<div className={styles['drawer-subscription-head-wrapper']}>
								<div className={styles['drawer-subscription-header']}>Choose a Subscription Plan</div>
								<div className={styles['skip-plans-btn']}>
									<AeroButton onClick={toggleAppSubscriptionSkipModal} otherstyles={{ color: '#00B3FF' }} type='text'>
										<u>Skip for now</u>
									</AeroButton>
								</div>
							</div>
						)}
						<div style={{ textAlign: 'center', margin: '20px' }}>
							<AeroTextSwitch options={['YEARLY', 'MONTHLY']} currentSelection={currentDuration} handleSwitchChange={handleDurationChange} />
						</div>
						<div style={{ color: '#84818A', fontSize: '16px', margin: '15px', fontWeight: '600', textAlign: 'center' }}>
							All the prices listed below are in<span style={{ color: '#00B3FF' }}> US Dollars </span>.
						</div>
						{subscriptionData && (
							<SubscriptionPlansList
								capturePlan={capturePlan}
								currentDuration={currentDuration}
								subscriptionData={subscriptionData[currentDuration]}
								activePlan={activePlanDetails}
								currentPaidPlan={currentPaidPlan}
							/>
						)}
						<div className={styles['plan-selection-btn-wrapper']}>
							<AeroButton className={styles['plan-selection-btn']} disabled={!currentPaidPlan} onClick={() => openPaidPlanModal(currentPaidPlan)} type='primary'>
								{profileData?.free_trial_availed ? 'Next' : 'Start 15-Days Trial'}
							</AeroButton>
						</div>
					</div>
					<div style={{ textAlign: 'center', margin: '30px' }}>
						<div className='signup-success-footer'>
							<a target='_blank' href='https://aerologix.com/contact/'>
								Contact
							</a>
							&nbsp; our team for Enterprise Plan
						</div>
					</div>
					<div style={{ textAlign: 'center' }}>
						<div className='signup-success-footer'>
							<sup>*</sup>1 Gigapixel = Image Megapixel x Number of Images / 1000
						</div>
					</div>
				</>
			)}

			<AeroModal footer={false} visible={paymentSuccessModal} onCancel={togglePaymentSuccessModal}>
				<GeneralNotificationContent
					btnAction={togglePaymentSuccessModal}
					btnText='Okay, Got it'
					topAsset={IMAGES_SRC.BIG_CHECK_ICON}
					heading='Payment Mandate Successful!'
					description={
						profileDetails?.active_plan?.status === 'UPCOMING'
							? `Your plans have been successfully activated. Your free trial has commenced today and will last for 15 days. You can cancel at any time before ${moment
									.utc(profileDetails?.active_plan?.freeTrialEndDate)
									.local()
									.format('DD/MM/YYYY')} to avoid any charges.`
							: `Congratulations! You have bought ${
									profileDetails?.active_plan?.pendingPlan?.type
										? capitalize(profileDetails?.active_plan.pendingPlan.type)
										: profileDetails?.active_plan?.type
										? capitalize(profileDetails?.active_plan.type)
										: null
							  } Plan.
					You can start using all features available on this plan.`
					}
				/>
			</AeroModal>
			<AeroModal footer={false} visible={cancelModal} onCancel={toggleCancelModal}>
				<GeneralConfirmationContent
					primaryBtnAction={toggleCancelModal}
					primaryBtnText='Keep Plan'
					secondaryBtnAction={callCancel}
					secondaryBtnText='Cancel Subscription'
					topAsset={IMAGES_SRC.BIG_EXCLAMATION_ICON}
					heading='Are you sure you want to cancel the subscription?'
					description='Please note that if you cancel your subscription, you will lose your gigapixel balance and will not be able to process any images after the plan ends.'
				/>
			</AeroModal>
			{currentPaidPlan && (
				<AeroModal
					width={850}
					footer={false}
					visible={paidPlanModal}
					onCancel={() => {
						setPaidPlanModal(false)
						updateRightContentPhase(1)
					}}
				>
					<PaidPlanModalContent
						captureDiscountCode={captureDiscountCode}
						checkDiscountCode={checkDiscountCode}
						currentDuration={currentDuration}
						currentPaidPlan={currentPaidPlan}
						planStartDate={profileData?.active_plan?.amount > currentPaidPlan.charge ? moment(profileData?.active_plan?.endDate) : planStartDate}
						discountPercentage={discountPercentage}
						form={form}
						country={profileData?.country_id}
						freeTrialStartDate={planStartDate}
						freeTrialEndDate={planEndDate}
						otherPlans={otherPlans}
						capturePlan={capturePlan}
						updateDuration={updateDuration}
						freeTrialAvailed={profileData?.free_trial_availed}
						discountList={discountList}
						discountCode={discountCode}
						handlePostSubscription={handlePostSubscription}
						handlePaymentCancelled={handlePaymentCancelled}
						callSubscriptionBoughtMixpanelEvent={callSubscriptionBoughtMixpanelEvent}
						setOldPlan={setOldPlan}
						activePlanDetails={activePlanDetails}
						rightContentPhase={rightContentPhase}
						updateRightContentPhase={updateRightContentPhase}
					/>
				</AeroModal>
			)}

			<AeroModal footer={false} visible={appSubscriptionSkipModal} onCancel={toggleAppSubscriptionSkipModal}>
				<GeneralConfirmationContent
					primaryBtnAction={() => {
						updateModalStatus()
						toggleAppSubscriptionSkipModal()
					}}
					primaryBtnText='Yes, Continue'
					secondaryBtnAction={toggleAppSubscriptionSkipModal}
					secondaryBtnText='No, Return'
					topAsset={IMAGES_SRC.BIG_INFO_ICON}
					heading='Continue without choosing a plan?'
					description="You can't process images until you select a plan, but you can choose or change it anytime later."
				/>
			</AeroModal>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		subscriptionPlans: state.SubscriptionReducer.subscriptionPlans,
		profileData: state.UserSessionReducer.profileData,
		activePlanData: state.SubscriptionReducer.activePlanData,
		planCancelled: state.SubscriptionReducer.planCancelled,
		discountCodeValidated: state.SubscriptionReducer.discountCodeValidated,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllSubscriptions: bindActionCreators(getAllSubscriptions, dispatch),
		getActivePlanDetails: bindActionCreators(getActivePlanDetails, dispatch),
		cancelPlan: bindActionCreators(cancelPlan, dispatch),
		validateDiscountCode: bindActionCreators(validateDiscountCode, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Subscription)
