import React from 'react'
import { Divider } from 'antd'
import styles from '../mymaps.module.sass'
import { PROCESSING_CAMERA_TYPE_ENUMS, PROCESSING_FLIGHT_LOG_ENUMS, PROCESSING_TYPE_ENUMS } from '../constants'
import AeroButton from '../../../AeroComponents/Button'
import { capitalize } from '../../../Services/UtilityService'

const ProcessingSummary = props => {
	const { data, profileData, changeStage, callStartProcessing, callTransaction, activePlan } = props
	const overduePixels = activePlan?.gigapixelsLeft - data?.gigapixels < 0 ? data?.gigapixels - activePlan?.gigapixelsLeft : 0
	const updatedPixels = activePlan?.gigapixelsLeft - data?.gigapixels >= 0 ? activePlan?.gigapixelsLeft - data?.gigapixels : 0

	const getSubTotal = () => {
		return (overduePixels * activePlan?.additionalGigapixelCharge + (activePlan?.type === 'PRO' && data?.processingData?.bl_gcp ? 49 : 0)).toFixed(2)
	}

	return (
		<div>
			<div className={styles['maps-heading']}>Processing Summary</div>
			<div className={styles['process-summary-wrapper']}>
				<div className={styles['process-summary-box']}>
					<div className={styles['summary-head']}>Processing Details</div>
					<div style={{ padding: '20px' }}>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Map Name</div>
								<div className={styles['summary-item-value']}>{data?.processingName}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Number of Images</div>
								<div className={styles['summary-item-value']}>{data?.boxFolderResponse?.totalFileCount}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Estimated Time</div>
								<div className={styles['summary-item-value']}>{(data?.estimatedTime / 60).toFixed(1)} minutes</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Camera Type</div>
								<div className={styles['summary-item-value']}>{PROCESSING_CAMERA_TYPE_ENUMS[(data?.processingData?.ab_camera_type)]}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Processing Type</div>
								<div className={styles['summary-item-value']}>{PROCESSING_TYPE_ENUMS[(data?.processingData?.ab_processing_template)]}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Flight Log</div>
								<div className={styles['summary-item-value']}>{PROCESSING_FLIGHT_LOG_ENUMS[(data?.processingData?.ab_georeference_type)]}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Has GCP</div>
								<div className={styles['summary-item-value']}>{data?.processingData?.bl_gcp ? 'Yes' : 'No'}</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles['process-summary-box']}>
					<div className={styles['summary-head']}>Cost</div>
					<div style={{ padding: '20px' }}>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Gigapixels Balance</div>
								<div className={styles['summary-item-value']}>{activePlan?.gigapixelsLeft}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Estimated Gigapixels</div>
								<div className={styles['summary-item-value']}>{data?.gigapixels.toFixed(2)}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Updated Gigapixel Balance</div>
								<div className={styles['summary-item-value']}>{updatedPixels.toFixed(2)}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Overdue Gigapixels</div>
								<div className={styles['summary-item-value']}>{overduePixels.toFixed(2)}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>GCP</div>
								<div className={styles['summary-item-value']}>{activePlan?.type === 'PRO' && data?.processingData?.bl_gcp ? 'USD 49' : '--'}</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item-wrapper']}>
							<div className={styles['summary-item']}>
								<div className={styles['summary-item-label']}>Subscription Type</div>
								<div className={styles['summary-item-value']}>
									{activePlan?.type !== 'FREE_TRIAL' ? capitalize(activePlan?.type ? activePlan?.type : '') : 'Free Trial'}
								</div>
							</div>
							<Divider className={styles['summary-item-divider']}></Divider>
						</div>
						<div className={styles['summary-item']}>
							<div className={styles['summary-item-label']}>Cost per Gigapixel</div>
							<div className={styles['summary-item-value']}>USD {activePlan?.additionalGigapixelCharge} per GP</div>
						</div>
					</div>
					<div className={styles['summary-box-footer-holder']}>
						<div className={styles['summary-box-footer']}>
							<div className={styles['summary-box-footer-value']}>
								<strong>Subtotal</strong>
							</div>
							<div className={[styles['summary-box-footer-value'], styles['subtotal-value']].join(' ')}>
								{overduePixels.toFixed(2)} GP x USD {activePlan?.additionalGigapixelCharge}{' '}
								{activePlan?.type === 'PRO' && data?.processingData?.bl_gcp ? ' + USD 49 ' : null} ={' '}
								<span style={profileData?.country_id !== 1 ? { fontSize: '20px', color: '#00B3FF' } : {}}>
									<strong>USD {getSubTotal()}</strong>
								</span>
							</div>
						</div>
						{profileData?.country_id === 1 ? (
							<>
								<div className={styles['summary-box-footer']}>
									<div className={styles['summary-box-footer-value']}>
										<strong>GST</strong> (10%)
									</div>
									<div className={styles['summary-box-footer-value']}>
										<strong>USD {getSubTotal() * 0.1}</strong>
									</div>
								</div>

								<div className={styles['summary-box-footer']}>
									<div className={styles['summary-box-footer-value']}>
										<strong>Total amount</strong>
									</div>
									<div className={styles['summary-box-footer-value']}>
										<span style={profileData?.country_id === 1 ? { fontSize: '20px', color: '#00B3FF' } : {}}>
											<strong>USD {getSubTotal() + getSubTotal() * 0.1}</strong>
										</span>
									</div>
								</div>
							</>
						) : (
							''
						)}
					</div>
				</div>
			</div>
			<div style={{ textAlign: 'right', marginTop: '20px' }}>
				<div className={styles['summary-action-btn']}>
					<AeroButton onClick={() => changeStage(1)} className={styles['summary-back-btn']} otherstyles={{ border: '1px solid #00B3FF', color: '#00B3FF' }}>
						Go Back
					</AeroButton>
					<AeroButton
						onClick={() =>
							overduePixels || (activePlan?.type === 'PRO' && data?.processingData?.bl_gcp)
								? callTransaction(
										overduePixels.toFixed(2),
										overduePixels.toFixed(2) * activePlan?.additionalGigapixelCharge,
										activePlan?.id,
										activePlan?.type === 'PRO' && data?.processingData?.bl_gcp,
								  )
								: callStartProcessing()
						}
						className={styles['summary-pay-btn']}
						type='primary'
					>
						{overduePixels || (activePlan?.type === 'PRO' && data?.processingData?.bl_gcp) ? 'Pay &' : null} Start Processing
					</AeroButton>
				</div>
			</div>
		</div>
	)
}

export default ProcessingSummary
