import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form } from 'antd'
import AeroButton from '../../AeroComponents/Button'
import AeroInput from '../../AeroComponents/Input'
import { goTo } from '../../Services/NavigationService'
import { recoverPassword } from '../../Stores/UserSession/action'
import AeroModal from '../../AeroComponents/Modal'
import ContactSupportContent from './components/contact-support-content'

const FrogotPassword = props => {
	const { recoverPassword, passwordRecovered } = props
	const [waitForApi, setWaitForApi] = useState(false)
	const [contactSupportModal, setContactSupportModal] = useState(false)

	const recordData = values => {
		setWaitForApi(true)
		recoverPassword({ email: values.email })
	}

	useEffect(() => {
		if (passwordRecovered && waitForApi) {
			setWaitForApi(false)
			goTo('forgot-password-sent')
		}
	}, [passwordRecovered])

	const toggleContactSupport = () => setContactSupportModal(prevState => !prevState)

	return (
		<>
			<div style={{ textAlign: 'center', padding: '40px' }}>
				<div className='signup-success-title'>Recover your password</div>
				<div className='signup-success-subtitle' style={{ fontWeight: '400' }}>
					Enter the email that you used while registering to recover your password. You will receive a new password on this email.
				</div>
				<div className='recovery-form'>
					<Form onFinish={recordData} layout='vertical'>
						<Form.Item rules={[{ required: true, message: 'Please input email!', whitespace: true }]} name='email' label='Email ID'>
							<AeroInput type='email' placeholder='Type here' />
						</Form.Item>

						<Form.Item style={{ marginTop: '40px' }}>
							<AeroButton htmlType='submit' type='primary' block>
								Send New Password
							</AeroButton>
						</Form.Item>
					</Form>
				</div>
				<div className='signup-success-footer'>
					If you need further assistance&nbsp;{' '}
					<span onClick={toggleContactSupport} style={{ cursor: 'pointer', color: '#00B3FF' }}>
						Contact Support
					</span>
				</div>
			</div>
			<AeroModal footer={false} title='Contact Support' visible={contactSupportModal} onCancel={toggleContactSupport}>
				<ContactSupportContent toggleContactSupport={toggleContactSupport} />
			</AeroModal>
		</>
	)
}

const mapStateToProps = state => {
	return {
		passwordRecovered: state.UserSessionReducer.passwordRecovered,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		recoverPassword: bindActionCreators(recoverPassword, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FrogotPassword)
