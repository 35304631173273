import React, { Component } from 'react'
import { Result, Button, Icon, Typography } from 'antd'
import styled from 'styled-components'
import { reportError } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const { Paragraph, Text } = Typography

const ErrorPage = styled(Result)`
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
`

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { error: null, errorInfo: null, eventId: null }
	}

	componentDidCatch(error, errorInfo) {
		this.props.reportError({ exception: String(error) + String(errorInfo.componentStack) })
		// mqtt.publishToBroker(String(error) + String(errorInfo.componentStack))

		this.setState({
			error: error,
			errorInfo: errorInfo,
		})
	}

	onClickHandler() {
		document.location.href = '/'
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<ErrorPage
					status='error'
					title='Something Went Wrong'
					subTitle='Please retry.'
					extra={[
						<Button type='primary' key='console' onClick={this.onClickHandler}>
							Go Home
						</Button>,
					]}
				>
					<div className='desc'>
						<Paragraph>
							<Text
								strong
								style={{
									fontSize: 16,
								}}
							>
								The program encountered the following error :
							</Text>
						</Paragraph>
						<Paragraph>
							<Icon style={{ color: 'red' }} type='close-circle' /> {this.state.error && this.state.error.toString()}
						</Paragraph>
					</div>
				</ErrorPage>
			)
		}

		return this.props.children
	}
}

const mapDispatchToProps = dispatch => {
	return {
		reportError: bindActionCreators(reportError, dispatch),
	}
}
export default connect(
	null,
	mapDispatchToProps,
)(ErrorBoundary)
