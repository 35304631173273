import React from 'react'
import moment from 'moment'
import styles from '../subscription.module.sass'

const GracePeriodNoteAlert = props => {
	const { gracePeriodEndDate } = props
	return (
		<div style={{ padding: '20px', width: '40%', marginLeft: '20px' }} className={styles['warning-banner-wrapper']}>
			<div style={{ fontSize: '32px' }}>
				<strong>Note:</strong>
			</div>
			<div style={{ marginTop: '10px' }}>
				You have until{' '}
				<strong>
					{moment
						.utc(gracePeriodEndDate)
						.local()
						.format('DD MMMM YYYY, HH:mm A')}
				</strong>{' '}
				to download your data before it is permanently deleted and can’t be restored or recovered. To download your data, you must have an active subscription plan.
			</div>
		</div>
	)
}

export default GracePeriodNoteAlert
