import React from 'react'
import styles from '../subscription.module.sass'
import moment from 'moment'
import { capitalize } from '../../../Services/UtilityService'
import { Divider, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import AeroImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import AeroButton from '../../../AeroComponents/Button'
import PaidPlanModalRightBillingContent from './paid-plan-modal-right-billing-content'
import PaidPlanModalRightPaymentContent from './paid-plan-modal-right-payment-content'

const PaidPlanModalContent = props => {
	const { currentPaidPlan, currentDuration, otherPlans, capturePlan, planStartDate, discountPercentage, rightContentPhase, updateRightContentPhase } = props

	const startDate = { ...planStartDate }
	const planEndDate = moment(startDate).add(1, currentDuration === 'YEARLY' ? 'years' : 'months')
	const planCharge = discountPercentage
		? +(currentPaidPlan.charge[currentDuration] - (discountPercentage / 100) * currentPaidPlan.charge[currentDuration]).toFixed(2)
		: currentPaidPlan.charge[currentDuration]

	return (
		<>
			<div className={styles['mobile-view']}>
				{rightContentPhase === 1 ? (
					<PaidPlanModalRightBillingContent updateRightContentPhase={updateRightContentPhase} planCharge={planCharge} planEndDate={planEndDate} {...props} />
				) : (
					<PaidPlanModalRightPaymentContent planCharge={planCharge} planEndDate={planEndDate} {...props} />
				)}
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<div style={{ fontSize: '32px' }} className={styles['modal-subscription-title']}>
						{capitalize(currentPaidPlan.type)} Plan {currentPaidPlan.type === 'PRO' ? <div className={styles['best-choice-plan']}>Best Choice</div> : null}
					</div>
					<div className={styles['feature-description-box']}>
						<div style={{ fontSize: '20px', fontWeight: '700', position: 'relative', top: '10px' }}>Features:</div>
						<Divider></Divider>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>
								Gigapixels*{' '}
								<Tooltip
									title={
										<>
											Gigapixels=(Megapixels (MP) of a single image×Number of images)÷1000
											<br />
											For instance, if you have 100 images, each of 20 MP, the total would be: Gigapixels=(20×100)÷1000=2 Gigapixels
										</>
									}
								>
									<InfoCircleOutlined />
								</Tooltip>
							</div>
							<div style={{ color: '#47464A' }}>{currentPaidPlan.gigapixelsAvailable}</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>Images upto per process</div>
							<div style={{ color: '#47464A' }}>{currentPaidPlan.imagesAllowed}</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>Sharing capability</div>
							<div>
								{currentPaidPlan.shareAllowed ? (
									<AeroImage alt='cross' src={IMAGES_SRC.CHECK_CIRCLE_ICON} />
								) : (
									<AeroImage alt='cross' src={IMAGES_SRC.CROSS_CIRCLE_ICON} />
								)}
							</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>Download capability</div>
							<div>
								{currentPaidPlan.downloadAllowed ? (
									<AeroImage alt='cross' src={IMAGES_SRC.CHECK_CIRCLE_ICON} />
								) : (
									<AeroImage alt='cross' src={IMAGES_SRC.CROSS_CIRCLE_ICON} />
								)}
							</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>Additional GPs charge</div>
							<div style={{ color: '#47464A' }}>
								<span style={{ color: '#00B3FF' }}>
									<strong>USD {currentPaidPlan.additionalGigapixelsCharge}</strong>
								</span>{' '}
								per GP
							</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>Storage limit</div>
							<div style={{ color: '#47464A' }}>{currentPaidPlan.storageAvailable}</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>Additional storage charge</div>
							<div style={{ color: '#47464A' }}>
								<span style={{ color: '#00B3FF' }}>
									<strong>USD {currentPaidPlan.additionalStorageCharge}</strong>
								</span>
								/2 TBs/month
							</div>
						</div>
						<div className={styles['feature-wrappers']}>
							<div className={styles['feature-labels']}>GCP Processing</div>
							<div style={{ color: '#47464A' }}>
								{currentPaidPlan.gcpProcessingAllowed ? (
									currentPaidPlan.gcpProcessingPrice === 0 ? (
										'No additional charges'
									) : (
										<>
											{' '}
											<span style={{ color: '#00B3FF' }}>
												<strong>USD {currentPaidPlan.gcpProcessingPrice}</strong>
											</span>{' '}
											per Processing{' '}
										</>
									)
								) : (
									<AeroImage alt='cross' src={IMAGES_SRC.CROSS_CIRCLE_ICON} />
								)}
							</div>
						</div>
					</div>
					<div style={{ fontSize: '18px', fontWeight: '700', color: '#84818A', marginTop: '15px', marginBottom: '10px' }}>Other Plans</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						{otherPlans.map(item => (
							<AeroButton onClick={() => capturePlan(item, currentDuration)} key={item.type} height='67px' className={styles['other-palns-btn']}>
								<div>{capitalize(item.type)}</div>
								<div style={{ fontSize: '14px', color: '#00B3FF' }}>
									USD {item.charge[currentDuration]}/{currentDuration === 'YEARLY' ? 'Year' : 'Month'}
								</div>
							</AeroButton>
						))}
					</div>
				</div>
				<Divider className={styles['web-view']} style={{ background: '#ECEAEE', height: 'inherit', marginLeft: '30px', marginRight: '30px' }} type='vertical'></Divider>
				<div className={styles['web-view']}>
					{rightContentPhase === 1 ? (
						<PaidPlanModalRightBillingContent updateRightContentPhase={updateRightContentPhase} planCharge={planCharge} planEndDate={planEndDate} {...props} />
					) : (
						<PaidPlanModalRightPaymentContent planCharge={planCharge} planEndDate={planEndDate} {...props} />
					)}
				</div>
				{/* <div>
			{discountPercentage ? (
				<>
					<span style={{ color: '#ABA8B0', fontSize: '24px' }}>
						<s>USD {currentPaidPlan.charge}</s>
					</span>{' '}
					<span className={styles['subscription-price']}>{getAsUSD(planCharge)}</span>
				</>
			) : (
				<span className={styles['subscription-price']}>{getAsUSD(currentPaidPlan.charge)}</span>
			)}

			<span className={styles['subscription-price-duration']}>{ currentDuration === 'YEARLY' ? '/Year' : '/Month'} </span>
			{country === 1 ? <span className={styles['gst-text']}>(+ 10% GST)</span> : null}
		</div> */}
			</div>
		</>
	)
}

export default PaidPlanModalContent
