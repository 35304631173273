import React from 'react'
import moment from 'moment'
import AeroImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import AeroButton from '../../../AeroComponents/Button'
import styles from '../subscription.module.sass'
import { Divider, Progress } from 'antd'
import { capitalize } from '../../../Services/UtilityService'

const SubscriptionDetailsTopSection = props => {
	const { data, goToBuyMode, handlePlanCancel, isCancelled, inGrace, isDowngrade } = props

	const storageLimit = data.storageLimit
	let storagePercentage = 0
	if (storageLimit) {
		let valInGb = storageLimit.split(' ')
		valInGb = Number(valInGb[0]) * 1024
		storagePercentage = (data.usedStorage / valInGb) * 100
	}

	const RIGHT_DATE_CONTENT = (
		<>
			{data.status === 'UPCOMING' ? (
				<div style={{ textAlign: 'justify', width: '50%', fontSize: '12px' }}>
					Your free trial expires on{' '}
					<strong>
						{moment
							.utc(data.freeTrialEndDate)
							.local()
							.format('DD/MM/YYYY')}
					</strong>
					, you will be charged <strong>USD {data.amount}</strong> thereafter.
				</div>
			) : (
				<div
					style={{
						top: !inGrace ? (isCancelled ? (isDowngrade ? '0px' : '18px') : '0px') : '18px',
					}}
					className={styles['top-right-date-content']}
				>
					<div>
						Start Date -{' '}
						{moment
							.utc(data.startDate)
							.local()
							.format('DD/MM/YYYY')}
					</div>
					<div>
						{isCancelled ? 'Expires ' : inGrace ? 'Expired ' : 'Renews '} On -{' '}
						{moment
							.utc(data.endDate)
							.local()
							.format('DD/MM/YYYY')}
					</div>
					{!inGrace ? (
						isCancelled ? (
							isDowngrade && data.pendingPlan ? (
								<>
									<div>Upcoming plan - {capitalize(data.pendingPlan.type)}</div>
									<div>Estimated amount - USD {data.pendingPlan.price}</div>
								</>
							) : null
						) : (
							<>
								<div>Upcoming plan - {capitalize(data.type)}</div>
								<div>Estimated amount - USD {data.amount}</div>
							</>
						)
					) : null}
				</div>
			)}
		</>
	)

	return (
		<div className={styles['top-section-wrapper']}>
			<>
				<div className={[styles['gigapixel-box'], styles['details-box']].join(' ')}>
					<div className={styles['web-view']}>
						<AeroImage otherstyles={{ marginBottom: '15px' }} src={IMAGES_SRC.GIGAPIXEL_ICON} alt='gig-asset' />
						<div>
							<div className={styles['details-box-top-txt']}>Your Gigapixel</div>
							<div style={{ fontSize: '28px', fontWeight: '700' }}>{data.gigapixelsLeft}</div>
						</div>
					</div>
					<div className={styles['mobile-view']}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex' }}>
								<AeroImage src={IMAGES_SRC.GIGAPIXEL_ICON} alt='gig-asset' />
								<div className={styles['gigapixel-box-top-txt-mobile']}>Your Gigapixel</div>
							</div>

							<div style={{ fontSize: '28px', fontWeight: '700' }}>{data.gigapixelsLeft}</div>
						</div>
					</div>
				</div>
				<div className={[styles['storage-box'], styles['details-box']].join(' ')}>
					<div style={{ display: 'flex' }}>
						<AeroImage src={IMAGES_SRC.STORAGE_ICON} alt='gig-asset' />
						<div style={{ marginLeft: '15px' }}>
							<div className={styles['details-box-top-txt']}>Your Storage</div>
							<div style={{ fontSize: '14px', color: '#47464A', display: 'flex' }}>
								<div className={styles['storage-text']}>
									<Progress
										className={styles['progress-bar']}
										style={{ marginRight: '10px' }}
										showInfo={false}
										percent={storagePercentage}
										strokeColor='#00B3FF'
									/>{' '}
									<div>
										{data.usedStorage} GB of {data.storageLimit} used
									</div>
								</div>
							</div>
						</div>
					</div>
					<AeroButton disabled={true} otherstyles={{ marginTop: '35px' }} height='38px' className={styles['details-box-btn']}>
						Buy Storage
					</AeroButton>
				</div>
				<div className={[styles['plan-box'], styles['details-box']].join(' ')}>
					<div className={styles['mobile-view']}>
						<div className={styles['top-right-box-wrapper']}>
							<div>
								<AeroImage src={IMAGES_SRC.SUBSCRIPTION_BIG_ICON} alt='gig-asset' />
								<div className={styles['details-box-top-sub-txt']}>Your Subscription</div>
								<div style={{ fontSize: '28px', fontWeight: '700' }}>{capitalize(data.type)}</div>
							</div>
							<Divider style={{ height: '120px' }} type='vertical' />

							<>{RIGHT_DATE_CONTENT}</>
						</div>
					</div>
					<div className={styles['web-view']}>
						<div className={styles['top-right-box-wrapper']}>
							<AeroImage src={IMAGES_SRC.SUBSCRIPTION_BIG_ICON} alt='gig-asset' />
							<div style={{ marginLeft: '20px' }}>
								<div className={styles['details-box-top-sub-txt']}>Your Subscription</div>
								<div style={{ fontSize: '28px', fontWeight: '700' }}>{capitalize(data.type)}</div>
							</div>
							<Divider style={{ height: '32.5px', position: 'relative', top: '20px', left: data.status === 'UPCOMING' ? '0px' : '25px' }} type='vertical' />
							{RIGHT_DATE_CONTENT}
						</div>
					</div>
					{(isCancelled && !isDowngrade) || moment.utc(data.endDate).local() < moment() ? (
						<AeroButton block type='primary' onClick={goToBuyMode} otherstyles={{ marginTop: '15px' }} height='38px'>
							Buy Subscription
						</AeroButton>
					) : (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<AeroButton onClick={goToBuyMode} height='38px' className={styles['details-box-btn']}>
								Change Plans
							</AeroButton>
							<AeroButton onClick={handlePlanCancel} height='38px' type='text' otherstyles={{ color: '#00B3FF', marginTop: '14px' }}>
								Cancel Subscription
							</AeroButton>
						</div>
					)}
				</div>
			</>
		</div>
	)
}

export default SubscriptionDetailsTopSection
