import { Form, Input, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { contactSupport } from '../../../Stores/UserSession/action'
import AeroInput from '../../../AeroComponents/Input'
import AeroButton from '../../../AeroComponents/Button'
import { validateEmailAddress } from '../../../Services/UtilityService'

const ContactSupportContent = props => {
	const { toggleContactSupport, contactSupport, contacted } = props
	const [waitForApi, setWaitForApi] = useState(false)

	const recordValues = values => {
		const { email, subject, description } = values
		const body = {
			email: email,
			type: subject,
			description: description,
		}
		setWaitForApi(true)
		contactSupport(body)
	}
	useEffect(() => {
		if (contacted && waitForApi) {
			setWaitForApi(false)
			toggleContactSupport()
		}
	}, [contacted])

	return (
		<Form onFinish={recordValues} layout='vertical'>
			<Space direction='vertical' size='large' style={{ width: '100%' }}>
				<Form.Item rules={[{ required: true, message: 'Please input email!', whitespace: true }, { validator: validateEmailAddress }]} name='email' label='Email'>
					<AeroInput type='email' placeholder='Type here' />
				</Form.Item>
				<Form.Item rules={[{ required: true, message: 'Please input subject!', whitespace: true }]} name='subject' label='Subject'>
					<Select size='large' placeholder='Select'>
						<Select.Option value='query'>Query</Select.Option>
						<Select.Option value='complaint'>Complaint</Select.Option>
						<Select.Option value='request'>Request</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item rules={[{ required: true, message: 'Please input description!', whitespace: true }]} name='description' label='Description'>
					<Input.TextArea placeholder='Type here' style={{ borderRadius: '6px' }} rows={4} />
				</Form.Item>
				<Form.Item>
					<AeroButton htmlType='submit' size='large' type='primary' block>
						Submit
					</AeroButton>
				</Form.Item>
			</Space>
		</Form>
	)
}

const mapStateToProps = state => {
	return {
		contacted: state.UserSessionReducer.contacted,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		contactSupport: bindActionCreators(contactSupport, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContactSupportContent)
