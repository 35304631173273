import { Divider, List } from 'antd'
import React from 'react'

export const PROCESSING_CAMERA_TYPE_DATA = {
	RGB: (
		<>
			<List.Item>
				<List.Item.Meta title='Description:' description={<span style={{ color: '#262626' }}>Traditional imaging systems and optical sensors.</span>} />
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta title='Examples:' description={<span style={{ color: '#262626' }}>DJI Inspire, DJI Mavic Pro, DJI Mavic Air 2s, DJI Mini.</span>} />
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Image Format:'
					description={
						<span style={{ color: '#262626' }}>
							Captured in either <strong>JPEG</strong> or <strong>PNG</strong> format with a 90º orientation relative to the ground.{' '}
						</span>
					}
				/>
			</List.Item>
		</>
	),
	MULTISPECTRAL: (
		<>
			<List.Item>
				<List.Item.Meta
					title='Description:'
					description={
						<span style={{ color: '#262626' }}>
							Advanced sensors designed to capture non-visible light, converting this data into valuable insights for agricultural applications.
						</span>
					}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Examples:'
					description={<span style={{ color: '#262626' }}>Sequoia, RedEdge, DJI M30, DJI Matrice 350 RTK, DJI Matrice 300, DJI P4 Enterprise.</span>}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Image Format:'
					description={
						<span style={{ color: '#262626' }}>
							Acquired in <strong>.TIF</strong> format with a 90º orientation relative to the ground.
						</span>
					}
				/>
			</List.Item>
		</>
	),
}

export const PROCESSING_CAMERA_TYPE = {
	RGB: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '300px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_CAMERA_TYPE_DATA['RGB']}</div>
		</>
	),
	MULTISPECTRAL: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '190px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_CAMERA_TYPE_DATA['MULTISPECTRAL']}</div>
		</>
	),
}

export const PROCESSING_TYPE_DATA = {
	PADRAO_RGB: (
		<>
			<List.Item>
				<List.Item.Meta
					title='Description:'
					description={
						<span style={{ color: '#262626' }}>Processing tailored for urban areas, including structures such as buildings, roads and various constructions.</span>
					}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Generated Products:'
					description={
						<span style={{ color: '#262626' }}>Orthomosaic, Digital Terrain Model (DTM), Digital Surface Model (DSM), Contour Lines, Point Cloud and 3D model.</span>
					}
				/>
			</List.Item>
		</>
	),
	AGRICOLA_RGB: (
		<>
			<List.Item>
				<List.Item.Meta
					title='Description:'
					description={
						<span style={{ color: '#262626' }}>
							Processing optimized for homogeneous areas, specifically addressing regions characterized by either annual or perennial agricultural production.
						</span>
					}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Generated Products:'
					description={
						<span style={{ color: '#262626' }}>
							Orthomosaic, Digital Terrain Model (DTM), Digital Surface Model (DSM), Contour Lines, Point Cloud, 3D model and the VARI and IFV indices.{' '}
						</span>
					}
				/>
			</List.Item>
		</>
	),
	SILVICULTURA_RGB: (
		<>
			<List.Item>
				<List.Item.Meta
					title='Description:'
					description={<span style={{ color: '#262626' }}>Tailored for vegetation, encompassing various types of forest trees as well as native forests.</span>}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Generated Products:'
					description={
						<span style={{ color: '#262626' }}>
							Orthomosaic, Digital Terrain Model (DTM), Digital Surface Model (DSM), Contour Lines, Point Cloud, 3D model and the VARI and IFV indices.{' '}
						</span>
					}
				/>
			</List.Item>
		</>
	),
	AGRICOLA_MULTISPECTRAL: (
		<>
			<List.Item>
				<List.Item.Meta
					title='Description:'
					description={
						<span style={{ color: '#262626' }}>
							Tailored for processing images captured by multispectral sensors in agricultural zones, requiring the images to be in <strong>.TIF</strong> format.{' '}
						</span>
					}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Generated Products:'
					description={
						<span style={{ color: '#262626' }}>
							<strong>NDVI</strong> , <strong>NDRE</strong> and <strong>SAVI</strong> and reflectance maps.
						</span>
					}
				/>
			</List.Item>
		</>
	),
	PADRAO_3D: (
		<>
			<List.Item>
				<List.Item.Meta title='Description:' description={<span style={{ color: '#262626' }}>Specialized processing designed to produce high-quality 3D models.</span>} />
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta title='Restrictions:' description={<span style={{ color: '#262626' }}>A maximum of 500 images can be used per project.</span>} />
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Generated Products:'
					description={
						<span style={{ color: '#262626' }}>
							<strong>3D Model</strong> , <strong>Digital Surface Model</strong>, <strong>Point Cloud</strong> and <strong>Orthomosaic</strong>.
						</span>
					}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='Note:'
					description={
						<span style={{ color: '#262626' }}>
							Please be aware that 2D products might display inconsistencies. This is due to the incorporation of skewed images in this specific processing method.
						</span>
					}
				/>
			</List.Item>
		</>
	),
}

export const PROCESSING_TYPE = {
	PADRAO_RGB: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '250px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_TYPE_DATA['PADRAO_RGB']}</div>
		</>
	),
	AGRICOLA_RGB: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '250px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_TYPE_DATA['AGRICOLA_RGB']}</div>
		</>
	),
	SILVICULTURA_RGB: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '250px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_TYPE_DATA['SILVICULTURA_RGB']}</div>
		</>
	),
	AGRICOLA_MULTISPECTRAL: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '250px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_TYPE_DATA['AGRICOLA_MULTISPECTRAL']}</div>
		</>
	),
	PADRAO_3D: (
		<>
			<div style={{ float: 'left' }}>
				<Divider style={{ marginLeft: '70px', height: '250px' }} type='vertical'></Divider>
			</div>

			<div style={{ display: 'inline-block', width: '60%', marginLeft: '120px' }}>{PROCESSING_TYPE_DATA['PADRAO_3D']}</div>
		</>
	),
}

export const FLIGHT_LOG_TYPE = {
	NOT_SET: {
		desc: 'Images that already have geographic coordinates inserted in their properties.',
		fileType: 'In general, multirotor images already have a geotag and this option must be selected.',
	},
	LAT_LONG: {
		desc: 'For aircraft using RTK/PPK technology, a .txt file must be inserted with the correct geographic coordinates.',
		fileType: `.txt file respectively with latitude, longitude and altitude , with values ​​separated by " " (single spacing) . The decimal place
		separator must be "." (point).`,
	},
}

export const PROCESSING_CAMERA_TYPE_ENUMS = {
	RGB: 'RGB',
	MULTISPECTRAL: 'Multispectral',
}

export const PROCESSING_TYPE_ENUMS = {
	PADRAO_RGB: 'Urban',
	AGRICOLA_RGB: 'Rural',
	SILVICULTURA_RGB: 'Forestry',
	AGRICOLA_MULTISPECTRAL: 'Agriculture',
	PADRAO_3D: '3D',
}

export const PROCESSING_FLIGHT_LOG_ENUMS = {
	NOT_SET: 'Georeferenced Photos',
	LAT_LONG: 'RTK/PPK Flight Log',
}

export const PROCESSING_STATUS_ENUMS = {
	CREATED: 'Created',
	PROCESSING: 'Started',
	ERROR: 'Error',
	ENDED: 'Completed',
	WAITING_ADD_GCP: 'Waiting for GCP',
	ON_QUEUE: 'In Queue',
}

export const INFO_TEXT = {
	HEAD: {
		TRUE_TEXT1: (
			<span>
				Please upload <span style={{ color: '#00B3FF', fontFamily: 'lato' }}>JPEG</span> or <span style={{ color: '#00B3FF', fontFamily: 'lato' }}>PNG</span> file only
			</span>
		),
		FALSE_TEXT1: (
			<span>
				Please upload <span style={{ color: '#00B3FF', fontFamily: 'lato' }}>JPEG</span> or <span style={{ color: '#00B3FF', fontFamily: 'lato' }}>PNG</span> file only
			</span>
		),
		TRUE_TEXT2: (
			<span>
				Please upload <span style={{ color: '#00B3FF', fontFamily: 'lato' }}>TIFF</span> file only
			</span>
		),
		FALSE_TEXT2: (
			<span>
				Please upload <span style={{ color: '#00B3FF', fontFamily: 'lato' }}>TIFF</span> file only
			</span>
		),
	},
	DESC1: {
		TYPE1_TEXT: (
			<span style={{ color: '#212222' }}>
				As you've chosen RGB image processing, please upload only <span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>JPG</span> or
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> PNG </span> files to ensure successful processing. Additionally, please use the
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> Control Point File uploader </span> for{' '}
				<strong style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> Ground Control Points Mapping file (.txt). </strong> Uploading files in other formats
				could lead to processing errors.
			</span>
		),
		TYPE2_TEXT: (
			<span style={{ color: '#212222' }}>
				As you've chosen RGB image processing, please upload only <span style={{ color: '#00B3FF', fontFamily: 'lato', fontWeight: 'bold' }}> Georeferenced </span>
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> JPG</span> or{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> PNG</span> files to ensure successful processing. Uploading any other file formats may
				result in processing errors
			</span>
		),
		TYPE3_TEXT: (
			<span style={{ color: '#212222' }}>
				As you've chosen RGB image processing with non-georeferenced images, please upload only{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>JPG</span> or{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>PNG</span> files to ensure successful processing. Additionally, please use the{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>Flight Log File uploader</span> specifically for{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>RTK/PPK Flight log</span> file. Uploading files in other formats could lead to processing
				errors.
			</span>
		),
		TYPE4_TEXT: (
			<span style={{ color: '#212222' }}>
				As you've chosen RGB image processing, please upload only <span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>JPG</span> or{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>PNG</span> files to ensure successful processing. Additionally, please use the{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>Flight Log File uploader</span> specifically for{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> RTK/PPK Flight log</span> file{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>(.txt)</span> for the Flight Log file and{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>Control Point File uploader</span> for{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>Ground Control Points Mapping file (.txt).</span> Uploading files in other formats could
				lead to processing errors.{' '}
			</span>
		),
	},
	DESC2: {
		TYPE1_TEXT: (
			<span style={{ color: '#212222' }}>
				As you've chosen multispectral image processing, please upload only <span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>TIFF</span> files to
				ensure successful processing. Additionally, please use the{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>Control Point File uploader</span> for{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> Ground Control Points Mapping file (.txt)</span>. Uploading files in other formats could
				lead to processing errors.
			</span>
		),
		TYPE2_TEXT: (
			<span style={{ color: '#212222' }}>
				{' '}
				As you've chosen multispectral image processing, please upload only <span style={{ color: '#00B3FF', fontFamily: 'lato', fontWeight: 'bold' }}>
					{' '}
					Georeferenced
				</span>{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> TIFF</span> files to ensure successful processing. Uploading any other file formats may
				result in processing errors.
			</span>
		),
		TYPE3_TEXT: (
			<span style={{ color: '#212222' }}>
				As you've chosen multispectral image processing, please upload only <span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}>TIFF</span> files to
				ensure successful processing. Additionally, please use the <span style={{ color: '#212222', fontFamily: 'lato' }}> Control Point File uploader </span> for{' '}
				<span style={{ color: '#212222', fontFamily: 'lato', fontWeight: 'bold' }}> Ground Control Points Mapping file (.txt)</span>. Uploading files in other formats could
				lead to processing errors.
			</span>
		),
	},
}

export const INFO_HEAD_TEXT = {
	RGB: {
		NOT_SET: {
			true: INFO_TEXT.HEAD.TRUE_TEXT1,
			false: INFO_TEXT.HEAD.FALSE_TEXT1,
		},
		LAT_LONG: {
			true: INFO_TEXT.HEAD.TRUE_TEXT1,
			false: INFO_TEXT.HEAD.FALSE_TEXT1,
		},
	},
	MULTISPECTRAL: {
		NOT_SET: {
			true: INFO_TEXT.HEAD.TRUE_TEXT2,
			false: INFO_TEXT.HEAD.FALSE_TEXT2,
		},
		LAT_LONG: {
			true: INFO_TEXT.HEAD.TRUE_TEXT2,
			false: INFO_TEXT.HEAD.FALSE_TEXT2,
		},
	},
}

export const INFO_DESC_TEXT = {
	RGB: {
		NOT_SET: {
			true: INFO_TEXT.DESC1.TYPE1_TEXT,
			false: INFO_TEXT.DESC1.TYPE2_TEXT,
		},
		LAT_LONG: {
			true: INFO_TEXT.DESC1.TYPE4_TEXT,
			false: INFO_TEXT.DESC1.TYPE3_TEXT,
		},
	},
	MULTISPECTRAL: {
		NOT_SET: {
			true: INFO_TEXT.DESC2.TYPE1_TEXT,
			false: INFO_TEXT.DESC2.TYPE2_TEXT,
		},
		LAT_LONG: {
			true: INFO_TEXT.DESC2.TYPE1_TEXT,
			false: INFO_TEXT.DESC2.TYPE3_TEXT,
		},
	},
}

export const SHOW_LOG_FILE_UPLOADER = {
	RGB: {
		NOT_SET: false,
		LAT_LONG: true,
	},
	MULTISPECTRAL: {
		NOT_SET: false,
		LAT_LONG: false,
	},
}

export const SHOW_CONTROL_POINTS_UPLOADER = {
	RGB: {
		NOT_SET: {
			true: true,
			false: false,
		},
		LAT_LONG: {
			true: true,
			false: false,
		},
	},
	MULTISPECTRAL: {
		NOT_SET: {
			true: true,
			false: false,
		},
		LAT_LONG: {
			true: false,
			false: false,
		},
	},
}
