import React, { Component } from 'react'
import { Divider } from 'antd'
import AeroImage from '../../AeroComponents/Image'
import './header.sass'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { gotToHome } from '../../Services/NavigationService'

class Header extends Component {
	render() {
		return (
			<>
				<div style={{ background: 'white', padding: '15px' }}>
					<AeroImage
						onClick={() => gotToHome()}
						otherstyles={{ position: 'relative', top: '15px', left: '15px', cursor: 'pointer' }}
						src={IMAGES_SRC.HEADER_LOGO}
						alt='logo'
					/>
				</div>
				<div style={{ background: 'white' }}>
					<Divider />
				</div>
			</>
		)
	}
}

export default Header
