import React from 'react'
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api'

const containerStyle = {
	width: '243px',
	height: '149px',
}

const options = {
	fillColor: 'lightorange',
	fillOpacity: 0.4,
	strokeColor: 'orange',
	strokeOpacity: 0.8,
	strokeWeight: 1.5,
}

function MapThumbnail({ center, polyPath }) {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAxj3E73qJL4BQAPyKN9J8TYBWUsv32dvI',
	})

	const onLoad = mapInstance => {
		mapInstance.setMapTypeId('satellite')
	}

	return isLoaded ? (
		<div style={{ borderRadius: '7px 7px 0px 0px', overflow: 'hidden' }}>
			<GoogleMap
				options={{
					disableDefaultUI: true,
					zoomControl: false,
					mapTypeControl: false,
					keyboardShortcuts: false,
				}}
				mapContainerStyle={containerStyle}
				center={center}
				zoom={13}
				onLoad={onLoad}
			>
				<Polygon paths={polyPath} options={options} />
			</GoogleMap>
		</div>
	) : (
		<></>
	)
}

export default React.memo(MapThumbnail)
