import React from 'react'
import AeroImage from '../Image'

const CommonModalContent = props => {
	const { topAsset, heading, description, subDescription } = props
	return (
		<div style={{ textAlign: 'center' }}>
			<AeroImage otherstyles={{ marginTop: '20px' }} alt='email-country' src={[topAsset]} />
			<div className='signup-success-title' style={{ margin: 'auto', padding: '30px' }}>
				{heading}
			</div>
			<div className='signup-success-subtitle' style={{ fontWeight: '400', width: '100%' }}>
				{description}
			</div>
			<div className='signup-success-footer'>{subDescription}</div>
		</div>
	)
}

export default CommonModalContent
