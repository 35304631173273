import React from 'react'
import styles from '../subscription.module.sass'
import { Radio } from 'antd'
import { capitalize } from '../../../Services/UtilityService'

const BillingCycleBox = props => {
	const { type, amount, discountPercentage, freeTrialAvailed } = props

	return (
		<div className={styles['billing-cycle-box']}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className={styles['plan-duration-txt']}>{capitalize(type)}</div>
				<Radio value={type}></Radio>
			</div>
			{freeTrialAvailed ? null : <div style={{ color: '#47464A', fontSize: '14px' }}>15-Days Free trial</div>}
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ color: '#47464A', fontSize: '16px', fontWeight: '600' }}>
					{freeTrialAvailed ? null : 'then'} <span style={{ color: '#00B3FF', fontWeight: '500', fontSize: '14px' }}>USD</span>
					<span style={{ color: '#00B3FF', fontWeight: '700', fontSize: '24px' }}>{amount}</span> /{type === 'annual' ? 'Year' : 'Month'}
				</div>
				{type === 'annual' ? <div className={styles['billing-discount-box']}>Save {discountPercentage}%</div> : null}
			</div>
		</div>
	)
}

export default BillingCycleBox
