/*eslint-disable*/
import React from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { Button } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import AeroButton from '../Button'
import AeroImage from '../Image'
import { IMAGES_SRC } from '../Image/image.constants'

class PaymentProfile extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			paymentEle: {
				stripeEle: <CardElement style={{ base: { fontSize: '18px' } }} />,
				submitDisplay: 'Pay',
				cancelDisplay: 'Cancel',
			},
			ibanRef: null,
			btnLoader: false,
		}
		this.submitRef = React.createRef()
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState({ btnLoader: true })
		if (this.props.stripe) {
			this.props.stripe
				.createToken({ type: 'card' })
				.then(result => {
					this.props.updateCardDetails(result.token.id)
				})
				.finally(() => this.setState({ btnLoader: false }))
		} else {
			console.log("Stripe.js hasn't loaded yet.")
		}
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				{this.state.paymentEle.stripeEle}
				<div style={{ color: '#8C8C8C', textAlign: 'center', margin: '30px', fontWeight: '600' }}>
					PAYMENTS POWERED BY <AeroImage alt='stripe' otherstyles={{ padding: '5px', marginTop: '-3px' }} src={IMAGES_SRC.STRIPE_LOGO} />
				</div>
				<div style={{ display: 'flex', marginTop: '15px', justifyContent: 'flex-end' }}>
					{this.state.paymentEle.cancelDisplay && (
						<AeroButton otherstyles={{ marginRight: '15px' }} onClick={this.props.cancel}>
							{this.state.paymentEle.cancelDisplay}
						</AeroButton>
					)}
					<AeroButton loading={this.state.btnLoader} htmlType='submit' type='primary'>
						<LockOutlined style={{ display: 'inline-flex', fontSize: '108%' }}></LockOutlined>
						{this.state.paymentEle.submitDisplay}
						&nbsp;{'$ ' + this.props.displayAmount}
					</AeroButton>
				</div>
			</form>
		)
	}
}

const InjectedPaymentProfile = injectStripe(PaymentProfile)
export default InjectedPaymentProfile
