import React from 'react'
import './side-bar-menu.sass'
import WebSidebar from './web-sidebar'
import MobileSidebar from './mobile-sidebar'

const SideBarNavigation = props => {
	return (
		<>
			<WebSidebar {...props} />
			<MobileSidebar {...props} />
		</>
	)
}

export default SideBarNavigation
