export const AEROLOGIX_ENDPOINTS = {
	// Onboardin APIs
	LOGIN: 'v1/login',
	GET_SOCIAL_AUTH: 'v1/socialMediaLogin',
	GET_COUNTRY_LIST: 'v1/country',
	REPORT_ERROR: 'v1/admin/forwardException',
	REGISTER_USER: 'v1/stallion/register',
	RECOVER_PASSWORD: 'v1/stallion/forgot/password',
	CONTACT_SUPPORT: 'v1/support/contact-us',
	GET_PROFILE: 'v1/stallion/profile',
	UPDATE_PROFILE: 'v1/stallion/update-profile',
	SEND_EMAIL_OTP: 'v1/stallion/email-otp',
	VALIDATE_EMAIL_OTP: 'v1/stallion/verify-otp',
	UPDATE_PASSWORD: 'v1/stallion/change/password',
	GET_OTP: 'v1/stallion/otp',
	RESEND_VERIFICATION_LINK: 'v1/stallion/reverify',

	// Subscription APIs
	GET_ALL_SUBSCRIPTIONS: 'v1/stallion/all-plans',
	SEND_CARD_TOKEN: 'v1/stallion/subscribe',
	GET_ACTIVE_PLAN_DETAILS: 'v1/stallion/active-plan',
	GET_PAYMENT_TRANSACTIONS: 'v1/stallion/transactions/payment',
	GET_GIGAPIXEL_TRANSACTIONS: 'v1/stallion/transactions/gigapixel',
	CANCEL_PLAN: 'v1/stallion/cancel-subscription',
	VALIDATE_DISCOUNT_CODE: 'v1/discount/validate-coupon',
	CONFIRM_PAYMENT: 'v1/stallion/client-secret',
	CANCEL_PAYMENT: 'v1/stallion/cancel-payment',
	SEND_CARD_TOKEN_FOR_FREE_TRIAL: 'v1/stallion/activate-freeTrial',

	// My Maps APIs
	GET_ALL_MAPS: 'v1/stallion/processing/all',
	CREATE_PROCESSING: 'v1/stallion/create-processing',
	GET_PROCESSING: 'v1/stallion/processing',
	SHARE_PROCESSING: 'v1/stallion/processing/share',
	DELETE_PROCESSING: 'v1/stallion/processing/delete',
	UPDATE_PROCESSING: 'v1/stallion/update-processing',
	UPLOAD_FLIGHT_LOG_FILE: 'v1/file/stallion/uploadFlightLog',
	GET_BOX_CREDS: 'v1/stallion/box-credentials',
	DELETE_FLIGHT_LOG: 'v1/stallion/delete-flightLog',
	CHECK_TRANSFER_STATUS: 'v1/stallion/upload-status',
	UPDATE_STAGE: 'v1/stallion/update-stage',
	START_PROCESSING: 'v1/stallion/start-processing',
	CREATE_TRANSACTION: 'v1/stallion/create-transaction',
	CHARGE_USER: 'v1/stallion/charge',
	UPLOAD_CONTROL_POINTS_FILE: 'v1/file/stallion/uploadControlPoints',
	DELETE_CONTROL_POINTS: 'v1/stallion/delete-controlPoint',
}
