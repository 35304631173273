import React, { useEffect } from 'react'
import { Card } from 'antd'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const BoxUploader = props => {
	const { boxCreds, returnToken, cameraType, enableNextBtn } = props
	useEffect(() => {
		const accessToken = boxCreds.accessToken
		const dataFolderId = boxCreds.dataFolderId
		const dataExplorer = new window.Box.ContentExplorer()
		if (dataFolderId && accessToken) {
			dataExplorer.addListener('upload', event => {
				enableNextBtn(event)
			})
			dataExplorer.show(dataFolderId, returnToken, {
				container: '.dataContainerBoxDev',
				logoUrl: IMAGES_SRC.DRONE_LOGO,
				canShare: false,
				contentUploaderProps: {
					fileLimit: 2500,
					isFolderUploadEnabled: false,
				},
			})
		}
	}, [boxCreds])

	return (
		<Card
			style={{ marginTop: '20px', borderRadius: '16px' }}
			headStyle={{ borderRadius: '16px 16px 0px 0px' }}
			type='inner'
			title={
				<span>
					Upload Images:{' '}
					<strong>
						(PLEASE ENSURE TO ONLY UPLOAD <span style={{ color: '#00B3FF' }}>{cameraType === 'RGB' ? 'JPEG or PNG' : 'TIFF'}</span> FILES)
					</strong>
				</span>
			}
		>
			<div className={'dataContainerBoxDev'}></div>
		</Card>
	)
}

export default BoxUploader
