// react & redux imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from 'antd'
import ScreenLoader from '../Loader'
import './aero-modal.scss'

class AeroModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: props.visible,
			modalLoading: props.modalLoading,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.modalLoading !== nextProps.modalLoading) {
			this.setState({
				modalLoading: nextProps.modalLoading,
			})
		}
		if (this.props.visible !== nextProps.visible) {
			this.setState({
				visible: nextProps.visible,
			})
		}
	}

	render() {
		return (
			<Modal
				className={'aero-modal'}
				{...this.props}
				open={this.state.visible}
				title={this.props.title}
				onOk={this.props.onOk}
				onCancel={this.props.onCancel}
				footer={this.props.footer}
			>
				{this.state.modalLoading && <ScreenLoader isModal />}
				{this.props.children}
			</Modal>
		)
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

const mapStateToProps = state => {
	return {
		modalLoading: state.UserSessionReducer.modalLoading,
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AeroModal)
