export const IMAGES_SRC = {
	HEADER_LOGO: require('../../Assets/Images/header-icon.svg'),
	SMALL_INFO_ICON: require('../../Assets/Images/small-info-icon.svg'),
	LOGIN_INFO_ASSET: require('../../Assets/Images/login-info-asset.svg'),
	GOOGLE_ICON: require('../../Assets/Images/google-icon.svg'),
	FACEBOOK_ICON: require('../../Assets/Images/facebook-icon.svg'),
	BIG_CHECK_ICON: require('../../Assets/Images/big-check-icon.svg'),
	EMAIL_COUNTRY_ICON: require('../../Assets/Images/email-country-icon.svg'),
	EMAIL_OTP_ICON: require('../../Assets/Images/email-otp-icon.svg'),
	EMAIL_SENT_ICON: require('../../Assets/Images/email-sent-icon.svg'),
	EMAIL_VERIFICATION_SUCCESSFUL: require('../../Assets/Images/email-verification-successful.svg'),
	MY_MAPS_ICON: require('../../Assets/Images/my-maps.svg'),
	MY_MAPS_ICON_SELECTED: require('../../Assets/Images/my-maps-selected.svg'),
	SUBSCRIPTION_ICON: require('../../Assets/Images/subscription.svg'),
	SUBSCRIPTION_ICON_SELECTED: require('../../Assets/Images/subscription-selected.svg'),
	PROFILE_ICON: require('../../Assets/Images/profile.svg'),
	PROFILE_ICON_SELECTED: require('../../Assets/Images/profile-selected.svg'),
	HELP_ICON: require('../../Assets/Images/contact-us.svg'),
	HELP_ICON_SELECTED: require('../../Assets/Images/contact-us-selected.svg'),
	SIDEBAR_LOGO: require('../../Assets/Images/sidebar-logo.svg'),
	NOT_FOUND_ICON: require('../../Assets/Images/not_found_icon.svg'),
	ADD_SQUARE: require('../../Assets/Images/add-square.svg'),
	PARTY_POPPER: require('../../Assets/Images/party-popper.svg'),
	EMAIL_BIG_ICON: require('../../Assets/Images/email-big-icon.svg'),
	CHECK_CIRCLE_ICON: require('../../Assets/Images/check-circle-icon.svg'),
	CROSS_CIRCLE_ICON: require('../../Assets/Images/cross-circle-icon.svg'),
	BIG_GIFT_ICON: require('../../Assets/Images/big-gift-icon.svg'),
	GO_BACK_ICON: require('../../Assets/Images/go-back-icon.svg'),
	GIGAPIXEL_ICON: require('../../Assets/Images/gigapixel.svg'),
	STORAGE_ICON: require('../../Assets/Images/storage.svg'),
	SUBSCRIPTION_BIG_ICON: require('../../Assets/Images/subscription-big.svg'),
	STRIPE_LOGO: require('../../Assets/Images/stripe-logo.svg'),
	BIG_EXCLAMATION_ICON: require('../../Assets/Images/big-exclamation-icon.svg'),
	MAP_PLACEHOLDER: require('../../Assets/Images/map-placeholder.svg'),
	ARROW_RIGHT: require('../../Assets/Images/arrow-right.svg'),
	EDIT_ICON: require('../../Assets/Images/edit-icon.svg'),
	SHARE_ICON: require('../../Assets/Images/share-icon.svg'),
	TRASH_ICON: require('../../Assets/Images/trash-icon.svg'),
	CONTROL_POINTS_PIC1: require('../../Assets/Images/control-points-pic1.svg'),
	CONTROL_POINTS_PIC2: require('../../Assets/Images/control-points-pic2.svg'),
	CONTROL_POINTS_PIC3: require('../../Assets/Images/control-points-pic3.svg'),
	CONTROL_POINTS_PIC4: require('../../Assets/Images/control-points-pic4.svg'),
	PROCESS_FILE_TYPE_PIC: require('../../Assets/Images/process-file-type-pic.svg'),
	PHOTO_FILLED: require('../../Assets/Images/photo-filled.svg'),
	DRONE_LOGO: require('../../Assets/Images/drone-logo.svg'),
	LOADER: require('../../Assets/Images/loader.gif'),
	BIG_INFO_ICON: require('../../Assets/Images/big-info-icon.svg'),
	BROKEN_LINK: require('../../Assets/Images/broken-link.svg'),
}
