import React, { useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import styles from '../subscription.module.sass'
import AeroImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { capitalize } from '../../../Services/UtilityService'
import { SUBSCRIPTION_ITEM_HEADINGS } from '../constants'
import { Radio } from 'antd'

const SubscriptionPlansList = props => {
	const { subscriptionData, currentDuration, capturePlan, currentPaidPlan } = props
	const [inactiveList, setInactiveList] = useState([])

	const closeList = type => {
		let data = inactiveList
		if (data.includes(type)) {
			data = data.filter(item => item !== type)
		} else {
			data.push(type)
		}
		setInactiveList([...data])
	}

	const getAsUSD = (value, tail) => {
		return (
			<span>
				{' '}
				<span className={styles['subscription-price-usd']}> USD {value} </span> {tail}{' '}
			</span>
		)
	}
	return (
		<div className={styles['list-wrapper']}>
			<div className={styles['web-view']} style={{ width: '170px', marginTop: '195px' }}>
				<div className={styles['subscription-description-items']}>
					Free Gigapixels<sup>*</sup>
				</div>
				{SUBSCRIPTION_ITEM_HEADINGS.map(item => (
					<div key={item.key} style={{ marginTop: item.marginVal }} className={styles['subscription-description-items']}>
						{item.text}
					</div>
				))}
			</div>
			<Radio.Group className={styles['plan-radio-grp']} value={currentPaidPlan} onChange={e => capturePlan(e.target.value, currentDuration)}>
				{subscriptionData.map(item => (
					<div key={item.type}>
						{item.type === 'FREE TRIAL' ? null : (
							<div className={styles['list-margin']} style={{ marginTop: item.discountPercentage ? '0px' : '35px' }}>
								{item.discountPercentage ? (
									<div className={styles['subscription-discount-box']}>
										<span className={styles['subscription-discount-box-txt']}>Save {item.discountPercentage}%</span>
									</div>
								) : null}

								<div
									style={{ height: inactiveList.includes(item.type) ? '150px' : null }}
									className={
										currentPaidPlan?.id === item.id
											? [styles['subscription-box-wrapper'], styles['selected-plan-wrapper']].join(' ')
											: styles['subscription-box-wrapper']
									}
								>
									<div className={styles['subscription-title']}>
										<div>{capitalize(item.type)}</div>
										<Radio disabled={item.bought} value={item}></Radio>
										<div onClick={() => closeList(item.type)} className={styles['mobile-view']}>
											{inactiveList.includes(item.type) ? <DownOutlined style={{ cursor: 'pointer' }} /> : <UpOutlined style={{ cursor: 'pointer' }} />}
										</div>
									</div>
									<div style={{ marginTop: '20px', marginBottom: '35px' }}>
										{item.charge[currentDuration] ? <span className={styles['subscription-price-usd']}>USD </span> : ''}
										<span className={styles['subscription-price']}>{`${item.charge[currentDuration]}`}</span>
										<span className={styles['subscription-price-duration']}>{currentDuration === 'YEARLY' ? '/Year' : '/Month'} </span>
									</div>
									{inactiveList.includes(item.type) ? null : (
										<>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>
													Gigapixels<sup>*</sup>
												</div>{' '}
												<div>{item.gigapixelsAvailable}</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>Images upto per process</div>
												<div>{item.imagesAllowed}</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>Sharing capability</div>
												<div>
													{item.shareAllowed ? (
														<AeroImage alt='cross' src={IMAGES_SRC.CHECK_CIRCLE_ICON} />
													) : (
														<AeroImage alt='cross' src={IMAGES_SRC.CROSS_CIRCLE_ICON} />
													)}
												</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>Download capability</div>
												<div>
													{item.downloadAllowed ? (
														<AeroImage alt='cross' src={IMAGES_SRC.CHECK_CIRCLE_ICON} />
													) : (
														<AeroImage alt='cross' src={IMAGES_SRC.CROSS_CIRCLE_ICON} />
													)}
												</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>Additional GPs charge</div>
												<div>{item.additionalGigapixelsCharge ? getAsUSD(item.additionalGigapixelsCharge, ` per GP`) : '--'}</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>Storage limit</div>
												<div>{item.storageAvailable ? item.storageAvailable : '--'}</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>Additional storage charge</div>
												<div>{item.additionalStorageCharge ? getAsUSD(item.additionalStorageCharge, ` /2 TBs/month`) : '--'}</div>
											</div>
											<div className={styles['susbcription-box-general-items']}>
												<div className={styles['mobile-list-desc']}>GCP Processing</div>
												<div>
													{item.gcpProcessingAllowed ? (
														item.gcpProcessingPrice === 0 ? (
															<span style={{ color: '#6CBE44' }}>No additional charges</span>
														) : (
															<> {getAsUSD(item.gcpProcessingPrice, ` per Processing`)} </>
														)
													) : (
														<AeroImage alt='cross' src={IMAGES_SRC.CROSS_CIRCLE_ICON} />
													)}
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						)}
					</div>
				))}
			</Radio.Group>
		</div>
	)
}

export default SubscriptionPlansList
