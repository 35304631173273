import { post, get, put } from '../../Api/http-interceptor'
import * as ACTION from './actionTypes'

export const loginApi = body => {
	return post(ACTION.LOGIN, body)
}

export const recoverPasswordApi = body => {
	return post(ACTION.RECOVER_PASSWORD, body)
}

export const getCountryListApi = () => {
	return get(ACTION.GET_COUNTRY_LIST)
}

export const getSocialAuthApi = body => {
	return post(ACTION.GET_SOCIAL_AUTH, body)
}

export const reportErrorApi = body => {
	return post(ACTION.REPORT_ERROR, body)
}

export const registerUserApi = body => {
	return post(ACTION.REGISTER_USER, body)
}

export const contactSupportApi = body => {
	return post(ACTION.CONTACT_SUPPORT, body)
}

export const getProfileApi = () => {
	return get(ACTION.GET_PROFILE)
}

export const updateProfileApi = body => {
	return put(ACTION.UPDATE_PROFILE, body)
}

export const sendEmailOtpApi = body => {
	return post(ACTION.SEND_EMAIL_OTP, body)
}

export const validateEmailOtpApi = body => {
	return post(ACTION.VALIDATE_EMAIL_OTP, body)
}

export const updatePasswordApi = body => {
	return post(ACTION.UPDATE_PASSWORD, body)
}

export const getOtpApi = body => {
	return post(ACTION.GET_OTP, body)
}

export const resendVerificationLinkApi = body => {
	return post(ACTION.RESEND_VERIFICATION_LINK, body)
}
