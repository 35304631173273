import React from 'react'
import AeroButton from '../Button'
import CommonModalContent from './common-modal-content'

const GeneralConfirmationContent = props => {
	const { primaryBtnAction, primaryBtnText, secondaryBtnAction, secondaryBtnText } = props
	return (
		<div style={{ textAlign: 'center' }}>
			<CommonModalContent {...props} />
			<div style={{ margin: '15px', display: 'flex', justifyContent: 'space-between' }}>
				<AeroButton width='45%' onClick={primaryBtnAction} height='50px' type='primary'>
					{primaryBtnText}
				</AeroButton>
				<AeroButton otherstyles={{ color: '#00B3FF', border: '1px solid #00B3FF' }} width='45%' onClick={secondaryBtnAction} height='50px'>
					{secondaryBtnText}
				</AeroButton>
			</div>
		</div>
	)
}

export default GeneralConfirmationContent
