import React from 'react'
import { Redirect } from 'react-router-dom'
import ServerDown from '../AeroComponents/ServerDown'
import PageNotFound from '../AeroComponents/PageNotFound'
import Login from '../Modules/Onboarding'
import Signup from '../Modules/Onboarding/signup'
import SignupSuccessfull from '../Modules/Onboarding/signup-successfull'
import SocialLoginData from '../Modules/Onboarding/social-login-data'
import EmailConfirmation from '../Modules/Onboarding/email-confirmation'
import FrogotPassword from '../Modules/Onboarding/forgot-password'
import ForgotPasswordSent from '../Modules/Onboarding/forgot-password-sent'
import MyMaps from '../Modules/MyMaps'
import ReceiveAuth from '../Modules/Onboarding/receive-auth'
import EmailVerificationSuccessful from '../Modules/Onboarding/email-verification-successful'
import VerifyEmailOtp from '../Modules/Onboarding/verify-email-otp'
import Subscription from '../Modules/Subscription'
import Help from '../Modules/Help'
import Profile from '../Modules/Profile'
import EditMap from '../Modules/MyMaps/edit-map'
import Tests from '../Modules/Tests'
import EmailVerificationFail from '../Modules/Onboarding/email-verification-fail'

export const ROUTES = [
	{
		key: 1,
		path: '/',
		component: props => <MyMaps {...props} />,
	},
	{
		key: 2,
		path: '/my-maps',
		component: props => <MyMaps {...props} />,
	},
	{
		key: 3,
		path: '/edit-map/:processingId',
		component: props => <EditMap {...props} />,
	},
	{
		key: 4,
		path: '/subscription',
		component: props => <Subscription {...props} />,
	},
	{
		key: 5,
		path: '/subscription/:action',
		component: props => <Subscription {...props} />,
	},
	{
		key: 6,
		path: '/profile',
		component: props => <Profile {...props} />,
	},
	{
		key: 7,
		path: '/help',
		component: props => <Help {...props} />,
	},
	{
		key: 10,
		path: '/test',
		component: props => <Tests {...props} />,
	},
	{
		key: 8,
		path: '/server-down',
		component: props => <ServerDown {...props} />,
	},
	{
		key: 9,
		path: '*',
		component: props => <PageNotFound {...props} />,
	},
]
export const NON_COUNTRY_ROUTES = [
	{
		key: 1,
		path: '/',
		component: props => <SocialLoginData {...props} />,
	},
	{
		key: 2,
		path: '/social-login-data',
		component: props => <SocialLoginData {...props} />,
	},
	{
		key: 3,
		path: '/verify-email-otp',
		component: props => <VerifyEmailOtp {...props} />,
	},
	{
		key: 4,
		path: '*',
		component: props => <SocialLoginData {...props} />,
	},
]
export const NON_AUTH_ROUTES = [
	{
		key: 1,
		path: '/',
		component: props => <Login {...props} />,
	},
	{
		key: 2,
		path: '/signup',
		component: props => <Signup {...props} />,
	},
	{
		key: 3,
		path: '/signup-successfull',
		component: props => <SignupSuccessfull {...props} />,
	},
	{
		key: 4,
		path: '/email-confirmation',
		component: props => <EmailConfirmation {...props} />,
	},
	{
		key: 5,
		path: '/forgot-password',
		component: props => <FrogotPassword {...props} />,
	},
	{
		key: 6,
		path: '/forgot-password-sent',
		component: props => <ForgotPasswordSent {...props} />,
	},
	{
		key: 7,
		path: '/receive-auth/:authKey/STALLION/:channel',
		component: props => <ReceiveAuth {...props} />,
	},
	{
		key: 8,
		path: '/email-verification-success',
		component: props => <EmailVerificationSuccessful {...props} />,
	},
	{
		key: 9,
		path: '/email-verification-fail',
		component: props => <EmailVerificationFail {...props} />,
	},
	{
		key: 10,
		path: '*',
		component: props => (
			<Redirect
				to={{
					pathname: '/',
				}}
			/>
		),
	},
]
