import React, { useEffect, useState } from 'react'
import { Space } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getBoxCreds } from '../../../Stores/MyMaps/action'
import AeroButton from '../../../AeroComponents/Button'
import BoxUploader from './box-uploader'
import { getValueFromStorage, setValueToStorage } from '../../../Services/LocalStorageService'
import GeneralConfirmationContent from '../../../AeroComponents/Modal/general-confirmation-content'
import GeneralNotificationContent from '../../../AeroComponents/Modal/general-notification-content'
import AeroModal from '../../../AeroComponents/Modal'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { INFO_DESC_TEXT, INFO_HEAD_TEXT } from '../constants'
import ExtraUploader from './extra-uploader'

const ProcessUploads = props => {
	const {
		processingId,
		callUploadFlightLog,
		boxCreds,
		getBoxCreds,
		flightLogUrl,
		changeStage,
		callDeleteLog,
		processUpload,
		stage,
		uploadStatus,
		showFlightLogUploader,
		showControlPointsUploader,
		cameraType,
		flightLogType,
		gcpValue,
		flightControlPointsUrl,
		callDeleteControlPoints,
		callUploadFlightControlPoints,
		isNextDisabled,
		enableNextBtn,
	} = props
	const [startTime, setStartTime] = useState(moment())
	const [infoModal, setInfoModal] = useState(true)
	const [uploadConfirmationModal, setUploadConfirmationModal] = useState(false)
	const [reuploadStatus, setReuploadStatus] = useState(false)

	useEffect(() => {
		if (getValueFromStorage('localStore/0/bce.defaultViewMode')) {
			setValueToStorage('localStore/0/bce.defaultViewMode', '"grid"')
		}
	}, [])

	const boxTokenGenerator = () => {
		const duration = moment.duration(moment().diff(startTime))
		if (duration.asHours() >= 1) {
			return getBoxCreds({ data: processingId })
				.then(res => res?.value?.data?.accessToken)
				.catch(() => boxCreds.accessToken)
				.finally(() => setStartTime(moment()))
		} else return boxCreds.accessToken
	}

	const reuploadStep = status => {
		setReuploadStatus(status)
		setUploadConfirmationModal(true)
	}

	return (
		<>
			{showFlightLogUploader ? (
				<ExtraUploader
					label='Upload RTK/PPK Log File'
					fetchedValue={flightLogUrl}
					callDeleteFile={callDeleteLog}
					callUploadApi={callUploadFlightLog}
					processingId={processingId}
				/>
			) : null}

			{showControlPointsUploader ? (
				<ExtraUploader
					label='Upload Control Points File (.txt)'
					fetchedValue={flightControlPointsUrl}
					callDeleteFile={callDeleteControlPoints}
					callUploadApi={callUploadFlightControlPoints}
					processingId={processingId}
				/>
			) : null}

			<BoxUploader enableNextBtn={enableNextBtn} boxCreds={boxCreds} returnToken={boxTokenGenerator} cameraType={cameraType} />
			<div style={{ textAlign: 'right', marginTop: '20px' }}>
				<Space size='large'>
					<AeroButton onClick={() => changeStage(0)} width='170px' otherstyles={{ border: '1px solid #00B3FF', color: '#00B3FF' }}>
						Go Back
					</AeroButton>
					<AeroButton
						disabled={isNextDisabled}
						onClick={() => {
							stage === 2 || (stage === 1 && uploadStatus === 'TRANSFERRED') ? reuploadStep(true) : reuploadStep(false)
						}}
						width='170px'
						type='primary'
					>
						Next
					</AeroButton>
				</Space>
			</div>
			<AeroModal footer={false} visible={uploadConfirmationModal} onCancel={() => setUploadConfirmationModal(false)}>
				<GeneralConfirmationContent
					primaryBtnAction={() => setUploadConfirmationModal(false)}
					primaryBtnText='Go Back'
					secondaryBtnAction={() => {
						setUploadConfirmationModal(false)
						processUpload(reuploadStatus)
					}}
					secondaryBtnText='Confirm'
					topAsset={IMAGES_SRC.BIG_EXCLAMATION_ICON}
					heading={'Are All Files Uploaded?'}
					description={'Before proceeding, could you please confirm that all file uploads have been successfully completed?'}
				/>
			</AeroModal>
			<AeroModal footer={false} visible={infoModal} onCancel={() => setInfoModal(false)}>
				<GeneralNotificationContent
					btnAction={() => setInfoModal(false)}
					btnText='Okay, Got it'
					topAsset={IMAGES_SRC.BIG_INFO_ICON}
					heading={INFO_HEAD_TEXT[cameraType][flightLogType][gcpValue]}
					description={INFO_DESC_TEXT[cameraType][flightLogType][gcpValue]}
					subDescription=''
				/>
			</AeroModal>
		</>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		getBoxCreds: bindActionCreators(getBoxCreds, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(ProcessUploads)
