import React from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { gotToHome } from '../../Services/NavigationService'

const SignupSuccessfull = () => {
	return (
		<div style={{ textAlign: 'center', padding: '40px' }}>
			<AeroImage alt='big-check' src={IMAGES_SRC.BIG_CHECK_ICON} />
			<div className='signup-success-title'>Signed up successfully!</div>
			<div className='signup-success-subtitle'>Please verify your email to proceed. Verification link will expire in 48 hours</div>
			<div className='signup-success-timer'>
				{' '}
				Click{' '}
				<span onClick={() => gotToHome()} style={{ cursor: 'pointer', color: '#00B3FF' }}>
					here
				</span>{' '}
				to go to the Login Screen
			</div>
		</div>
	)
}

export default SignupSuccessfull
