import React from 'react'
import { CheckCircleFilled } from '@ant-design/icons'

const AeroNotificationContent = props => {
	return (
		<span style={{ color: 'white', position: 'relative', top: '4px' }}>
			<CheckCircleFilled style={{ fontSize: '20px', marginRight: '5px' }} /> {props.content}
		</span>
	)
}

export default AeroNotificationContent
