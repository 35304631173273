import moment from 'moment'

function capitalize(input) {
	return input
		.toLowerCase()
		.split(' ')
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ')
}

const timeDiffString = createdAt => {
	const minutes = moment().diff(moment.utc(createdAt).local(), 'minutes')
	const hours = moment().diff(moment.utc(createdAt).local(), 'hours')
	const days = moment().diff(moment.utc(createdAt).local(), 'days')
	const months = moment().diff(moment.utc(createdAt).local(), 'months')
	const years = moment().diff(moment.utc(createdAt).local(), 'years')
	if (minutes <= 60) {
		return `${minutes} minutes`
	} else if (hours <= 48) {
		return `${hours} hours`
	} else if (days <= 31) {
		return `${days} days`
	} else if (months <= 12) {
		return `${months} months`
	} else {
		return `${years} years`
	}
}

const debounce = (fn, timeout = 1000) => {
	let timer
	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			fn.apply(this, args)
		}, timeout)
	}
}

const textShortner = (text, chars) => (text.length <= 10 ? text : text.substring(0, chars) + '...')

const validateMobileNumber = (rule, value, callback) => {
	if(value==="" || value === null || value === undefined){
		callback()
	}
	const mob = /^[0-9]{1}[0-9]{7,10}$/
	if (mob.test(value) === false) {
		callback('Invalid mobile number!')
	} else {
		callback()
	}
}

const validateISDCode = (rule, value, callback) => {
	if(value==="" || value === null || value === undefined){
		callback()
	}
    const isdCodePattern = /^\d{1,3}$/;  
    if (!isdCodePattern.test(value)) {
        callback('Invalid ISD code!');
    } else {
        callback();
    }
};


const validateEmailAddress = (rule, value, callback) => {
	const regForEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (value && value.match(regForEmail)) {
		callback()
	} else {
		callback('Please ensure the email address is in correct format!')
	}
}

const validatePassword = (rule, value, callback) => {
	const regForPass = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
	if (value && value.match(regForPass)) {
		callback()
	} else {
		callback('')
	}
}

const validateLettersAndSpaces = (rule, value, callback) => {
	const regForLS = /^[A-Za-z\s]*$/
	if (value && value.match(regForLS)) {
		callback()
	} else {
		callback('Please use only letters and spaces!')
	}
}

const passwordValidations = (password)=>{
	const validations = [
		{
		  text: "Minimum 6 characters",
		  result: password.length >= 6,
		},
		{
		  text: "Minimum One Uppercase Letter",
		  result: /[A-Z]/.test(password),
		},
		{
		  text: "Minimum One Number",
		  result: /[0-9]/.test(password),
		},
		{
		  text: "Minimum One Special Character",
		  result: /[!@#$%^&*]/.test(password),
		},
	  ];
	  return validations
}

export { capitalize, timeDiffString, debounce, textShortner, validateMobileNumber, validateEmailAddress, validatePassword, validateLettersAndSpaces,passwordValidations ,validateISDCode}
