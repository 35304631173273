import React, { useEffect, useState } from 'react'
import { Divider, Form, Select, Input, Space, notification, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import ReactCountryFlag from 'react-country-flag'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateProfile, getCountryList, updatePassword } from '../../Stores/UserSession/action'
import { capitalize, validateLettersAndSpaces, validatePassword } from '../../Services/UtilityService'
import AeroButton from '../../AeroComponents/Button'
import AeroInput from '../../AeroComponents/Input'
import AeroNotificationContent from '../../AeroComponents/Notification'
import MobileHeader from '../../AeroComponents/MobileHeader'
import styles from './profile.module.sass'

const Profile = props => {
	const { countryListData, getCountryList, profileData, updateProfile, profileUpdated, callProfile, updatePassword, passwordUpdated, handleHamburger } = props
	const [countryList, setCountryList] = useState(null)
	const [waitForApi, setWaitForApi] = useState(false)
	const [topDisabled, setTopDisabled] = useState(false)
	const [bottomDisabled, setBottomDisabled] = useState(false)
	const [profileDetails, setProfileDetails] = useState(profileData)
	const [loader, setLoader] = useState(false)

	const toggleTopDisabled = () => setTopDisabled(prevState => !prevState)
	const toggleBottomDisabled = () => setBottomDisabled(prevState => !prevState)

	const discardChanges = () => {
		if (topDisabled) toggleTopDisabled()
		if (bottomDisabled) toggleBottomDisabled()
	}

	useEffect(() => {
		setWaitForApi(true)
		getCountryList()
	}, [])

	useEffect(() => {
		if (countryListData && waitForApi) {
			setWaitForApi(false)
			const mappedData = countryListData.filter(item => item.active)
			setCountryList(mappedData)
		}
	}, [countryListData])

	useEffect(() => {
		if (profileUpdated && waitForApi) {
			setWaitForApi(false)
			notification.open({
				message: <AeroNotificationContent content='Changes Saved' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
			callProfile()
			setProfileDetails(profileUpdated)
			toggleTopDisabled()
		}
	}, [profileUpdated])

	useEffect(() => {
		if (passwordUpdated && waitForApi) {
			setWaitForApi(false)
			notification.open({
				message: <AeroNotificationContent content='Changes Saved' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
			callProfile()
			toggleBottomDisabled()
		}
	}, [passwordUpdated])

	const recordValues = values => {
		const { firstName, lastName, countryId, oldPassword, newPassword, confirmPassword } = values
		if (topDisabled) {
			const body = {
				firstName,
				lastName,
				countryId,
			}
			setWaitForApi(true)
			setLoader(true)
			updateProfile(body).finally(() => setLoader(false))
		}
		if (bottomDisabled) {
			const body = {
				oldPassword,
				newPassword,
				confirmPassword,
			}
			setWaitForApi(true)
			updatePassword(body)
		}
	}

	return (
		<Spin spinning={loader}>
			<div className={styles['profile-wrapper']}>
				<MobileHeader header='Profile' handleHamburger={handleHamburger} />
				<div style={{ fontSize: '32px', fontWeight: '700' }}>{capitalize(profileDetails.first_name) + ' ' + capitalize(profileDetails.last_name)}</div>
				<div style={{ fontSize: '20px', color: '#47464A' }}>{profileDetails.email}</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
					<div style={{ fontSize: '18px', color: '#47464A', fontWeight: '700' }}>Basic Information</div>
					{topDisabled ? (
						<div></div>
					) : (
						<AeroButton onClick={toggleTopDisabled} type='link'>
							<EditOutlined style={{ fontSize: '18px' }} />{' '}
						</AeroButton>
					)}
				</div>
				<Divider style={{ position: 'relative', bottom: '20px' }}></Divider>
				<Form onFinish={recordValues} layout='vertical'>
					<div className={styles['profile-name-wrapper']}>
						<Form.Item
							label='First Name'
							rules={[{ required: topDisabled, message: 'Please input first name!', whitespace: true }, { validator: validateLettersAndSpaces }]}
							initialValue={profileDetails.first_name}
							name='firstName'
						>
							<AeroInput disabled={!topDisabled} className={styles['name-input']} />
						</Form.Item>
						<Form.Item
							label='Last Name'
							rules={[{ required: topDisabled, message: 'Please input last name!', whitespace: true }, { validator: validateLettersAndSpaces }]}
							initialValue={profileDetails.last_name}
							name='lastName'
						>
							<AeroInput disabled={!topDisabled} className={styles['name-input']} />
						</Form.Item>
					</div>

					<Form.Item
						style={{ marginTop: '20px' }}
						rules={[{ required: topDisabled, message: 'Please select your country!' }]}
						initialValue={profileDetails.country_id}
						name='countryId'
						label='Country of Residence'
					>
						<Select
							className={styles['country-input']}
							disabled={!topDisabled || (profileDetails.consumer_profile_id !== 0 && profileDetails.pilot_profile_id !== 0)}
							size='large'
							showSearch
							placeholder='Type to search'
							optionFilterProp='children'
							filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}
						>
							{countryList?.map(item => (
								<Select.Option key={item.name} value={item.id}>
									<ReactCountryFlag countryCode={item.code} /> <span style={{ marginLeft: '5px' }}>{item.name}</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
						<div style={{ fontSize: '18px', color: '#47464A', fontWeight: '700' }}>Security</div>
						{bottomDisabled ? (
							<div></div>
						) : (
							<AeroButton onClick={toggleBottomDisabled} type='link'>
								<EditOutlined style={{ fontSize: '18px' }} />{' '}
							</AeroButton>
						)}
					</div>
					<Divider style={{ position: 'relative', bottom: '20px' }}></Divider>
					{!bottomDisabled ? (
						<Form.Item label='Password'>
							<Input.Password visibilityToggle={false} disabled={true} size='large' style={{ borderRadius: '6px' }} defaultValue='random' placeholder='Type here' />
						</Form.Item>
					) : (
						<>
							<Form.Item
								label='Current Password'
								name='oldPassword'
								rules={[{ required: bottomDisabled, message: 'Please input current password!', whitespace: true }]}
							>
								<Input.Password size='large' style={{ borderRadius: '6px' }} placeholder='Type here' />
							</Form.Item>
							<Form.Item
								label='New Password'
								name='newPassword'
								rules={[{ required: bottomDisabled, message: 'Please input new password!', whitespace: true }, { validator: validatePassword }]}
							>
								<Input.Password size='large' style={{ borderRadius: '6px' }} placeholder='Type here' />
							</Form.Item>
							<Form.Item
								label='Confirm Password'
								name='confirmPassword'
								rules={[
									{ required: bottomDisabled, message: 'Please input new password again!', whitespace: true },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('newPassword') === value) {
												return Promise.resolve()
											}
											return Promise.reject(new Error('The two passwords that you entered do not match!'))
										},
									}),
								]}
							>
								<Input.Password size='large' style={{ borderRadius: '6px' }} placeholder='Type here' />
							</Form.Item>
						</>
					)}

					<Space size='large' style={{ marginTop: '40px' }}>
						<AeroButton
							onClick={discardChanges}
							disabled={!topDisabled && !bottomDisabled}
							width='168px'
							otherstyles={{ color: '#00B3FF', border: '1px solid #00B3FF' }}
						>
							Discard Changes
						</AeroButton>
						<AeroButton htmlType='submit' disabled={!topDisabled && !bottomDisabled} width='168px' type='primary'>
							Save Changes
						</AeroButton>
					</Space>
				</Form>
			</div>
		</Spin>
	)
}

const mapStateToProps = state => {
	return {
		profileData: state.UserSessionReducer.profileData,
		profileUpdated: state.UserSessionReducer.profileUpdated,
		countryListData: state.UserSessionReducer.countryListData,
		passwordUpdated: state.UserSessionReducer.passwordUpdated,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: bindActionCreators(updateProfile, dispatch),
		getCountryList: bindActionCreators(getCountryList, dispatch),
		updatePassword: bindActionCreators(updatePassword, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Profile)
