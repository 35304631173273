export const BTN_LOG_OUT = 'Log Out'

export const SIDE_MENU_LIST = [
	{
		key: 'MY_MAPS',
		icon: 'MY_MAPS_ICON',
		pathName: '/my-maps',
		displayValue: 'My Maps',
	},
	{
		key: 'SUBSCRIPTION',
		icon: 'SUBSCRIPTION_ICON',
		pathName: '/subscription',
		displayValue: 'Subscription',
	},
	{
		key: 'PROFILE',
		icon: 'PROFILE_ICON',
		pathName: '/profile',
		displayValue: 'Profile',
	},
	{
		key: 'HELP',
		icon: 'HELP_ICON',
		pathName: '/help',
		displayValue: 'Help',
	},
]
