import React from 'react'
import styles from './text-switch.module.sass'

const AeroTextSwitch = props => {
	const { options, currentSelection, handleSwitchChange } = props
	return (
		<div className={styles['aero-switch-wrapper']}>
			{options.map((item, index) => (
				<div onClick={() => handleSwitchChange(item)} key={index} className={currentSelection === item ? styles['active-switch-btn'] : styles['passive-switch-btn']}>
					<span className={currentSelection === item ? styles['active-switch-txt'] : styles['passive-switch-txt']}>{item}</span>
				</div>
			))}
		</div>
	)
}

export default AeroTextSwitch
