import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class GTMTracker extends Component {
	componentDidMount() {
		this.handleRouteChange()
		// Listen for future route changes
		this.unlisten = this.props.history.listen(() => {
			this.handleRouteChange()
		})
	}

	componentWillUnmount() {
		// Stop listening for route changes when the component unmounts
		if (this.unlisten) {
			this.unlisten()
		}
	}

	handleRouteChange() {
		// Function to trigger GTM
		const triggerGtm = () => {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'pageview',
				page: window.location.pathname + window.location.search,
			})
		}
		triggerGtm()
	}

	render() {
		return this.props.children
	}
}

export default withRouter(GTMTracker)
