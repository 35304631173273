import React from 'react'
import AeroButton from '../Button'
import CommonModalContent from './common-modal-content'

const GeneralNotificationContent = props => {
	const { btnAction, btnText } = props
	return (
		<div style={{ textAlign: 'center' }}>
			<CommonModalContent {...props} />
			<div style={{ margin: '15px' }}>
				<AeroButton onClick={btnAction} height='50px' block type='primary'>
					{btnText}
				</AeroButton>
			</div>
		</div>
	)
}

export default GeneralNotificationContent
