export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS'
export const SEND_CARD_TOKEN = 'SEND_CARD_TOKEN'
export const GET_ACTIVE_PLAN_DETAILS = 'GET_ACTIVE_PLAN_DETAILS'
export const GET_PAYMENT_TRANSACTIONS = 'GET_PAYMENT_TRANSACTIONS'
export const GET_GIGAPIXEL_TRANSACTIONS = 'GET_GIGAPIXEL_TRANSACTIONS'
export const CANCEL_PLAN = 'CANCEL_PLAN'
export const VALIDATE_DISCOUNT_CODE = 'VALIDATE_DISCOUNT_CODE'
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT'
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT'
export const SEND_CARD_TOKEN_FOR_FREE_TRIAL = 'SEND_CARD_TOKEN_FOR_FREE_TRIAL'
