import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { combineReducers } from 'redux'

// import reducers.
import UserSessionReducer from '../src/Stores/UserSession/reducer'
import SubscriptionReducer from '../src/Stores/Subscription/reducer'
import MyMapsReducer from '../src/Stores/MyMaps/reducer'

const enhancers = []
const middleware = [thunk, promiseMiddleware]

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension())
	}
}

const reducers = combineReducers({
	UserSessionReducer,
	SubscriptionReducer,
	MyMapsReducer,
})

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers,
)

const AppStore = createStore(reducers, composedEnhancers)

export default AppStore
