const setValueToStorage = (key, value) => {
	window.localStorage.setItem(key, value)
}

const removeValueFromStorage = key => {
	window.localStorage.removeItem(key)
}

const getValueFromStorage = key => {
	return window.localStorage.getItem(key)
}

const clearToken = () => {
	window.localStorage.removeItem('TOKEN')
}

const clearProfileType = () => {
	window.localStorage.removeItem('PROFILE_TYPE')
}

const clearLocalStore = () => {
	window.localStorage.clear()
}

const initializeAuth = () => getValueFromStorage('TOKEN') !== null

export { getValueFromStorage, setValueToStorage, clearToken, clearProfileType, clearLocalStore, initializeAuth, removeValueFromStorage }
