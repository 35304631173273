/*eslint-disable*/
import React, { Component } from 'react'
import MatImage from '../Image'
import { IMAGES_SRC } from '../Image/image.constants'

class ServerDown extends Component {
	render() {
		return (
			<div style={{ textAlign: 'center' }}>
				<div style={{ fontSize: '33px', fontWeight: '300', color: '#373A3C', marginTop: '50px', marginBottom: '20px' }}>System Is Under Maintenance</div>
				<MatImage src={IMAGES_SRC.SERVER_DOWN_ICON} />
				<div style={{ fontSize: '16px', marginTop: '30px' }}>Please Re-login after a few minutes</div>
			</div>
		)
	}
}

export default ServerDown
