import React from 'react'
import styles from '../subscription.module.sass'
import moment from 'moment'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { capitalize } from '../../../Services/UtilityService'

const PlanCancelAlert = ({ isDowngrade, profilePlan }) => {
	return (
		<div className={styles['warning-banner-wrapper']}>
			<div style={{ display: 'flex' }}>
				<ExclamationCircleFilled style={{ fontSize: '30px', marginBottom: '20px' }} />
				<div style={{ marginLeft: '20px' }}>
					<div>
						<strong>{isDowngrade ? 'Plan Updates' : 'You’ve cancelled your subscription'} </strong>
					</div>
					<div style={{ fontSize: '14px', marginTop: '10px' }}>
						{isDowngrade
							? `You’ve downgraded your plan from ${capitalize(profilePlan?.type)} plan to ${capitalize(profilePlan?.pendingPlan?.type)} plan. Your ${capitalize(
									profilePlan?.pendingPlan?.type,
							  )} plan will be effective from ${moment
									.utc(profilePlan?.endDate)
									.local()
									.format('DD/MM/YYYY')}.`
							: 'Once your current plan concludes, you will no longer be able to process images or utilize your remaining Gigapixel balance.'}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlanCancelAlert
