import React, { useEffect } from 'react'
import './styles.sass'

const Tests = () => {
	useEffect(() => {
		const data = [{ name: 'Prashant', age: 29 }, { name: 'Shalini', age: 50 }, { name: 'Shashank', age: 40 }, { name: 'Dilip', age: 15 }]
		const output = data.reduce((acc, curr) => {
			if (curr.age > 30) {
				acc.push(curr.name)
			}
			return acc
		}, [])

		console.log('kk ', output)
	}, [])
	return (
		<>
			<div className='orbit'>
				<div className='planet'></div>
			</div>
			{/* <iframe src='https://web-maps.aerologixmaps.com/app/processing/share/a6ZsaDvL0e8ARTrr0oEcsqIXMGcFhuSbJB-_R1BvwEpDbjkSLYKOQV8oojuJhC_5vtUulbglRDAmAbd1wv8lVT6JQrGR3yhHMQuJ79Tt-4eeb7uW4J6p1AELhhAzNSBBwZLxSsBikbJeVM1H5l0_vooIjzKFZNFRBG0KpZ0TZwA'></iframe> */}
			<div className='rotation'></div>
			<div className='loader'>
				<div className='dot'></div>
				<div className='dot'></div>
				<div className='dot'></div>
				<div className='dot'></div>
			</div>
		</>
	)
}

export default Tests
