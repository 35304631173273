import React from 'react'
import { Popover, Tooltip } from 'antd'
import { DownloadOutlined, CloudUploadOutlined, MoreOutlined } from '@ant-design/icons'
import moment from 'moment'
import styles from '../mymaps.module.sass'
import AeroImage from '../../../AeroComponents/Image'
import AeroButton from '../../../AeroComponents/Button'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { capitalize, textShortner } from '../../../Services/UtilityService'
import { goTo } from '../../../Services/NavigationService'
import { PROCESSING_STATUS_ENUMS } from '../constants'
import { pushEventToMixpanel } from '../../../Services/MixpanelService'
import MapThumbnail from '../../../AeroComponents/GoogleMap/map-thumbnail'

const MapCard = props => {
	const { demo, data, openShareModal, openDeleteModal, openProcessedDataDownload } = props

	const openProcessedMap = () => {
		pushEventToMixpanel('Map Viewed', {
			'Map Name': data.processingName,
		})
		window.open(data.processedData, '_blank')
	}

	const path = JSON.parse(data.boundaryCoordinates)
	const polyPath = [
		{ lat: path?.coord_0.latitude, lng: path?.coord_0.longitude },
		{ lat: path?.coord_1.latitude, lng: path?.coord_1.longitude },
		{ lat: path?.coord_2.latitude, lng: path?.coord_2.longitude },
		{ lat: path?.coord_3.latitude, lng: path?.coord_3.longitude },
	]

	const centerLat = (path?.coord_0.latitude + path?.coord_1.latitude + path?.coord_2.latitude + path?.coord_3.latitude) / 4
	const centerLon = (path?.coord_0.longitude + path?.coord_1.longitude + path?.coord_2.longitude + path?.coord_3.longitude) / 4

	return (
		<div style={{ marginTop: demo ? null : '32px' }}>
			{demo ? <div className={styles['demo-wrapper']}>Demo</div> : null}

			<div className={styles['card-wrapper']}>
				{data.status !== 'ENDED' ? (
					<AeroImage otherstyles={{ borderRadius: '7px 7px 0px 0px', width: 'inherit' }} src={IMAGES_SRC.MAP_PLACEHOLDER} alt='map-asset' />
				) : (
					<MapThumbnail center={{ lat: centerLat, lng: centerLon }} polyPath={polyPath} />
				)}

				<div style={{ marginLeft: '13px' }}>
					<div className={styles['two-ends-items']}>
						<div className={styles['card-head']}>
							{data.processingName.length < 15 ? (
								capitalize(data.processingName)
							) : (
								<Tooltip title={capitalize(data.processingName)}>{textShortner(capitalize(data.processingName), 15)}</Tooltip>
							)}
						</div>
						<AeroButton onClick={() => openProcessedMap()} disabled={!data.processedData} type='link'>
							<AeroImage alt='ar-right' src={IMAGES_SRC.ARROW_RIGHT} />
						</AeroButton>
					</div>
					<div className={styles['date-wrapper']}>
						{moment
							.utc(data.createdAt)
							.local()
							.format('DD/MM/YYYY')}
					</div>
					<div className={styles['status-wrapper']}>
						<span style={{ marginLeft: '10px' }}>Processing {PROCESSING_STATUS_ENUMS[data.status]}</span>
					</div>
				</div>
				<div className={styles['footer-wrapper']}>
					<div style={{ position: 'relative', bottom: '7px' }}>
						<AeroButton
							onClick={() => openProcessedDataDownload(data.processingId)}
							disabled={data.status !== 'ENDED'}
							otherstyles={{ fontSize: '20px', color: '#00B3FF' }}
							type='link'
						>
							<DownloadOutlined />
						</AeroButton>
						<AeroButton
							onClick={() => goTo(`edit-map/${data.processingId}`)}
							disabled={data.stage !== 1}
							otherstyles={{ fontSize: '20px', color: '#00B3FF' }}
							type='link'
						>
							<CloudUploadOutlined />
						</AeroButton>
					</div>
					<Popover
						content={
							<div>
								<div>
									<AeroButton
										onClick={() => goTo(`edit-map/${data.processingId}`)}
										disabled={data.status !== 'CREATED'}
										otherstyles={{ textAlign: 'left' }}
										block
										type='text'
									>
										<AeroImage className={styles['action-btn-asset']} alt='edit' src={IMAGES_SRC.EDIT_ICON} /> Edit
									</AeroButton>
								</div>
								<div>
									<AeroButton disabled={data.status !== 'ENDED'} onClick={() => openShareModal(data)} otherstyles={{ textAlign: 'left' }} block type='text'>
										<AeroImage className={styles['action-btn-asset']} alt='share' src={IMAGES_SRC.SHARE_ICON} /> Share
									</AeroButton>
								</div>
								<div>
									<AeroButton disabled={demo} onClick={() => openDeleteModal(data)} otherstyles={{ textAlign: 'left' }} block type='text'>
										<AeroImage className={styles['action-btn-asset']} alt='delete' src={IMAGES_SRC.TRASH_ICON} /> Delete
									</AeroButton>
								</div>
							</div>
						}
					>
						<AeroButton otherstyles={{ fontSize: '20px', position: 'relative', bottom: '7px', color: 'black' }} type='link'>
							<MoreOutlined />
						</AeroButton>
					</Popover>
				</div>
			</div>
		</div>
	)
}

export default MapCard
