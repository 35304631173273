import React from 'react'
import { PaperClipOutlined } from '@ant-design/icons'
import AeroButton from '../../../AeroComponents/Button'
import { Upload, message } from 'antd'

const ExtraUploader = props => {
	const { label, fetchedValue, callDeleteFile, callUploadApi, processingId } = props

	const handleFileUpload = file => {
		const data = new FormData()
		data.append('file', file)
		data.append('processingId', processingId)
		callUploadApi(data)
	}

	const customRequest = ({ onSuccess, onError, file }, index) => {
		handleFileUpload(file, index)
	}

	const handleChange = info => {
		if (info.file.status === 'uploading') {
			return
		}
		if (info.file.status === 'done') {
			handleFileUpload(info.file.originFileObj)
		}
	}

	const beforeUpload = file => {
		const isTxt = file.type === 'text/plain'
		if (!isTxt) {
			message.error('You can only upload .txt file!')
		}
		const isLt2M = file.size / 1024 / 1024 <= 10
		if (!isLt2M) {
			message.error('File must not be more than 10 MB!')
		}
		return isLt2M && isTxt
	}

	return (
		<>
			<div>{label}</div>
			<div style={{ border: '1px solid #ABA8B0', borderRadius: '8px', marginTop: '10px' }}>
				{fetchedValue && fetchedValue !== 'DELETED' ? (
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<AeroButton onClick={() => window.open(fetchedValue, '_blank')} otherstyles={{ color: '#ABA8B0' }} type='text'>
							<PaperClipOutlined style={{ fontSize: '20px', color: '#ABA8B0', marginLeft: '15px', position: 'relative', bottom: '4px' }} />
							Click to download file
						</AeroButton>
						<AeroButton onClick={callDeleteFile} type='text' otherstyles={{ color: '#ABA8B0' }}>
							X
						</AeroButton>
					</div>
				) : (
					<Upload showUploadList={false} customRequest={customRequest} onChange={handleChange} beforeUpload={beforeUpload}>
						<AeroButton otherstyles={{ color: '#ABA8B0' }} type='text'>
							<PaperClipOutlined style={{ fontSize: '20px', color: '#ABA8B0', marginLeft: '15px', position: 'relative', bottom: '4px' }} />
							Click here to upload file (.txt)
						</AeroButton>
					</Upload>
				)}
			</div>
		</>
	)
}

export default ExtraUploader
