export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const GET_SOCIAL_AUTH = 'GET_SOCIAL_AUTH'
export const REPORT_ERROR = 'REPORT_ERROR'
export const APP_LOADER = 'APP_LOADER'
export const REGISTER_USER = 'REGISTER_USER'
export const LOGIN = 'LOGIN'
export const LOG_OUT = 'LOG_OUT'
export const CONTACT_SUPPORT = 'CONTACT_SUPPORT'
export const GET_PROFILE = 'GET_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SEND_EMAIL_OTP = 'SEND_EMAIL_OTP'
export const VALIDATE_EMAIL_OTP = 'VALIDATE_EMAIL_OTP'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const GET_OTP = 'GET_OTP'
export const RESEND_VERIFICATION_LINK = 'RESEND_VERIFICATION_LINK'
