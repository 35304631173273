import React from 'react'
import { Input, Space } from 'antd'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import AeroImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import AeroButton from '../../../AeroComponents/Button'
import styles from '../mymaps.module.sass'
import { capitalize, debounce } from '../../../Services/UtilityService'
import { goTo } from '../../../Services/NavigationService'

const MyMapsHeader = props => {
	const { name, toggleNewMapModal, searchMaps, searchRef, profileData } = props
	return (
		<div className={styles['header-wrapper']}>
			<div style={{ position: 'relative', bottom: '15px' }}>
				<div style={{ fontSize: '22px', fontWeight: 'bold' }}>Hey, {capitalize(name)}</div>
				<div style={{ fontSize: '16px', marginTop: '5px', color: '#84818A' }}>Welcome to AerologixMaps</div>
				<div style={{ fontSize: '16px', marginTop: '5px', color: '#00B3FF' }}>
					<Space size='large'>
						<a target='_blank' rel='noopener noreferrer' href='https://aerologix.app.box.com/s/q6egsbxyn3gjlib2zjit87ki0ned1qq0'>
							<DownloadOutlined /> Sample Dataset
						</a>
						<a target='_blank' rel='noopener noreferrer' href='https://drive.google.com/file/d/13oJ3t9qVGJI75iEO9_Y5zuuo-mA9HUDK/view?usp=sharing'>
							<DownloadOutlined /> Download Guide
						</a>
					</Space>
				</div>
			</div>
			{profileData?.free_trial_availed ? (
				<div>
					<Input
						onChange={debounce(() => searchMaps(), 500)}
						ref={searchRef}
						size='large'
						className={styles['header-search']}
						placeholder='Search by Processing Name'
						suffix={<SearchOutlined style={{ color: '#00000040' }} />}
					/>

					<AeroButton className={styles['new-map-btn']} onClick={toggleNewMapModal} type='primary'>
						<AeroImage otherstyles={{ position: 'relative', bottom: '2px', right: '8px' }} alt='add' src={IMAGES_SRC.ADD_SQUARE} /> New Map
					</AeroButton>
				</div>
			) : (
				<AeroButton onClick={() => goTo('subscription')} className={styles['header-free-trial-btn']}>
					Explore Plans{' '}
				</AeroButton>
			)}
		</div>
	)
}

export default MyMapsHeader
