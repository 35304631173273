import React, { useEffect, useState } from 'react'
import { Tabs, Skeleton, Divider, List, Descriptions } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { getPaymentTransactions, getGigapixelTransactions } from '../../../Stores/Subscription/action'
import SubscriptionDetailsTopSection from './subscription-details-top-section'
import PlanCancelAlert from '../alerts/plan-cancel-alert'
import GracePeriodAlert from '../alerts/grace-period-alert'
import InfiniteScroll from 'react-infinite-scroll-component'
import { capitalize } from '../../../Services/UtilityService'

const SubscriptionDetails = props => {
	const {
		data,
		getPaymentTransactions,
		getGigapixelTransactions,
		paymentsData,
		gigapixelsData,
		goToBuyMode,
		handlePlanCancel,
		isCancelled,
		inGrace,
		paymentsTransaction,
		isDowngrade,
		profilePlan,
	} = props
	const [waitForApi, setWaitForApi] = useState(false)
	const [paymentsDetails, setPaymentsDetails] = useState(null)
	const [paymentsCount, setPaymentsCount] = useState(0)
	const [gigapixelsDetails, setGigapixelsDetails] = useState(null)
	const [gigapixelsCount, setGigapixelsCount] = useState(0)
	const [currentPaymentsPage, setCurrentPaymentsPage] = useState(1)
	const [currentGigapixelsPage, setCurrentGigapixelsPage] = useState(1)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (paymentsTransaction) {
			// callPaymentsTransaction(false)
			setWaitForApi(true)
			getPaymentTransactions({ pageNumber: currentPaymentsPage, pageSize: 10 })
		}
	}, [paymentsTransaction])

	useEffect(() => {
		if (paymentsData && waitForApi) {
			setWaitForApi(false)
			setLoading(false)
			const prevValue = paymentsDetails ? paymentsDetails : []

			if (prevValue.length && currentPaymentsPage === 1) {
				setPaymentsDetails(paymentsData.data)
				setPaymentsCount(paymentsData.count)
			} else {
				setPaymentsDetails([...prevValue, ...paymentsData.data])
				setPaymentsCount(paymentsData.count)
			}
		}
	}, [paymentsData])

	useEffect(() => {
		if (gigapixelsData && waitForApi) {
			setWaitForApi(false)
			setLoading(false)
			const prevValue = gigapixelsDetails ? gigapixelsDetails : []

			if (prevValue.length && currentGigapixelsPage === 1) {
				setGigapixelsDetails(gigapixelsData.data)
				setGigapixelsCount(gigapixelsData.count)
			} else {
				setGigapixelsDetails([...prevValue, ...gigapixelsData.data])
				setGigapixelsCount(gigapixelsData.count)
			}
		}
	}, [gigapixelsData])

	const handleTabChange = key => {
		setWaitForApi(true)
		if (key === '1') {
			getPaymentTransactions({ pageNumber: currentPaymentsPage, pageSize: 10 })
		} else {
			getGigapixelTransactions({ pageNumber: currentGigapixelsPage, pageSize: 10 })
		}
	}
	const loadMoreData = type => {
		if (loading) return
		setLoading(true)
		if (type === 'pay') {
			setCurrentPaymentsPage(prevState => prevState + 1)
			setWaitForApi(true)
			getPaymentTransactions({ pageNumber: currentPaymentsPage, pageSize: 10 })
		} else {
			setCurrentGigapixelsPage(prevState => prevState + 1)
			setWaitForApi(true)
			getGigapixelTransactions({ pageNumber: currentGigapixelsPage, pageSize: 10 })
		}
	}

	return (
		<div className='module-container'>
			{isCancelled ? <PlanCancelAlert isDowngrade={isDowngrade} profilePlan={profilePlan} /> : null}
			{inGrace ? <GracePeriodAlert cancellationDate={data.cancellationDate} gracePeriodEndDate={data.gracePeriodEndDate} goToBuyMode={goToBuyMode} /> : null}

			<div className='module-title'>Subscription Details</div>
			<SubscriptionDetailsTopSection
				isDowngrade={isDowngrade}
				inGrace={inGrace}
				isCancelled={isCancelled}
				data={data}
				goToBuyMode={goToBuyMode}
				handlePlanCancel={handlePlanCancel}
			/>
			<div style={{ marginTop: '40px' }} className='module-title'>
				Transactions
				<div style={{ marginTop: '30px', marginRight: '30px' }}>
					<Tabs onChange={handleTabChange} defaultActiveKey='1'>
						<Tabs.TabPane tab='Payments' key='1'>
							<div id='paymentsDiv' style={{ overflow: 'auto', height: '75vh' }}>
								{paymentsDetails && paymentsDetails.length ? (
									<InfiniteScroll
										dataLength={paymentsDetails.length}
										next={() => loadMoreData('pay')}
										hasMore={paymentsDetails.length < paymentsCount}
										loader={
											<Skeleton
												style={{ margin: '20px' }}
												paragraph={{
													rows: 3,
												}}
												active
											/>
										}
										endMessage={<Divider></Divider>}
										scrollableTarget={'paymentsDiv'}
									>
										<List
											size='large'
											dataSource={paymentsDetails}
											renderItem={item => (
												<List.Item>
													<Descriptions labelStyle={{ color: '#84818A' }}>
														<Descriptions.Item>
															<strong>
																{item.type === 'MAP'
																	? `Map - [${item.processName}]`
																	: item.type === 'PLAN'
																	? item.additionalDetails === 'FREE_TRIAL'
																		? 'Plan - (Free Trial)'
																		: `Plan - (${capitalize(item.additionalDetails)})`
																	: item.type === 'ADDITIONAL_GP'
																	? 'Additional Gigapixels'
																	: capitalize(item.type)}
															</strong>
														</Descriptions.Item>
														<Descriptions.Item label='Map'>{item.processName ? item.processName : '--'}</Descriptions.Item>
														<Descriptions.Item label='Date'>
															{moment
																.utc(item.transactionDate)
																.local()
																.format('DD MMMM YYYY')}
														</Descriptions.Item>
														<Descriptions.Item label='Amount'>
															<strong>{`USD ${item.amount}`}</strong>
														</Descriptions.Item>
													</Descriptions>
												</List.Item>
											)}
										/>
									</InfiniteScroll>
								) : null}
							</div>
						</Tabs.TabPane>
						<Tabs.TabPane tab='Gigapixels' key='2'>
							<div id='gigsDiv' style={{ overflow: 'auto', height: '75vh' }}>
								{gigapixelsDetails && gigapixelsDetails.length ? (
									<InfiniteScroll
										dataLength={gigapixelsDetails.length}
										next={() => loadMoreData('giga')}
										hasMore={gigapixelsDetails.length < gigapixelsCount}
										loader={
											<Skeleton
												style={{ margin: '20px' }}
												paragraph={{
													rows: 3,
												}}
												active
											/>
										}
										endMessage={<Divider></Divider>}
										scrollableTarget={'gigsDiv'}
									>
										<List
											size='large'
											dataSource={gigapixelsDetails}
											renderItem={item => (
												<List.Item>
													<Descriptions labelStyle={{ color: '#84818A' }}>
														<Descriptions.Item>
															<strong>{capitalize(item.operation)}</strong>
														</Descriptions.Item>
														<Descriptions.Item label='Type'>
															{item.type === 'MAP'
																? `Map - [${item.processName}]`
																: item.type === 'PLAN'
																? item.additionalDetails === 'FREE_TRIAL'
																	? 'Plan - [Free Trial]'
																	: `Plan - [${capitalize(item.additionalDetails)}]`
																: item.type === 'ADDITIONAL_GP'
																? 'Additional Gigapixels'
																: capitalize(item.type)}
														</Descriptions.Item>
														<Descriptions.Item label='Date'>
															{moment
																.utc(item.transactionDate)
																.local()
																.format('DD MMMM YYYY')}
														</Descriptions.Item>
														<Descriptions.Item label='Number of Gigapixels'>
															<strong>{item.gigapixelsUsed}</strong>
														</Descriptions.Item>
													</Descriptions>
												</List.Item>
											)}
										/>
									</InfiniteScroll>
								) : null}
							</div>
						</Tabs.TabPane>
					</Tabs>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		paymentsData: state.SubscriptionReducer.paymentsData,
		gigapixelsData: state.SubscriptionReducer.gigapixelsData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getPaymentTransactions: bindActionCreators(getPaymentTransactions, dispatch),
		getGigapixelTransactions: bindActionCreators(getGigapixelTransactions, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SubscriptionDetails)
