// react & redux imports
import React, { Component } from 'react'
import { Spin } from 'antd'

let spinContainerStyle = {
    display: 'flex',
    height: '100%', width: '100%',
    justifyContent: 'center', alignItems: 'center',
    zIndex: '999', backgroundColor: 'rgba(0, 0, 0, 0.2)',
    top: '24px'
}
const modalStyle = {
    height: '100%',
    width: '100%',
    margin: '-24px',
    borderRadius: '4px',
}

class ScreenLoader extends Component {
    render() {
        let style = (this.props.isModal) ?
            { ...spinContainerStyle, ...modalStyle, position: 'absolute' } :
            { ...spinContainerStyle, position: 'fixed' };
        return (
            <div style={style}>
                <Spin size="large" />
            </div>
        )
    }
}

export default ScreenLoader;
