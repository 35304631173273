import React from 'react'

export const FAQ_HEADS = [
	{
		key: 1,
		headerText: 'General Questions',
		panelItems: [
			{
				key: 'a',
				text: 'What is AerologixMaps?',
				cta: '',
			},
			{
				key: 'b',
				text: 'How does AerologixMaps differentiate itself from applications like PIX4Dcloud and DroneDeploy?',
				cta: '',
			},
			{
				key: 'c',
				text: 'In which industries can AerologixMaps be applied?',
				cta: '',
			},
			{
				key: 'd',
				text: 'What functionalities does AerologixMaps offer for aerial imagery processing?',
				cta: '',
			},
			{
				key: 'e',
				text: 'In which languages is AerologixMaps available?',
				cta: '',
			},
		],
	},
	{
		key: 2,
		headerText: 'License / Subscription',
		panelItems: [
			{
				key: 'f',
				text: 'How can I try AerologixMaps for free?',
				cta: '',
			},
			{
				key: 'g',
				text: 'How can I purchase a subscription for AerologixMaps?',
				cta: '',
			},
			{
				key: 'h',
				text: 'How can I switch between different subscription plans?',
				cta: '',
			},
			{
				key: 'i',
				text: 'How do I cancel my AerologixMaps subscription?',
				cta: '',
			},
			{
				key: 'j',
				text: 'When will my account be billed?',
				cta: '',
			},
			{
				key: 'k',
				text: 'What is a Gigapixel and how is it calculated?',
				cta: '',
			},
			{
				key: 'l',
				text: 'What if I exhaust my Gigapixels during the subscription period?',
				cta: '',
			},
			{
				key: 'm',
				text: 'What happens to my unused Gigapixels when my plan expires?',
				cta: '',
			},
		],
	},
	{
		key: 3,
		headerText: 'Inputs and Outputs',
		panelItems: [
			{
				key: 'n',
				text: 'What types of inputs and outputs are supported by AerologixMaps?',
				cta: '',
			},
			{
				key: 'o',
				text: 'What is the maximum number of images that can be processed with AerologixMaps?',
				cta: '',
			},
			{
				key: 'p',
				text: 'Is it possible to download a map that was processed on AerologixMaps?',
				cta: '',
			},
		],
	},
	{
		key: 4,
		headerText: 'Functionalities',
		panelItems: [
			{
				key: 'q',
				text: 'How do I process images on AerologixMaps?',
				cta: '',
			},
			{
				key: 'r',
				text: 'What processing options are available on AerologixMaps?',
				cta: '',
			},
			{
				key: 's',
				text: 'Can the results from AerologixMaps be shared?',
				cta: '',
			},
			{
				key: 't',
				text: 'Can Ground Control Points (GCPs) be used on AerologixMaps?',
				cta: '',
			},
			{
				key: 'u',
				text: 'Can I create 3D maps using AerologixMaps?',
				cta: '',
			},
		],
	},
	{
		key: 5,
		headerText: 'Data Management',
		panelItems: [
			{
				key: 'v',
				text: 'How is my data protected on AerologixMaps?',
				cta: '',
			},
			{
				key: 'w',
				text: 'What happens to my AerologixMaps projects when the subscription expires?',
				cta: '',
			},
		],
	},
	{
		key: 6,
		headerText: 'Technical Support and Feedback',
		panelItems: [
			{
				key: 'x',
				text: 'How can I submit product feedback and feature requests for AerologixMaps?',
				cta: '',
			},
			{
				key: 'y',
				text: 'How can I get technical support for AerologixMaps?',
				cta: '',
			},
		],
	},
]

export const DETAILS_HEADS = {
	1: 'General Questions',
	2: 'License / Subscription',
	3: 'Inputs and Outputs',
	4: 'Functionalities',
	5: 'Data Management',
	6: 'Technical Support and Feedback',
}

export const DETAILS_ITEMS = {
	1: [
		{
			key: 'a1',
			questionText: 'What is AerologixMaps?',
			answerText:
				'AerologixMaps is a cloud-based platform tailored for aerial imagery processing. It efficiently transforms drone captures into actionable data, producing maps like orthomosaics, digital terrain models, 3D models, and point clouds. With its user-friendly design, AerologixMaps provides rapid processing, cost-effectiveness, and flexibility to operate even on a laptop, making it an essential tool for deriving precise insights from drone images.',
		},
		{
			key: 'a2',
			questionText: 'How does AerologixMaps differentiate itself from applications like PIX4Dcloud and DroneDeploy?',
			answerText: `AerologixMaps differentiates itself from competitors like PIX4Dcloud and DroneDeploy by offering a cost-effective solution tailored to a broad spectrum of users. Whether it's an individual with a few projects each month or a large enterprise handling numerous maps regularly, AerologixMaps is designed to cater to all. Its flexibility, user-centric design, and affordability set it apart, ensuring that every user, regardless of scale or expertise, finds it an optimal choice for their aerial imagery processing needs.`,
		},
		{
			key: 'a3',
			questionText: 'In which industries can AerologixMaps be applied?',
			answerText:
				'AerologixMaps can be applied across a wide range of industries, including but not limited to construction, land surveying, real estate, mining, agriculture, forestry, emergency management, and inspection. Its versatile features cater to the unique needs of each sector.',
		},
		{
			key: 'a4',
			questionText: 'What functionalities does AerologixMaps offer for aerial imagery processing?',
			answerText:
				'AerologixMaps offers functionalities such as 3D mapping, orthophoto generation, digital elevation model creation, point cloud visualization, on-demand data processing, vegetation indices, temporal map analysis and much more. Users can also integrate Ground Control Points (GCPs) for increased accuracy and can share their projects with team members or clients seamlessly.',
		},
		{
			key: 'a5',
			questionText: 'In which languages is AerologixMaps available?',
			answerText: 'Currently, AerologixMaps is available in English. However, we are continuously working very hard to provide AerologixMaps in multiple languages soon.',
		},
	],
	2: [
		{
			key: 'b1',
			questionText: 'How can I try AerologixMaps for free?',
			answerText:
				'You can try AerologixMaps for free by signing up on our website. After registration, head over to the subscriptions page to initiate your free trial, giving you access to its features and capabilities without any commitment. This trial is available for 15 days, post which you can opt to upgrade to a paid plan.',
		},
		{
			key: 'b2',
			questionText: 'How can I purchase a subscription for AerologixMaps?',
			answerText: `To purchase a subscription for AerologixMaps, visit our website and navigate to the "Subscription" section. There, you'll find various subscription plans along with their details. Select the plan that best suits your needs, enter your credit card information, and complete the payment for the chosen plan duration. Once the payment is processed, you're all set to start utilizing AerologixMaps to its fullest potential.`,
		},
		{
			key: 'b3',
			questionText: 'How can I switch between different subscription plans?',
			answerText: (
				<>
					Switching between different subscription plans on AerologixMaps is straightforward:
					<p>
						<ol>
							<li>Log in to your AerologixMaps account.</li>
							<li>Navigate to the Subscription section on our homepage or dashboard.</li>
							<li>You'll see the list of available subscription plans: Free Trial, Lite, Pro, and Business.</li>
							<li>Choose the plan you want to switch to by clicking on it.</li>
							<li>
								Follow the on-screen prompts to finalize your choice. If you're upgrading, you'll be guided through the payment process. If downgrading, the changes
								will take effect at the start of your next billing cycle.
							</li>
							<li>Once completed, you'll receive a confirmation of the change to your subscription plan.</li>
						</ol>
					</p>
					<p>
						Remember, when you upgrade or downgrade, the changes will become effective based on your current subscription's terms. Always review the details and
						implications before making changes to your subscription.
					</p>
				</>
			),
		},
		{
			key: 'b4',
			questionText: 'How do I cancel my AerologixMaps subscription?',
			answerText: (
				<>
					To cancel your AerologixMaps subscription, follow these steps:
					<p>
						<ol>
							<li>Log in to your AerologixMaps account.</li>
							<li>Navigate to the Subscription section on our homepage or dashboard.</li>
							<li>Within this section, you'll see details of your current subscription plan.</li>
							<li>Locate and click on the option to Cancel your subscription.</li>
							<li>A prompt will appear asking for confirmation. Select "Confirm Cancellation".</li>
							<li>Once confirmed, you'll receive a confirmation message or email indicating that your subscription has been successfully cancelled.</li>
						</ol>
					</p>
				</>
			),
		},
		{
			key: 'b5',
			questionText: 'When will my account be billed?',
			answerText: (
				<>
					Your account will be billed based on the subscription plan you've chosen:
					<p>
						<ol>
							<li>
								<strong>Monthly Plans:</strong> If you've selected a monthly plan, your account will be billed every month on the same date as the day you initially
								subscribed. For instance, if you subscribed on the 10th of January, the subsequent billing would occur on the 10th of February, and so on.
							</li>
							<li>
								<strong>Annual Plans:</strong> If you've opted for an annual plan, your account will be billed once a year on the anniversary of the date you
								initiated the subscription.
							</li>
						</ol>
					</p>
					<p>
						Please note that for both monthly and annual plans, the billing process is automated, and the payment method linked to your AerologixMaps account will be
						charged. You will typically receive a notification or invoice via email confirming the billing details. If any payment issues arise, you'll be promptly
						informed to address the situation.
					</p>
				</>
			),
		},
		{
			key: 'b6',
			questionText: 'What is a Gigapixel and how is it calculated?',
			answerText: (
				<>
					A Gigapixel refers to one billion pixels and is a unit used to measure digital image resolution. In the context of AerologixMaps, it's a way to quantify the
					amount of processing a user can perform based on their subscription plan.
					<p>The calculation for Gigapixels is as follows:</p>
					<p>Gigapixels=(Megapixels (MP) of a single image×Number of images)÷1000</p>
					<p>For instance, if you have 100 images, each of 20 MP, the total would be:</p>
					<p>Gigapixels=(20×100)÷1000=2 Gigapixels</p>
					<p>
						With the purchase of every AerologixMaps plan, users receive a designated number of Gigapixels, determining the amount of image processing they can perform
						during their subscription period.
					</p>
				</>
			),
		},
		{
			key: 'b7',
			questionText: 'What if I exhaust my Gigapixels during the subscription period?',
			answerText: (
				<>
					If you use up all the Gigapixels included in your subscription plan before the period ends, here's what you can do:
					<p>
						<ol>
							<li>
								<strong>Purchase Additional Gigapixels:</strong> Depending on your current plan, you will have to pay for every extra Gigapixel used for processing
								at a set rate. For example, under the Lite plan, you might be charged $6.5 for each extra Gigapixel you require.
							</li>
							<li>
								<strong>Wait for Renewal:</strong> If you'd rather not buy more Gigapixels, you can simply wait until your next subscription renewal. Upon renewal,
								your Gigapixel allowance will be refreshed according to the terms of your plan.
							</li>
							<li>
								<strong>Upgrade Your Subscription:</strong> Think about upgrading to a more advanced subscription plan. These plans typically provide more
								Gigapixels every period and might include other enhanced features.
							</li>
							<li>
								<strong>Manage Your Usage:</strong> To prevent running low on Gigapixels too quickly, keep an eye on your consumption and be strategic about how you
								use the service. Prioritize essential tasks and be selective about the images you process.
							</li>
						</ol>
					</p>
					<p>
						Always check your remaining Gigapixel balance and the estimated Gigapixels required for a task before you begin. If you're close to exceeding your limit,
						the AerologixMaps platform will usually send alerts or notifications to let you know.
					</p>
				</>
			),
		},
		{
			key: 'b8',
			questionText: 'What happens to my unused Gigapixels when my plan expires?',
			answerText: (
				<>
					When your AerologixMaps subscription plan expires, the following happens to your Gigapixels (GPs):
					<p>
						<ol>
							<li>
								<strong>Gigapixel Freezing:</strong> Your unused Gigapixels will be frozen. This means you won't be able to process new maps with the unused GPs
								after the expiry of your plan.
							</li>
							<li>
								<strong>Reactivation of Gigapixels:</strong> If you decide to purchase a new subscription after expiry, the previously unused Gigapixels will become
								available again. These will be combined with any new Gigapixels you receive as part of your new subscription, allowing you to process maps with the
								cumulative total.
							</li>
						</ol>
					</p>
					<p>
						Always ensure to review the specific terms and conditions related to Gigapixels in your subscription agreement or consult the AerologixMaps support team for
						any clarifications.
					</p>
				</>
			),
		},
	],
	3: [
		{
			key: 'c1',
			questionText: 'What types of inputs and outputs are supported by AerologixMaps?',
			answerText: (
				<>
					AerologixMaps is equipped to handle a variety of inputs and produce diverse outputs suitable for drone image processing. Here's a detailed breakdown:
					<p>
						<strong>Supported Inputs:</strong>
					</p>
					<p>
						Drone Images: AerologixMaps accepts aerial photographs or image files captured using drones. You can easily upload these images to AerologixMaps for further
						processing.
					</p>
					<p>
						<strong>Supported Outputs:</strong>
						<div>After processing the uploaded drone images, AerologixMaps generates the following outputs:</div>
					</p>
					<p>
						<ol>
							<li>
								<strong>Orthomosaic:</strong>This is a high-definition, georeferenced aerial photo that seamlessly merges multiple drone-captured images. It
								provides an accurate depiction of the surveyed region.
							</li>
							<li>
								<strong>Digital Surface Model (DSM):</strong> A DSM offers a 3D visualization representing the Earth's surface elevation. It includes both natural
								topographical features and man-made structures.
							</li>
							<li>
								<strong>Digital Terrain Model (DTM):</strong> Unlike the DSM, a DTM provides a 3D view of the Earth's surface without any obstructions. It excludes
								vegetation and man-made constructions to show the raw terrain.
							</li>
							<li>
								<strong>Contour Lines:</strong> These are continuous lines connecting points of identical elevation. They visually portray the configuration and
								relief of the landscape.
							</li>
							<li>
								<strong>Point Cloud:</strong> A dense cluster of 3D data points that illustrate the surface of the surveyed area. This allows for intricate analysis
								and measurements.
							</li>
							<li>
								<strong>3D Model:</strong> A comprehensive three-dimensional depiction of the mapped area, facilitating examination from myriad perspectives.
							</li>
						</ol>
					</p>
					<p>
						For users in the agricultural and forestry sectors, AerologixMaps also produces Vegetation Indices, such as VARI and IFV. These indices shed light on
						vegetation health and vitality.
					</p>
					<p>
						In essence, with AerologixMaps, users can harness the power of their drone-captured images to derive actionable insights, make informed decisions, and
						integrate the outputs with other specialized software platforms.
					</p>
				</>
			),
		},
		{
			key: 'c2',
			questionText: 'What is the maximum number of images that can be processed with AerologixMaps?',
			answerText: (
				<>
					AerologixMaps offers different subscription tiers, each with its own limit on the number of images that can be processed:
					<p>
						<ul>
							<li>Free Trial: Up to 500 images per processing task.</li>
							<li>Lite Plan: Up to 750 images per processing task.</li>
							<li>Pro Plan: Up to 1,000 images per processing task.</li>
							<li>Business Plan: Up to 3,000 images per processing task.</li>
						</ul>
					</p>
					<p>
						Please note that these limits are set per individual processing task. If you have more images than the limit of your plan, consider upgrading to a
						higher-tier subscription to accommodate larger datasets.
					</p>
				</>
			),
		},
		{
			key: 'c3',
			questionText: 'Can I upload local results to AerologixMaps?',
			answerText:
				'Currently, AerologixMaps does not support the capability to upload local results. Users can only visualize and analyze the maps that have been generated using AerologixMaps.',
		},
		{
			key: 'c4',
			questionText: 'Is it possible to download a map that was processed on AerologixMaps?',
			answerText: `Yes, maps processed on AerologixMaps can be downloaded. After your project has been processed and the desired outputs, such as orthomosaic, DSM, DTM, point clouds, and processing reports have been generated, they are available for download directly from AerologixMaps. This feature ensures you can maintain a local copy of the data for deeper analysis or for integration with other software tools. To download, simply choose the specific outputs you're interested in. This provides the best of both worlds - the ease of online access and the versatility of having local copies of the data.`,
		},
	],
	4: [
		{
			key: 'd1',
			questionText: 'How do I process images on AerologixMaps?',
			answerText: (
				<>
					Processing images on AerologixMaps is a straightforward process. Here's how you can do it:
					<p>
						<ol>
							<li>Ensure you're connected to the internet and access AerologixMaps via a supported browser on your laptop or computer.</li>
							<li>Log into your AerologixMaps account and choose the option to initiate a new processing task.</li>
							<li>Upload the drone images you wish to process.</li>
							<li>Configure the processing settings based on your requirements. This might involve selecting a specific template or desired outputs.</li>
							<li>
								Once your images are uploaded and settings are in place, start the processing. AerologixMaps will handle the processing in the cloud, allowing you
								to attend to other tasks.
							</li>
							<li>
								Upon completion, you can view and download the processed results from your AerologixMaps account. Outputs might encompass orthomosaic, DSM, DTM,
								contour maps, point clouds, and 3D models.
							</li>
						</ol>
					</p>
					<p>
						The user-friendly design of AerologixMaps ensures that processing drone images is both efficient and hassle-free. Upload, set your preferences, and let
						AerologixMaps manage the rest!
					</p>
				</>
			),
		},
		{
			key: 'd2',
			questionText: 'What processing options are available on AerologixMaps?',
			answerText: (
				<>
					AerologixMaps offers a variety of processing options tailored for different needs in drone image processing. The available options are:
					<p>
						<ol>
							<li>
								<strong>Rural:</strong> Optimized for areas with less infrastructure, focusing on natural landscapes.
							</li>
							<li>
								<strong>Urban:</strong> Ideal for cityscapes and areas with dense infrastructure, ensuring accuracy in populated regions.
							</li>
							<li>
								<strong>Forestry:</strong> Tailored for wooded areas, capturing details of trees and vegetation.
							</li>
							<li>
								<strong>3D:</strong> Generates three-dimensional models, ideal for topographic surveys and detailed landscape visualization.
							</li>
							<li>
								<strong>Agricultural:</strong> Designed for farmlands, capturing details to analyze crop health, irrigation, and more.
							</li>
						</ol>
					</p>
					<p>With these processing options, AerologixMaps ensures precision and efficiency, catering to various needs in the drone imaging domain.</p>
				</>
			),
		},
		{
			key: 'd3',
			questionText: 'Can the results from AerologixMaps be shared?',
			answerText:
				'Yes, the results from AerologixMaps can be shared. Once you have processed your images and generated the desired outputs, AerologixMaps provides a sharing feature. You can share the maps and other outputs with colleagues, clients, or other stakeholders through a secure link. This link can be accessed by anyone you share it with, allowing them to view the results without needing an AerologixMaps account. This feature ensures seamless collaboration and easy dissemination of your processed data to the desired audience.',
		},
		{
			key: 'd4',
			questionText: 'Can Ground Control Points (GCPs) be used on AerologixMaps?',
			answerText: `Yes, Ground Control Points (GCPs) can be used on AerologixMaps. GCPs play a pivotal role in enhancing the geospatial accuracy of aerial maps. By integrating GCPs into your projects, the maps produced by AerologixMaps are refined and calibrated to align accurately with actual on-ground coordinates. It's important to highlight that the free trial and lite plans do not support GCP processing. The Pro plan may have associated charges per map for GCP processing, while the business subscription plan includes this feature without additional costs.`,
		},
		{
			key: 'd5',
			questionText: 'Can I create 3D maps using AerologixMaps?',
			answerText: 'Yes, you can create 3D maps using AerologixMaps. The platform is designed to process drone images and generate various outputs, including 3D models',
		},
	],
	5: [
		{
			key: 'e1',
			questionText: 'How is my data protected on AerologixMaps?',
			answerText:
				'At AerologixMaps, we prioritize data security using industry-standard encryption for data in transit and at rest. Our data is housed in top-tier centers with stringent security, frequently backed up, and accessed only by authorized personnel. We strictly adhere to relevant data protection laws, conduct regular security audits, and maintain a clear data retention policy to ensure the utmost safety of your information.',
		},
		{
			key: 'e2',
			questionText: 'What happens to my AerologixMaps projects when the subscription expires?',
			answerText: `Upon the expiration of your AerologixMaps subscription, it's essential to export any crucial data you wish to retain long-term. While your 2D maps and 3D models will remain accessible for viewing for 90 days post-plan expiration, the ability to download them will be deactivated immediately after the subscription ends. For uninterrupted access and functionality, ensure you download all required data while your subscription is active. If you choose to resubscribe within the 90-day window, all your maps, features, and functionalities will be fully restored, and the expiry date on your maps will be removed.`,
		},
	],
	6: [
		{
			key: 'f1',
			questionText: 'How can I submit product feedback and feature requests for AerologixMaps?',
			answerText: (
				<>
					To submit feedback or feature requests for AerologixMaps, please email <a href='mailto:maps.support@aerologix.com'>maps.support@aerologix.com</a>. Your input is
					valuable to us for future improvements.
				</>
			),
		},
		{
			key: 'f2',
			questionText: 'How can I get technical support for AerologixMaps?',
			answerText: (
				<>
					For technical support with AerologixMaps, you can access our help desk center or send an email to{' '}
					<a href='mailto:maps.support@aerologix.com'>maps.support@aerologix.com</a>. Our team is here to assist you.
				</>
			),
		},
	],
}
