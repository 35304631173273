/*eslint-disable*/
import React from 'react'
import { Elements } from 'react-stripe-elements'
import InjectedPaymentProfile from './stripe-card-info'

const StripePayment = props => {
	return (
		<Elements>
			<InjectedPaymentProfile {...props} />
		</Elements>
	)
}

export default StripePayment
