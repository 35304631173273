import React from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { Form } from 'antd'
import AeroButton from '../../AeroComponents/Button'
import AeroInput from '../../AeroComponents/Input'

const EmailConfirmation = () => {
	return (
		<div style={{ textAlign: 'center', padding: '40px' }}>
			<AeroImage alt='email-country' src={IMAGES_SRC.EMAIL_OTP_ICON} />
			<div className='signup-success-title'>Verify your email address</div>
			<div className='signup-success-subtitle' style={{ fontWeight: '400' }}>
				Please enter a 6-digit verification code sent to your email address
			</div>
			<div style={{ margin: 'auto', padding: '50px', width: '30%' }}>
				<Form layout='vertical'>
					<Form.Item label='Enter OTP Sent to your Email' extra={<div style={{ color: '#00B3FF', cursor: 'pointer', float: 'left', marginTop: '5px' }}>Resend Code</div>}>
						<AeroInput placeholder='Type here' />
					</Form.Item>

					<Form.Item style={{ marginTop: '40px' }}>
						<AeroButton type='primary' block>
							Continue
						</AeroButton>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

export default EmailConfirmation
