import React, { useEffect, useRef, useState } from 'react'
import { Steps, notification } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	getProcessing,
	updateProcessing,
	uploadFlightLogFile,
	deleteFlightLog,
	deleteControlPoints,
	checkTransferStatus,
	updateStage,
	startProcessing,
	createTransaction,
	chargeUser,
	uploadControlPointsFile,
} from '../../Stores/MyMaps/action'
import { getActivePlanDetails } from '../../Stores/Subscription/action'
import { goTo } from '../../Services/NavigationService'
import ProcessForm from './components/process-form'
import { capitalize } from '../../Services/UtilityService'
import ProcessUploads from './components/process-uploads'
import ProcessingSummary from './components/process-summary'
import AeroModal from '../../AeroComponents/Modal'
import AeroNotificationContent from '../../AeroComponents/Notification'
import StripePayment from '../../AeroComponents/Payments/stripe-payment'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import MobileHeader from '../../AeroComponents/MobileHeader'
import styles from './mymaps.module.sass'
import { SHOW_CONTROL_POINTS_UPLOADER, SHOW_LOG_FILE_UPLOADER } from './constants'

const EditMap = props => {
	const processingId = props.match.params.processingId
	const {
		getProcessing,
		processingData,
		updateProcessing,
		processingUpdated,
		uploadFlightLogFile,
		flightLogUploaded,
		deleteFlightLog,
		logDeleted,
		deleteControlPoints,
		controlPointsDeleted,
		checkTransferStatus,
		transferData,
		stageUpdated,
		profileData,
		startProcessing,
		processingStarted,
		createTransaction,
		transactionCreated,
		chargeUser,
		userCharged,
		transferDifference,
		handleHamburger,
		uploadControlPointsFile,
		controlPointsUploaded,
		getActivePlanDetails,
		activePlanData,
	} = props
	const [processingDetails, setProcessingDetails] = useState(null)
	const [waitForApi, setWaitForApi] = useState(false)
	const [stage, setStage] = useState(0)
	const [paymentModal, setPaymentModal] = useState(false)
	const [gcpProcessing, setGcpProcessing] = useState(false)
	const [isNextDisabled, setIsNextDisabled] = useState(false)
	const [activePlanDetails, setActivePlanDetails] = useState(null)
	const stageUpdation = useRef(false)

	useEffect(() => {
		setWaitForApi(true)
		getProcessing({ processing_id: processingId })
	}, [])

	useEffect(() => {
		if (processingData && waitForApi) {
			setWaitForApi(false)
			changeStage(processingData.stage)
			if (processingData.boxFolderResponse.totalFileCount === 0) {
				setIsNextDisabled(true)
			}
			setProcessingDetails(processingData)
		}
	}, [processingData])

	useEffect(() => {
		if (processingUpdated && waitForApi) {
			setWaitForApi(false)
			changeStage(processingUpdated.stage)
			setProcessingDetails(processingUpdated)
			pushEventToMixpanel('Step 1: Parameters added', {
				'Processing Name': processingUpdated.processingName,
				'Camera Type': processingUpdated.processingData.ab_camera_type,
				'Procesing type': processingUpdated.processingData.ab_processing_template,
				'Flight log': processingUpdated.processingData.ab_georeference_type,
				'GCP Processing': processingUpdated.processingData.bl_gcp,
				'ESPG value': processingUpdated.processingData.str_gcp_epsg,
			})
			setGcpProcessing(processingUpdated.processingData.bl_gcp)
		}
	}, [processingUpdated])

	useEffect(() => {
		if (activePlanData && waitForApi) {
			setWaitForApi(false)
			setActivePlanDetails(activePlanData)
		}
	}, [activePlanData])

	const handleProcessingData = body => {
		setWaitForApi(true)
		updateProcessing(body)
	}

	const callUploadFlightLog = data => {
		setWaitForApi(true)
		uploadFlightLogFile(data)
	}

	const callUploadFlightControlPoints = data => {
		setWaitForApi(true)
		uploadControlPointsFile(data)
	}

	useEffect(() => {
		if (flightLogUploaded && waitForApi) {
			setWaitForApi(false)
			let data = processingDetails
			data.flightLogUrl = flightLogUploaded.url
			setProcessingDetails(data)
			notification.open({
				message: <AeroNotificationContent content='Flight Log file uploaded' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		}
	}, [flightLogUploaded])

	useEffect(() => {
		if (controlPointsUploaded && waitForApi) {
			setWaitForApi(false)
			let data = processingDetails
			data.controlPointUrl = controlPointsUploaded.url
			setProcessingDetails(data)
			notification.open({
				message: <AeroNotificationContent content='Control Points file uploaded' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		}
	}, [controlPointsUploaded])

	useEffect(() => {
		if (logDeleted && waitForApi) {
			setWaitForApi(false)
			let data = processingDetails
			data.flightLogUrl = null
			setProcessingDetails(data)
			notification.open({
				message: <AeroNotificationContent content='Flight Log file deleted' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		}
	}, [logDeleted])

	useEffect(() => {
		if (controlPointsDeleted && waitForApi) {
			setWaitForApi(false)
			let data = processingDetails
			data.controlPointUrl = null
			setProcessingDetails(data)
			notification.open({
				message: <AeroNotificationContent content='Control Points file deleted' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
		}
	}, [controlPointsDeleted])

	useEffect(() => {
		if (transferData && waitForApi) {
			changeStage(transferData.stage)
			setProcessingDetails(transferData)
			pushEventToMixpanel('Step 2: File upload completed on Box', {
				'No.of files uploaded': transferData.processingData?.int_total_images,
			})
		}
	}, [transferData, transferDifference])

	useEffect(() => {
		if (stageUpdated && waitForApi) {
			setWaitForApi(false)
			changeStage(stageUpdated.stage)
			stageUpdation.current = true
			setProcessingDetails(stageUpdated)
		}
	}, [stageUpdated])

	useEffect(() => {
		if (processingStarted && waitForApi) {
			setWaitForApi(false)
			notification.open({
				message: <AeroNotificationContent content='Processing Started' />,
				style: { background: '#6CBE44', borderRadius: '12px' },
			})
			pushEventToMixpanel('Step 3: Processing Started', {
				'Subscription type': profileData?.active_plan?.type,
				'Number of Gigapixels': processingStarted.gigapixels,
				'GCP Processing': gcpProcessing,
			})
			goTo('my-maps')
		}
	}, [processingStarted])

	useEffect(() => {
		if (transactionCreated && waitForApi) {
			setWaitForApi(false)
			setPaymentModal(true)
		}
	}, [transactionCreated])

	useEffect(() => {
		if (userCharged && waitForApi) {
			pushEventToMixpanel('Payment Completed for Processing', {
				'Payment Successful': userCharged > 0 ? true : false,
				'Payment Amount': transactionCreated?.amount,
			})
			if (userCharged < 0) {
				// payment failure
				return
			}
			setPaymentModal(false)
			startProcessing({ processingId: processingId })
		}
	}, [userCharged])

	const changeStage = val => {
		if (val === 2) {
			setWaitForApi(true)
			getActivePlanDetails({ plan_id: profileData.active_plan.id })
		}
		setStage(val)
	}

	const callDeleteLog = () => {
		setWaitForApi(true)
		deleteFlightLog({ processing_id: processingId })
	}

	const callDeleteControlPoints = () => {
		setWaitForApi(true)
		deleteControlPoints({ processing_id: processingId })
	}

	const processUpload = reupload => {
		pushEventToMixpanel('Step 2: File upload- Box upload', {})
		setWaitForApi(true)
		checkTransferStatus({ processing_id: processingId, reUpload: reupload })
	}

	const callStartProcessing = () => {
		setWaitForApi(true)
		startProcessing({ processingId: processingId })
	}

	const callTransaction = (overdue, amount, planId, isGcp) => {
		setWaitForApi(true)
		createTransaction({ gcpCharge: isGcp ? 49 : 0, gigapixelsCharge: amount, subscriptionId: planId, gigaPixels: overdue, processName: processingDetails.processingName })
	}

	const payNow = token => {
		let body = {
			bankToken: token,
			gcpTransactionId: transactionCreated?.gcpTransactionId,
			gigapixelTransactionId: transactionCreated?.gigapixelTransactionId,
		}
		setWaitForApi(true)
		chargeUser(body)
	}

	const enableNextBtn = event => {
		if (isNextDisabled) {
			setIsNextDisabled(false)
		}
	}

	const STEP_MAPPER = {
		0: (
			<ProcessForm
				processingId={processingId}
				handleProcessingData={handleProcessingData}
				processingData={processingDetails?.processingData}
				activePlan={profileData?.active_plan?.type}
			/>
		),
		1: (
			<ProcessUploads
				processingId={processingId}
				callUploadFlightLog={callUploadFlightLog}
				boxCreds={processingDetails?.boxFolderResponse}
				flightLogUrl={processingDetails?.flightLogUrl}
				changeStage={changeStage}
				callDeleteLog={callDeleteLog}
				processUpload={processUpload}
				stage={processingDetails?.stage}
				uploadStatus={processingDetails?.uploadedData}
				showFlightLogUploader={
					processingDetails?.processingData?.ab_camera_type
						? SHOW_LOG_FILE_UPLOADER[(processingDetails?.processingData?.ab_camera_type)][(processingDetails?.processingData?.ab_georeference_type)]
						: false
				}
				showControlPointsUploader={
					processingDetails?.processingData?.ab_camera_type
						? SHOW_CONTROL_POINTS_UPLOADER[(processingDetails?.processingData?.ab_camera_type)][(processingDetails?.processingData?.ab_georeference_type)][
								(processingDetails?.processingData?.bl_gcp)
						  ]
						: false
				}
				cameraType={processingDetails?.processingData?.ab_camera_type}
				flightLogType={processingDetails?.processingData?.ab_georeference_type}
				gcpValue={processingDetails?.processingData?.bl_gcp}
				flightControlPointsUrl={processingDetails?.controlPointUrl}
				callDeleteControlPoints={callDeleteControlPoints}
				callUploadFlightControlPoints={callUploadFlightControlPoints}
				enableNextBtn={enableNextBtn}
				isNextDisabled={isNextDisabled}
			/>
		),
		2: (
			<ProcessingSummary
				data={processingDetails}
				profileData={profileData}
				activePlan={activePlanDetails}
				changeStage={changeStage}
				callStartProcessing={callStartProcessing}
				callTransaction={callTransaction}
			/>
		),
	}

	return (
		<>
			{processingDetails && (
				<div className='module-container'>
					<MobileHeader header='My Maps' handleHamburger={handleHamburger} />
					<div className='module-title'>
						<span onClick={() => goTo('my-maps')} style={{ color: '#84818A', cursor: 'pointer' }}>
							My Maps
						</span>{' '}
						<RightOutlined /> <span>{capitalize(processingDetails.processingName)}</span>
					</div>
					<div style={{ marginTop: '30px', marginRight: '30px', marginBottom: '30px' }}>
						<Steps
							labelPlacement='vertical'
							current={stage}
							responsive={false}
							items={[
								{
									description: <span className={styles['step-desc']}>Processing Settings</span>,
								},
								{
									description: <span className={styles['step-desc']}>Upload Images</span>,
								},
								{
									description: <span className={styles['step-desc']}>Processing Summary</span>,
								},
							]}
						/>
					</div>
					{STEP_MAPPER[stage]}
				</div>
			)}
			<AeroModal title='Payment Option' footer={false} onCancel={() => setPaymentModal(false)} visible={paymentModal}>
				<StripePayment displayAmount={transactionCreated?.amount.toFixed(2)} cancel={() => setPaymentModal(false)} updateCardDetails={payNow} />
			</AeroModal>
		</>
	)
}

const mapStateToProps = state => {
	return {
		profileData: state.UserSessionReducer.profileData,
		processingData: state.MyMapsReducer.processingData,
		processingUpdated: state.MyMapsReducer.processingUpdated,
		flightLogUploaded: state.MyMapsReducer.flightLogUploaded,
		logDeleted: state.MyMapsReducer.logDeleted,
		transferData: state.MyMapsReducer.transferData,
		stageUpdated: state.MyMapsReducer.stageUpdated,
		processingStarted: state.MyMapsReducer.processingStarted,
		transactionCreated: state.MyMapsReducer.transactionCreated,
		userCharged: state.MyMapsReducer.userCharged,
		transferDifference: state.MyMapsReducer.transferDifference,
		controlPointsDeleted: state.MyMapsReducer.controlPointsDeleted,
		controlPointsUploaded: state.MyMapsReducer.controlPointsUploaded,
		activePlanData: state.SubscriptionReducer.activePlanData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getProcessing: bindActionCreators(getProcessing, dispatch),
		updateProcessing: bindActionCreators(updateProcessing, dispatch),
		uploadFlightLogFile: bindActionCreators(uploadFlightLogFile, dispatch),
		deleteFlightLog: bindActionCreators(deleteFlightLog, dispatch),
		checkTransferStatus: bindActionCreators(checkTransferStatus, dispatch),
		updateStage: bindActionCreators(updateStage, dispatch),
		startProcessing: bindActionCreators(startProcessing, dispatch),
		createTransaction: bindActionCreators(createTransaction, dispatch),
		chargeUser: bindActionCreators(chargeUser, dispatch),
		deleteControlPoints: bindActionCreators(deleteControlPoints, dispatch),
		uploadControlPointsFile: bindActionCreators(uploadControlPointsFile, dispatch),
		getActivePlanDetails: bindActionCreators(getActivePlanDetails, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EditMap)
