import * as ACTION from './actionTypes'
import {
	loginApi,
	getCountryListApi,
	recoverPasswordApi,
	getSocialAuthApi,
	reportErrorApi,
	registerUserApi,
	contactSupportApi,
	getProfileApi,
	updateProfileApi,
	sendEmailOtpApi,
	validateEmailOtpApi,
	updatePasswordApi,
	getOtpApi,
	resendVerificationLinkApi,
} from './api'

export const logOut = () => {
	return {
		type: ACTION.LOG_OUT,
	}
}

export const toggleAppLoading = loaderType => {
	return {
		type: ACTION.APP_LOADER,
		payload: loaderType,
	}
}

export const recoverPassword = body => {
	return {
		type: ACTION.RECOVER_PASSWORD,
		payload: recoverPasswordApi(body),
	}
}

export const getCountryList = () => {
	return {
		type: ACTION.GET_COUNTRY_LIST,
		payload: getCountryListApi(),
	}
}

export const getSocialAuth = body => {
	return {
		type: ACTION.GET_SOCIAL_AUTH,
		payload: getSocialAuthApi(body),
	}
}

export const reportError = body => {
	return {
		type: ACTION.REPORT_ERROR,
		payload: reportErrorApi(body),
	}
}

export const registerUser = body => {
	return {
		type: ACTION.REGISTER_USER,
		payload: registerUserApi(body),
	}
}

export const login = body => {
	return {
		type: ACTION.LOGIN,
		payload: loginApi(body),
	}
}

export const contactSupport = body => {
	return {
		type: ACTION.CONTACT_SUPPORT,
		payload: contactSupportApi(body),
	}
}

export const getProfile = () => {
	return {
		type: ACTION.GET_PROFILE,
		payload: getProfileApi(),
	}
}

export const updateProfile = body => {
	return {
		type: ACTION.UPDATE_PROFILE,
		payload: updateProfileApi(body),
	}
}

export const sendEmailOtp = body => {
	return {
		type: ACTION.SEND_EMAIL_OTP,
		payload: sendEmailOtpApi(body),
	}
}

export const validateEmailOtp = body => {
	return {
		type: ACTION.VALIDATE_EMAIL_OTP,
		payload: validateEmailOtpApi(body),
	}
}

export const updatePassword = body => {
	return {
		type: ACTION.UPDATE_PASSWORD,
		payload: updatePasswordApi(body),
	}
}

export const getOtp = body => {
	return {
		type: ACTION.GET_OTP,
		payload: getOtpApi(body),
	}
}

export const resendVerificationLink = body => {
	return {
		type: ACTION.RESEND_VERIFICATION_LINK,
		payload: resendVerificationLinkApi(body),
	}
}
