import * as ACTION from './actionTypes'
import {
	getAllMapsApi,
	createProcessingApi,
	getProcessingApi,
	shareProcessingApi,
	deleteProcessingApi,
	updateProcessingApi,
	uploadFlightLogFileApi,
	getBoxCredsApi,
	deleteFlightLogApi,
	checkTransferStatusApi,
	updateStageApi,
	startProcessingApi,
	createTransactionApi,
	chargeUserApi,
	uploadControlPointsFileApi,
	deleteControlPointsApi,
} from './api'

export const getAllMaps = queryParam => {
	return {
		type: ACTION.GET_ALL_MAPS,
		payload: getAllMapsApi(queryParam),
	}
}

export const createProcessing = body => {
	return {
		type: ACTION.CREATE_PROCESSING,
		payload: createProcessingApi(body),
	}
}

export const getProcessing = queryParam => {
	return {
		type: ACTION.GET_PROCESSING,
		payload: getProcessingApi(queryParam),
	}
}

export const shareProcessing = body => {
	return {
		type: ACTION.SHARE_PROCESSING,
		payload: shareProcessingApi(body),
	}
}

export const deleteProcessing = queryParam => {
	return {
		type: ACTION.DELETE_PROCESSING,
		payload: deleteProcessingApi(queryParam),
	}
}

export const updateProcessing = body => {
	return {
		type: ACTION.UPDATE_PROCESSING,
		payload: updateProcessingApi(body),
	}
}

export const uploadFlightLogFile = body => {
	return {
		type: ACTION.UPLOAD_FLIGHT_LOG_FILE,
		payload: uploadFlightLogFileApi(body),
	}
}

export const getBoxCreds = queryParam => {
	return {
		type: ACTION.GET_BOX_CREDS,
		payload: getBoxCredsApi(queryParam),
	}
}

export const deleteFlightLog = queryParam => {
	return {
		type: ACTION.DELETE_FLIGHT_LOG,
		payload: deleteFlightLogApi(queryParam),
	}
}

export const checkTransferStatus = queryParam => {
	return {
		type: ACTION.CHECK_TRANSFER_STATUS,
		payload: checkTransferStatusApi(queryParam),
	}
}

export const updateStage = body => {
	return {
		type: ACTION.UPDATE_STAGE,
		payload: updateStageApi(body),
	}
}

export const startProcessing = body => {
	return {
		type: ACTION.START_PROCESSING,
		payload: startProcessingApi(body),
	}
}

export const createTransaction = body => {
	return {
		type: ACTION.CREATE_TRANSACTION,
		payload: createTransactionApi(body),
	}
}

export const chargeUser = body => {
	return {
		type: ACTION.CHARGE_USER,
		payload: chargeUserApi(body),
	}
}

export const uploadControlPointsFile = body => {
	return {
		type: ACTION.UPLOAD_CONTROL_POINTS_FILE,
		payload: uploadControlPointsFileApi(body),
	}
}

export const deleteControlPoints = queryParam => {
	return {
		type: ACTION.DELETE_CONTROL_POINTS,
		payload: deleteControlPointsApi(queryParam),
	}
}
