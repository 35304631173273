import React from 'react'
import { MenuOutlined } from '@ant-design/icons'
import './style.sass'

const MobileHeader = props => {
	const { header, handleHamburger } = props

	return (
		<div className='mobile-header'>
			<MenuOutlined style={{ cursor: 'pointer' }} onClick={() => handleHamburger()} />
			<div style={{ margin: 'auto' }}>{header}</div>
		</div>
	)
}

export default MobileHeader
