import React, { useEffect, useState } from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { gotToHome } from '../../Services/NavigationService'

const EmailVerificationSuccessful = () => {
	const [timeVal, setTimeVal] = useState(10)

	const startTimer = type => {
		let timer = 10
		const interval = setInterval(() => {
			if (timer > 0 && type !== 'btn') {
				timer = timer - 1
				setTimeVal(prevState => prevState - 1)
			} else {
				clearInterval(interval)
				gotToHome()
			}
		}, 1000)
	}

	useEffect(() => startTimer('app'), [])
	return (
		<div style={{ textAlign: 'center', padding: '40px' }}>
			<AeroImage alt='big-check' src={IMAGES_SRC.EMAIL_VERIFICATION_SUCCESSFUL} />
			<div className='signup-success-title'>Email verification successful!</div>
			<div className='signup-success-timer'>You will be redirected to login screen in {timeVal} sec</div>
			<div className='signup-success-footer'>
				If not,{' '}
				<span onClick={() => startTimer('btn')} style={{ cursor: 'pointer', color: '#00B3FF' }}>
					Click here
				</span>
			</div>
		</div>
	)
}

export default EmailVerificationSuccessful
