import React from 'react'
import moment from 'moment'
import styles from '../subscription.module.sass'
import AeroButton from '../../../AeroComponents/Button'

const GracePeriodAlert = props => {
	const { goToBuyMode, cancellationDate, gracePeriodEndDate } = props
	return (
		<div style={{ padding: '20px' }} className={styles['warning-banner-wrapper']}>
			<div style={{ fontSize: '32px' }}>
				<strong>Subscription Plan Expired!</strong>
			</div>
			<div style={{ marginTop: '10px' }}>
				You can still view your maps, but you won’t be able to do any processing. You have until{' '}
				<strong>
					{moment
						.utc(gracePeriodEndDate)
						.local()
						.format('DD MMMM YYYY, HH:mm A')}
				</strong>{' '}
				to buy a new subscription and use your remaining gigapixel balance.
			</div>
			<div style={{ marginTop: '10px' }}>
				<span style={{ marginRight: '20px' }}>
					Cancellation date:{' '}
					<strong>
						{moment
							.utc(cancellationDate)
							.local()
							.format('DD/MM/YYYY')}
					</strong>
				</span>{' '}
				|{' '}
				<span style={{ marginLeft: '20px' }}>
					Last date for renewal:{' '}
					<strong>
						{moment
							.utc(gracePeriodEndDate)
							.local()
							.format('DD/MM/YYYY')}
					</strong>
				</span>
			</div>
			<div style={{ marginTop: '20px' }}>
				<AeroButton onClick={goToBuyMode} width='228px' otherstyles={{ color: '#00B3FF' }}>
					Browse Plan
				</AeroButton>
			</div>
		</div>
	)
}

export default GracePeriodAlert
