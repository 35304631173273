import React, { useEffect, useState } from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { Form, Statistic } from 'antd'
import AeroButton from '../../AeroComponents/Button'
import AeroInput from '../../AeroComponents/Input'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validateEmailOtp, sendEmailOtp } from '../../Stores/UserSession/action'
import { setValueToStorage } from '../../Services/LocalStorageService'
import { goTo } from '../../Services/NavigationService'
const { Countdown } = Statistic

const VerifyEmailOtp = props => {
	const { callProfile, validateEmailOtp, emailValidated, sendEmailOtp, emailOtpSent } = props
	const [waitForApi, setWaitForApi] = useState(false)
	const [verificationId, setVerificationId] = useState(props.location.state?.verificationId)
	const [resendBtnClicked, setResendBtnClicked] = useState(false)
	const [timer, setTimer] = useState(null)

	const recordValues = values => {
		setWaitForApi(true)
		validateEmailOtp({ emailOtp: values.otp, verificationId: verificationId })
	}

	useEffect(() => {
		if (emailOtpSent && waitForApi) {
			setWaitForApi(false)
			setResendBtnClicked(true)
			setTimer(Date.now() + 1000 * 60 * 5)
			setVerificationId(emailOtpSent)
		}
	}, [emailOtpSent])

	useEffect(() => {
		if (emailValidated && waitForApi) {
			setWaitForApi(false)
			setValueToStorage('TOKEN', emailValidated.token)
			callProfile()
		}
	}, [emailValidated])

	const handleResend = () => {
		setWaitForApi(true)
		sendEmailOtp({ email: props.location.state.emailValue })
	}

	const toggleShowTimer = () => setResendBtnClicked(false)

	return (
		<>
			<AeroButton type='link' onClick={() => goTo('social-login-data')}>
				<AeroImage alt='go back' src={IMAGES_SRC.GO_BACK_ICON} />
			</AeroButton>
			<div style={{ textAlign: 'center', padding: '40px' }}>
				<AeroImage alt='email-country' src={IMAGES_SRC.EMAIL_BIG_ICON} />
				<div className='signup-success-title'>Verify your email address</div>
				<div className='signup-success-subtitle' style={{ fontWeight: '400' }}>
					Please enter a 4-digit verification code sent to your email address
				</div>
				<div className='recovery-form'>
					<Form onFinish={recordValues} layout='vertical'>
						<Form.Item
							rules={[{ required: true, message: 'Please input OTP!', whitespace: true }]}
							name='otp'
							label='Enter OTP Sent to your Email'
							extra={
								<>
									<AeroButton
										dissabled={resendBtnClicked}
										onClick={handleResend}
										type='link'
										size='middle'
										style={{ color: resendBtnClicked ? '#47464A' : '#00B3FF', float: 'left', position: 'relative', right: '10px', top: '3px' }}
									>
										Resend Code
									</AeroButton>
									{resendBtnClicked ? (
										<Countdown
											prefix={<span style={{ fontWeight: '400' }}>in</span>}
											suffix={<span style={{ fontWeight: '400', position: 'relative', right: '3px' }}>s</span>}
											valueStyle={{
												color: '#47464A',
												fontWeight: '700',
												fontSize: '14px',
												textAlign: 'left',
												position: 'relative',
												top: '8px',
												right: '20px',
											}}
											value={timer}
											format='ss'
											onFinish={toggleShowTimer}
										/>
									) : null}
								</>
							}
						>
							<AeroInput type='number' placeholder='Type here' />
						</Form.Item>
						<Form.Item style={{ marginTop: '40px' }}>
							<AeroButton htmlType='submit' type='primary' block>
								Continue
							</AeroButton>
						</Form.Item>
					</Form>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = state => {
	return {
		emailValidated: state.UserSessionReducer.emailValidated,
		emailOtpSent: state.UserSessionReducer.emailOtpSent,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		validateEmailOtp: bindActionCreators(validateEmailOtp, dispatch),
		sendEmailOtp: bindActionCreators(sendEmailOtp, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(VerifyEmailOtp)
