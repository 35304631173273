import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Drawer, Layout } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './app.sass'
import SideBarNavigation from './SideBarMenu'
import Header from './Header'
import { logOut, getProfile, updateProfile } from '../Stores/UserSession/action'
import { setNavigation } from '../Services/NavigationService'
import ScreenLoader from '../AeroComponents/Loader'
import { NON_AUTH_ROUTES, NON_COUNTRY_ROUTES, ROUTES } from './route-constants'
import { IMAGES_SRC } from '../AeroComponents/Image/image.constants'
import GTMTracker from '../Services/RouteChangeListener'

import { setValueToStorage, getValueFromStorage, removeValueFromStorage } from '../Services/LocalStorageService'
import { pushEventToMixpanel, setUserIdentityToMixpanel, pushSuperPropertiesToMixpanel, resetMixpanel, pushProfileToMixpanel } from '../Services/MixpanelService'
import AeroImage from '../AeroComponents/Image'
import Subscription from '../Modules/Subscription'

const createBrowserHistory = require('history').createBrowserHistory
const history = createBrowserHistory()
const { Sider, Content } = Layout

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoggedIn: props.isAuthenticated,
			pageLoading: props.pageLoading,
			mobileSidebarVisible: false,
			profileData: null,
			welcomeModal: false,
			redirectValue: false,
		}
		setNavigation(history)
		this.authStateRedirection(props.isAuthenticated, false)
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
			this.setState({
				isLoggedIn: nextProps.isAuthenticated,
			})
			this.authStateRedirection(nextProps.isAuthenticated, true)
		}

		if (this.props.pageLoading !== nextProps.pageLoading) {
			this.setState({
				pageLoading: nextProps.pageLoading,
			})
		}

		if (this.props.profileData !== nextProps.profileData) {
			if (nextProps.profileData) {
				const hasProfileFetchedBefore = getValueFromStorage('hasProfileFetchedBefore')
				// Setting to localstorage that profile has been fetched once so event do't get fired multiple times to Mixpanel
				setValueToStorage('hasProfileFetchedBefore', true)
				let profileData = {
					'User Id': nextProps.profileData.id,
					$email: nextProps.profileData.email,
					$first_name: nextProps.profileData.first_name,
					$last_name: nextProps.profileData.last_name,
					'User Country': nextProps.profileData.country_id,
				}

				if (!hasProfileFetchedBefore) {
					// Setting identity in Mixpanel
					setUserIdentityToMixpanel(nextProps.profileData.id)
					// Pushing supper properties in Mixpanel
					pushSuperPropertiesToMixpanel(profileData)

					pushProfileToMixpanel(profileData)
					pushEventToMixpanel('User Login', {
						'Login Method': nextProps.profileData.social_media_channel,
					})
				} else {
					// Pushing supper properties in Mixpanel
					pushSuperPropertiesToMixpanel(profileData)
				}
				this.setState({
					profileData: nextProps.profileData,
					welcomeModal: nextProps.profileData.first_time_login_popup,
				})
				let hasEmail = false
				const emailArr = nextProps.profileData.email.split('_')
				if (emailArr && emailArr.length) {
					if (emailArr[0] === 'DUMMY' && emailArr[1] === 'EMAIL' && emailArr[2] === 'FOR' && emailArr[3] === 'SOCIAL' && emailArr[4] === 'MEDIA') {
						hasEmail = true
					}
				}
				if (nextProps.profileData.country_id === 0 || hasEmail) {
					this.state.redirectValue && history.push('/social-login-data')
				} else {
					if (window.location.pathname === '/') {
						this.state.redirectValue && history.push('/my-maps')
					}
				}
			}
		}

		if (this.props.profileUpdated !== nextProps.profileUpdated) {
			if (nextProps.profileUpdated) {
				this.setState({
					welcomeModal: false,
				})
			}
		}
	}

	authStateRedirection = (isAuthenticated, redirect) => {
		if (isAuthenticated) {
			this.setState({ redirectValue: redirect })
			this.props.getProfile()
		} else {
			redirect && history.push('/')
		}
	}

	handleHamburger = () => {
		this.setState(prevState => ({
			mobileSidebarVisible: !prevState.mobileSidebarVisible,
		}))
	}

	updateModalStatus = () => this.props.updateProfile({ firstPopupFlag: false })
	callProfile = () => this.props.getProfile()

	sendMixPanelLogutEventAndLogout = () => {
		pushEventToMixpanel('User Sign out', {})
		// For clearing super properties in mixpanel
		removeValueFromStorage('hasProfileFetchedBefore')
		resetMixpanel()
		this.props.logOut()
	}

	render() {
		const { isLoggedIn, mobileSidebarVisible, profileData, welcomeModal } = this.state
		return (
			<>
				<Router history={history}>
					<GTMTracker>
						{isLoggedIn ? (
							<Layout className='app-container'>
								{this.state.pageLoading && <ScreenLoader />}
								{profileData?.country_id === 0 ? null : (
									<Sider className='sidebar-on'>
										<SideBarNavigation
											history={history}
											handleHamburger={this.handleHamburger}
											mobileSidebarVisible={mobileSidebarVisible}
											logOut={this.sendMixPanelLogutEventAndLogout}
										/>
									</Sider>
								)}

								<Layout>
									{profileData ? (
										<>
											{profileData.country_id === 0 ? <Header /> : null}

											<Content style={{ overflow: 'scroll', height: '100%', width: '100%', background: 'white' }}>
												<Switch>
													{profileData?.country_id === 0
														? NON_COUNTRY_ROUTES.map(item => (
																<Route
																	key={item.key}
																	exact
																	path={item.path}
																	render={props => <item.component hasEmail={profileData.email} callProfile={this.callProfile} {...props} />}
																/>
														  ))
														: ROUTES.map(item => (
																<Route
																	key={item.key}
																	exact
																	path={item.path}
																	render={props => (
																		<item.component handleHamburger={this.handleHamburger} callProfile={this.callProfile} {...props} />
																	)}
																/>
														  ))}
												</Switch>
											</Content>
										</>
									) : null}
								</Layout>
							</Layout>
						) : (
							<Layout className='app-container'>
								{this.state.pageLoading && <ScreenLoader />}
								<Header authenticated={false} userName={''} profilePicture={''} {...this.props} />
								<Content style={{ overflow: 'scroll', height: '100%', width: '100%', background: 'white' }}>
									<Switch>
										{NON_AUTH_ROUTES.map(item => (
											<Route key={item.key} exact path={item.path} render={props => <item.component {...props} />} />
										))}
									</Switch>
								</Content>
							</Layout>
						)}
					</GTMTracker>
				</Router>
				<Drawer
					closable={false}
					open={welcomeModal}
					placement='left'
					title={<AeroImage src={IMAGES_SRC.SIDEBAR_LOGO} />}
					width='100VW'
					getContainer={false}
					forceRender
					onClose={this.updateModalStatus}
				>
					{isLoggedIn ? <Subscription source='app' updateModalStatus={this.updateModalStatus} /> : null}
				</Drawer>
			</>
		)
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.UserSessionReducer.authState.isAuthenticated,
		pageLoading: state.UserSessionReducer.pageLoading,
		profileData: state.UserSessionReducer.profileData,
		profileUpdated: state.UserSessionReducer.profileUpdated,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		logOut: bindActionCreators(logOut, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(App)
