import { Divider, Form, Input, Space, Spin } from 'antd'
import React, { useState } from 'react'
import './styles.sass'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AppleLogin from 'react-apple-login'
import InfoBox from './components/info-box'
import AeroButton from '../../AeroComponents/Button'
import AeroInput from '../../AeroComponents/Input'
import { goTo } from '../../Services/NavigationService'
import { login } from '../../Stores/UserSession/action'
import AeroModal from '../../AeroComponents/Modal'
import GeneralConfirmationContent from '../../AeroComponents/Modal/general-confirmation-content'

const Login = props => {
	const { login } = props
	const [loading, setLoading] = useState(false)
	const [facebookLoginModal, setFacebookLoginModal] = useState(false)
	const recordValues = values => {
		const { emailId, password } = values
		const body = {
			username: emailId,
			password: password,
			role: 'STALLION',
		}
		login(body)
	}

	const redirectSocial = social_type => {
		setLoading(true)
		const redirect_endpoint = process.env.REACT_APP_BASE_URL + 'v1/login/' + social_type + '/' + 'STALLION?' + 'STALLION'
		window.location.replace(redirect_endpoint)
	}

	return (
		<>
			<div className='login-wrapper'>
				<div className='login-form-wrapper'>
					<Spin spinning={loading}>
						<div className='login-title'>Log In to AerologixMaps</div>
						<Form onFinish={recordValues} layout='vertical'>
							<Space direction='vertical' size='large' style={{ width: '100%' }}>
								<Form.Item name='emailId' rules={[{ required: true, message: 'Please input email!', whitespace: true }]} label='Email ID'>
									<AeroInput type='email' placeholder='Type here' />
								</Form.Item>
								<Form.Item rules={[{ required: true, message: 'Please input password!', whitespace: true }]} name='password' label='Password'>
									<Input.Password size='large' style={{ borderRadius: '6px' }} placeholder='Type here' />
								</Form.Item>
							</Space>
							<div className='login-action-btn'>
								<span onClick={() => goTo('forgot-password')} className='btn-maker'>
									Forgot Password?
								</span>
								<AeroButton htmlType='submit' width='170px' type='primary'>
									Log In
								</AeroButton>
							</div>
						</Form>
						<div style={{ marginTop: '40px' }}>
							<Divider>
								<span className='login-divier-txt'>Or continue with</span>
							</Divider>
						</div>
						<div style={{ textAlign: 'center', marginTop: '30px' }}>
							<Space style={{ marginBottom: '15px' }}>
								<AeroButton onClick={() => redirectSocial('GOOGLE')} width='120px'>
									<AeroImage className='login-btn-icon' alt='google' src={IMAGES_SRC.GOOGLE_ICON} />
									<span style={{ color: '#0000008A' }}>Google</span>
								</AeroButton>
								<AeroButton onClick={() => setFacebookLoginModal(true)} width='120px'>
									<AeroImage className='login-btn-icon' alt='fb' src={IMAGES_SRC.FACEBOOK_ICON} />
									<span style={{ color: '#1977F3' }}>facebook</span>
								</AeroButton>
							</Space>
							<AppleLogin
								style={{ cursor: 'pointer' }}
								designProp={{ height: 50, width: 250 }}
								clientId='com.aerologix.client'
								redirectURI={process.env.REACT_APP_BASE_URL + 'v1/forwardLogin/APPLE/STALLION'}
								scope='email%20name'
								responseType='code%20id_token'
								responseMode='form_post'
							/>
							<div className='login-bottom-btn' style={{ marginTop: '40px', color: '#84848A' }}>
								New to AerologixMaps?{' '}
								<AeroButton onClick={() => goTo('signup')} color='#00B3FF' type='text'>
									Create an Account Using Email
								</AeroButton>
							</div>
						</div>
					</Spin>
				</div>
				<InfoBox />
			</div>
			<AeroModal footer={false} visible={facebookLoginModal} onCancel={() => setFacebookLoginModal(false)}>
				<GeneralConfirmationContent
					primaryBtnAction={() => setFacebookLoginModal(false)}
					primaryBtnText='Cancel'
					secondaryBtnAction={() => goTo('forgot-password')}
					secondaryBtnText='Create Password'
					topAsset={IMAGES_SRC.BIG_INFO_ICON}
					heading='Important Update Regarding Facebook Login'
					description={
						<>
							We’re moving away from Facebook login to enhance your user experience and ensure more reliable access. Hence, your account will be transitioned to Email
							login. Please click on “Create Password” to initiate the process. After clicking, you’ll be prompted to enter the same email ID used for your Facebook
							account. A system-generated password will then be sent to this email. For security and convenience, we recommend updating this password once you’ve
							logged in to your account. If you’re facing any issues with the login, please feel free to write to us at{' '}
							<a href='mailto:support@aerologix.com'> support@aerologix.com </a>. A team member will be happy to assist and get things in order for you.
						</>
					}
				/>
			</AeroModal>
		</>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		login: bindActionCreators(login, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(Login)
