import React from 'react'
import styles from '../subscription.module.sass'
import { Elements } from 'react-stripe-elements'
import CardSetupForm from '../../../AeroComponents/Payments/card-setup-form'

const PaidPlanModalRightPaymentContent = props => {
	const {
		discountCode,
		currentPaidPlan,
		handlePostSubscription,
		freeTrialAvailed,
		handlePaymentCancelled,
		callSubscriptionBoughtMixpanelEvent,
		setOldPlan,
		activePlanDetails,
		discountPercentage,
		country,
		planCharge,
		planEndDate,
	} = props

	return (
		<>
			<div className={styles['billing-cycle-title']}>Payment details</div>
			<div style={{ marginTop: '20px', color: '#84818A' }}>
				We are asking these details to provide you a best subscription experience. Rest assured you won’t incur any charges until trial period ends.
			</div>
			<div style={{ marginTop: '20px' }}>
				<Elements>
					<CardSetupForm
						discountCode={discountCode}
						handlePostSubscription={handlePostSubscription}
						planId={currentPaidPlan.id}
						handlePaymentCancelled={handlePaymentCancelled}
						callSubscriptionBoughtMixpanelEvent={callSubscriptionBoughtMixpanelEvent}
						setOldPlan={setOldPlan}
						currentPlan={activePlanDetails}
						discountPercentage={discountPercentage}
						freeTrialAvailed={freeTrialAvailed}
						payBtnText={
							freeTrialAvailed ? <>Pay USD {country === 1 ? `${(planCharge + planCharge * 0.1).toFixed(2)} (including 10% GST)` : planCharge}</> : 'Try for USD 0'
						}
					/>
				</Elements>
			</div>

			<div style={{ textAlign: 'center' }}>
				USD<span style={{ fontSize: '32px', fontWeight: '600' }}>{country === 1 ? (planCharge + planCharge * 0.1).toFixed(2) : planCharge}</span> after{' '}
				{freeTrialAvailed ? planEndDate.format('MMMM D, YYYY') : 'free trial ends'}
			</div>
		</>
	)
}

export default PaidPlanModalRightPaymentContent
