/*eslint-disable*/
import * as ACTION from './actionTypes'
import typeToReducer from 'type-to-reducer'
import { setValueToStorage, clearToken, initializeAuth } from '../../Services/LocalStorageService'
import { message } from 'antd'
import { gotToHome } from '../../Services/NavigationService'

const initialState = {
	authState: {
		isAuthenticated: initializeAuth(),
		authenticate() {
			this.isAuthenticated = true
		},
		signout() {
			this.isAuthenticated = false
			clearToken()
		},
	},
	pageLoading: false,
	modalLoading: false,
	token: null,
	countryListData: null,
	registrationDone: null,
	passwordRecovered: null,
	contacted: null,
	profileData: null,
	profileUpdated: null,
	emailOtpSent: null,
	emailValidated: null,
	passwordUpdated: null,
	getOtpData: null,
}

const UserSessionReducer = typeToReducer(
	{
		LOGIN: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.data && action.payload.status_code === 200) {
					state.authState.authenticate()
					setValueToStorage('TOKEN', action.payload.data.token)
					return Object.assign({}, state, {
						token: action.payload.data.token,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		LOG_OUT: state => {
			state.authState.signout()
			return Object.assign({}, state, {
				token: null,
				profileData: null,
			})
		},
		APP_LOADER: (state, action) => {
			let objectToReturn
			switch (action.payload) {
				case 'PAGE':
					objectToReturn = Object.assign({}, state, {
						pageLoading: !state.pageLoading,
					})
					break
				case 'MODAL':
					objectToReturn = Object.assign({}, state, {
						modalLoading: !state.modalLoading,
					})
					break
				default:
					objectToReturn = Object.assign({}, state, {})
					break
			}
			return objectToReturn
		},
		[ACTION.GET_COUNTRY_LIST]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						countryListData: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_SOCIAL_AUTH]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					state.authState.authenticate()
					setValueToStorage('TOKEN', action.payload.data.token)
					return Object.assign({}, state, {
						token: action.payload.data.token,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.REPORT_ERROR]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.REGISTER_USER]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						registrationDone: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.RECOVER_PASSWORD]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						passwordRecovered: Math.random(),
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CONTACT_SUPPORT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Support team contacted!')
					return Object.assign({}, state, {
						contacted: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_PROFILE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						profileData: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_PROFILE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						profileUpdated: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.SEND_EMAIL_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('OTP sent to your Email!')
					return Object.assign({}, state, {
						emailOtpSent: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.VALIDATE_EMAIL_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						emailValidated: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_PASSWORD]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						passwordUpdated: Math.random(),
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('OTP has been sent to your phone number')
					return Object.assign({}, state, {
						getOtpData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {
						getOtpData: 'error ' + Math.random(),
					})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				message.error('Error sending OTP')
				return Object.assign({}, state, {
					getOtpData: 'error ' + Math.random(),
				})
			},
		},
		[ACTION.RESEND_VERIFICATION_LINK]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Verification link resent to your email successfully!')
					gotToHome()
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
	},
	initialState,
)

export default UserSessionReducer
