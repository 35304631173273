import { message } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSocialAuth } from '../../Stores/UserSession/action'
import { goTo } from '../../Services/NavigationService'

const ReceiveAuth = props => {
	useEffect(() => {
		const authKey = props.match.params.authKey
		const channel = props.match.params.channel
		if (authKey && channel) {
			const body = {
				oauth2Token: authKey,
				role: 'STALLION',
				socialMediaChannel: channel,
				redirection: 'STALLION',
			}
			props.getSocialAuth(body).then(res => {	 
				goTo('')				 		 
			})
			 
		} else {
			message.error('No message received from server!')
		}
	}, [])

	return <></>
}

const mapDispatchToProps = dispatch => {
	return {
		getSocialAuth: bindActionCreators(getSocialAuth, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(ReceiveAuth)
