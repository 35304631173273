let AppNavigator

export function setNavigation(routerHistoryObj) {
	AppNavigator = routerHistoryObj
}

export function goTo(key, data) {
	AppNavigator.push({ pathname: `/${key}`, state: data })
}

export function gotToHome() {
	AppNavigator.push(`/`)
}


export function goToWithQuery(key,query, data) {
	AppNavigator.push({ pathname: `/${key}` , search: query , state: data })
}

export const getQueryParameters = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of queryParams) {
        params[key] = value;
    }

    return params;
};
