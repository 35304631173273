import { Card, Checkbox, Divider, Form, List, Radio, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import AeroImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { FLIGHT_LOG_TYPE, PROCESSING_CAMERA_TYPE, PROCESSING_CAMERA_TYPE_DATA, PROCESSING_TYPE, PROCESSING_TYPE_DATA, PROCESSING_TYPE_ENUMS } from '../constants'
import styles from '../mymaps.module.sass'
import AeroButton from '../../../AeroComponents/Button'
import AeroInput from '../../../AeroComponents/Input'

const ProcessForm = props => {
	const { handleProcessingData, processingId, processingData, activePlan } = props
	const [form] = Form.useForm()
	const [cameraType, setCameraType] = useState(null)
	const [processingType, setProcessingType] = useState(null)
	const [flightLogType, setFlightLogType] = useState(null)
	const [currentMobileDesc, setCurrentMobileDesc] = useState(null)

	useEffect(() => {
		if (processingData) {
			setCameraType(processingData.ab_camera_type)
			setProcessingType(processingData.ab_processing_template)
			setFlightLogType(processingData.ab_georeference_type)
		}
	}, [processingData])

	const recordData = values => {
		const body = {
			processingId: processingId,
			data: {
				ab_camera_type: values.ab_camera_type,
				ab_georeference_type: values.ab_georeference_type,
				ab_processing_template: values.ab_processing_template,
				bl_gcp: values.bl_gcp,
				str_gcp_epsg: values.str_gcp_epsg,
			},
		}
		handleProcessingData(body)
	}

	const handleCameraChange = e => {
		setCameraType(e.target.value)
		if (processingType) {
			form.setFieldsValue({
				ab_processing_template: null,
			})
			setProcessingType(null)
		}
	}
	const handleProcessingChange = e => {
		setProcessingType(e.target.value)
		if (flightLogType) {
			form.setFieldsValue({
				ab_georeference_type: null,
			})
			setFlightLogType(null)
		}
	}
	const handleFlightLogChange = e => setFlightLogType(e.target.value)

	const seeInfoBox = module => {
		return (
			<div onClick={() => setCurrentMobileDesc(prevState => (prevState === module ? null : module))} className={[styles['mobile-view'], styles['see-info-box']].join(' ')}>
				See Info <DownOutlined />
			</div>
		)
	}

	const FLIGHT_LOG_DATA = (
		<>
			<List.Item>
				<List.Item.Meta title='Description:' description={<span style={{ color: '#262626' }}>{FLIGHT_LOG_TYPE[flightLogType]?.desc}</span>} />
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta title='File Type:' description={<span style={{ color: '#262626' }}>{FLIGHT_LOG_TYPE[flightLogType]?.fileType}</span>} />
			</List.Item>
		</>
	)

	const CONTROL_POINT_DATA = (
		<>
			<List.Item>
				<List.Item.Meta
					title='Description:'
					description={
						<div style={{ color: '#262626' }}>
							The control points must be visible, materialized with colored markers or in the shape of an "X" with a minimum diameter of one and a half meters (1.5m X
							1.5m), as shown in the following images. control data obtained from identifiable objects already existing in the area of ​​interest (corners,
							crosswalks, manholes, etc.).
							<div style={{ marginTop: '20px' }}>
								<AeroImage otherstyles={{ marginRight: '20px' }} src={IMAGES_SRC.CONTROL_POINTS_PIC1} alt='cp1' />
								<AeroImage otherstyles={{ marginRight: '20px' }} src={IMAGES_SRC.CONTROL_POINTS_PIC2} alt='cp2' />
								<AeroImage otherstyles={{ marginRight: '20px' }} src={IMAGES_SRC.CONTROL_POINTS_PIC3} alt='cp3' />
								<AeroImage otherstyles={{ marginRight: '20px' }} src={IMAGES_SRC.CONTROL_POINTS_PIC4} alt='cp4' />
							</div>
						</div>
					}
				/>
			</List.Item>
			<Divider></Divider>

			<List.Item>
				<List.Item.Meta
					title='File Type:'
					description={
						<div style={{ color: '#262626' }}>
							.txt file respectively with coordinates in UTM (Name, North, East and Altitude). The decimal place separator must be the '.' (period) and the columns
							must be a ',' (comma).Example of UTM coordinates: ID1, 856930020, 64575021, 122.123
							<div style={{ marginTop: '20px' }}>
								<AeroImage otherstyles={{ marginRight: '20px' }} src={IMAGES_SRC.PROCESS_FILE_TYPE_PIC} alt='pf1' />
							</div>
						</div>
					}
				/>
			</List.Item>
			<Divider></Divider>
			<List.Item>
				<List.Item.Meta title='Usage Rules:' description={<span style={{ color: '#262626' }}>Processing with control points is limited to 20 points.</span>} />
			</List.Item>
			<Divider></Divider>
			<List.Item>
				<List.Item.Meta
					title='Comments:'
					description={
						<div style={{ color: '#262626' }}>
							<ul>
								<li>
									Only the coordinates of the control points that make up the sent processing must be sent, do not send extra coordinates , such as checkpoints or
									other flights;
								</li>
								<li>It is necessary that the coordinates are in the WGS 84 or Sirgas2000 datum;</li>
								<li>Processing products will be delivered in WGS84/UTM regardless of the coordinate system of the inserted control points;</li>
								<li>The sent coordinates must be post-processed. Errors in the checkpoints file will not be corrected;</li>
								<li>Images must face fully downwards (90 degree angle) during flight, oblique photos will not be processed;</li>
								<li>
									The user is responsible for the quality of the data sent as well as the chosen processing settings (operation, collection of control points,
									choice of templates, selection of EPSG, etc).
								</li>
							</ul>
							<div>
								<strong>Aerologix is not responsible for problems in the accuracy and precision of the control points.</strong>
							</div>
						</div>
					}
				/>
			</List.Item>
		</>
	)

	return (
		<Form form={form} layout='vertical' onFinish={recordData}>
			<div className={styles['process-wrapper']}>
				<div className={styles['process-header']}>Camera Type</div>
				<Card style={{ borderRadius: '8px' }}>
					<div className={styles['web-form-input-item']}>
						<Form.Item
							name='ab_camera_type'
							rules={[
								{
									required: true,
									message: 'Camera type is required!',
								},
							]}
							initialValue={processingData?.ab_camera_type}
						>
							<Radio.Group className={styles['mobile-input-items']} onChange={handleCameraChange}>
								<Radio className={styles['web-input-item']} value='RGB'>
									RGB
								</Radio>
								<Radio className={styles['web-input-item']} value='MULTISPECTRAL'>
									Multispectral
								</Radio>
							</Radio.Group>
						</Form.Item>
					</div>
					<span className={styles['web-view']}>{PROCESSING_CAMERA_TYPE[cameraType]}</span>
					<div className={styles['mobile-view']}>
						{cameraType && seeInfoBox('camera')}
						{currentMobileDesc === 'camera' ? <>{PROCESSING_CAMERA_TYPE_DATA[cameraType]}</> : null}
					</div>
				</Card>
			</div>
			<div className={styles['process-wrapper']}>
				<div className={styles['process-header']}>Type of Processing</div>
				<Card style={{ borderRadius: '8px' }}>
					{cameraType && (
						<>
							<div className={styles['web-form-input-item']}>
								<Form.Item
									name='ab_processing_template'
									rules={[
										{
											required: true,
											message: 'Process type is required!',
										},
									]}
									initialValue={processingData?.ab_processing_template}
								>
									<Radio.Group className={styles['mobile-input-items']} onChange={handleProcessingChange}>
										{cameraType === 'RGB' ? (
											<>
												<Radio className={styles['web-input-item']} value='PADRAO_RGB'>
													{PROCESSING_TYPE_ENUMS.PADRAO_RGB}
												</Radio>
												<Radio className={styles['web-input-item']} value='AGRICOLA_RGB'>
													{PROCESSING_TYPE_ENUMS.AGRICOLA_RGB}
												</Radio>
												<Radio className={styles['web-input-item']} value='SILVICULTURA_RGB'>
													{PROCESSING_TYPE_ENUMS.SILVICULTURA_RGB}
												</Radio>
												<Radio className={styles['web-input-item']} value='PADRAO_3D'>
													{PROCESSING_TYPE_ENUMS.PADRAO_3D}
												</Radio>
											</>
										) : cameraType === 'MULTISPECTRAL' ? (
											<Radio className={styles['web-input-item']} value='AGRICOLA_MULTISPECTRAL'>
												Agriculture
											</Radio>
										) : null}
									</Radio.Group>
								</Form.Item>
							</div>
							<span className={styles['web-view']}>{PROCESSING_TYPE[processingType]}</span>
							<div className={styles['mobile-view']}>
								{processingType && seeInfoBox('processing')}
								{currentMobileDesc === 'processing' ? <>{PROCESSING_TYPE_DATA[processingType]}</> : null}
							</div>
						</>
					)}
				</Card>
			</div>
			<div className={styles['process-wrapper']}>
				<div className={styles['process-header']}>Flight Log</div>
				<Card style={{ borderRadius: '8px' }}>
					{processingType && (
						<>
							<div className={styles['web-form-input-item']}>
								<Form.Item
									name='ab_georeference_type'
									rules={[
										{
											required: true,
											message: 'Flight log is required!',
										},
									]}
									initialValue={processingData?.ab_georeference_type}
								>
									<Radio.Group className={styles['mobile-input-items']} onChange={handleFlightLogChange}>
										<Space size={35} direction='vertical'>
											<Radio className={styles['web-input-item']} value='NOT_SET'>
												Georeferenced Photos
											</Radio>
											{processingType === 'AGRICOLA_MULTISPECTRAL' ? null : (
												<Radio className={styles['web-input-item']} value='LAT_LONG'>
													RTK/PPK Flight Log
												</Radio>
											)}
										</Space>
									</Radio.Group>
								</Form.Item>
							</div>
							<span className={styles['web-view']}>
								<div style={{ float: 'left' }}>
									<Divider style={{ marginLeft: '70px', height: '190px' }} type='vertical'></Divider>
								</div>
								<div style={{ display: 'inline-block', width: '50%', marginLeft: '120px' }}>{flightLogType ? FLIGHT_LOG_DATA : null}</div>
							</span>
							<div className={styles['mobile-view']}>
								{flightLogType && seeInfoBox('flightLog')}
								{currentMobileDesc === 'flightLog' ? <>{FLIGHT_LOG_DATA}</> : null}
							</div>
						</>
					)}
				</Card>
			</div>
			{activePlan === 'PRO' || activePlan === 'BUSINESS' ? (
				<div className={styles['process-wrapper']}>
					<div className={styles['process-header']}>Control Points</div>
					<Card style={{ borderRadius: '8px' }}>
						{flightLogType && (
							<>
								<div className={styles['web-form-input-item']}>
									<Form.Item initialValue={processingData?.bl_gcp} name='bl_gcp' valuePropName='checked'>
										<Checkbox>Use control points</Checkbox>
									</Form.Item>
									<Form.Item initialValue={processingData?.str_gcp_epsg} name='str_gcp_epsg' label='Enter EPSG value'>
										<AeroInput otherstyles={{ width: '70px' }} />
									</Form.Item>

									<div>
										EPSG are only valid with <strong>4 or 5 digits</strong>
									</div>
								</div>
								<span className={styles['web-view']}>
									<div style={{ float: 'left' }}>
										<Divider style={{ marginLeft: '70px', height: '1100px' }} type='vertical'></Divider>
									</div>
									<div style={{ display: 'inline-block', width: '60%', marginLeft: '30px' }}>{CONTROL_POINT_DATA}</div>
								</span>
								<div style={{ marginTop: '20px' }} className={styles['mobile-view']}>
									{seeInfoBox('controlPoints')}
									{currentMobileDesc === 'controlPoints' ? CONTROL_POINT_DATA : null}
								</div>
							</>
						)}
					</Card>
				</div>
			) : null}

			<div style={{ textAlign: 'right' }}>
				<AeroButton htmlType='submit' width='170px' type='primary'>
					Next
				</AeroButton>
			</div>
		</Form>
	)
}

export default ProcessForm
