export const SUBSCRIPTION_ITEM_HEADINGS = [
	{
		key: 0,
		marginVal: '22px',
		text: 'Images upto per process',
	},
	{
		key: 1,
		marginVal: '9px',
		text: 'Sharing Capability',
	},
	{
		key: 2,
		marginVal: '30px',
		text: 'Download Capability',
	},
	{
		key: 3,
		marginVal: '27px',
		text: 'Additional GPs charge',
	},
	{
		key: 4,
		marginVal: '27px',
		text: 'Storage limit',
	},
	{
		key: 5,
		marginVal: '22px',
		text: 'Additional storage charge',
	},
	{
		key: 6,
		marginVal: '15px',
		text: 'GCP Processing',
	},
]
