import React, { useEffect, useState } from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { Form, Select } from 'antd'
import AeroButton from '../../AeroComponents/Button'
import AeroInput from '../../AeroComponents/Input'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactCountryFlag from 'react-country-flag'
import { getCountryList, updateProfile, sendEmailOtp } from '../../Stores/UserSession/action'
import { validateEmailAddress } from '../../Services/UtilityService'
import { goTo } from '../../Services/NavigationService'

const SocialLoginData = props => {
	const { countryListData, getCountryList, updateProfile, profileUpdated, callProfile, hasEmail, sendEmailOtp, emailOtpSent } = props
	const [countryList, setCountryList] = useState(null)
	const [waitForApi, setWaitForApi] = useState(false)
	const [showEmail, setShowEmail] = useState(false)
	const [emailValue, setEmailValue] = useState(null)

	useEffect(() => {
		setWaitForApi(true)
		getCountryList()
		const emailArr = hasEmail.split('_')
		if (emailArr && emailArr.length) {
			if (emailArr[0] === 'DUMMY' && emailArr[1] === 'EMAIL' && emailArr[2] === 'FOR' && emailArr[3] === 'SOCIAL' && emailArr[4] === 'MEDIA') {
				setShowEmail(true)
			}
		}
	}, [])

	useEffect(() => {
		if (countryListData && waitForApi) {
			setWaitForApi(false)
			const mappedData = countryListData.filter(item => item.active)
			setCountryList(mappedData)
		}
	}, [countryListData])

	useEffect(() => {
		if (emailOtpSent && waitForApi) {
			setWaitForApi(false)
			goTo('verify-email-otp', { emailValue: emailValue, verificationId: emailOtpSent })
		}
	}, [emailOtpSent])

	const recordValues = values => {
		if (showEmail) setEmailValue(values.email)
		const body = {
			countryId: values.country,
		}
		setWaitForApi(true)
		updateProfile(body)
	}

	useEffect(() => {
		if (profileUpdated && waitForApi) {
			if (showEmail) {
				sendEmailOtp({ email: emailValue })
			} else {
				setWaitForApi(false)
				callProfile()
			}
		}
	}, [profileUpdated])

	return (
		<div style={{ textAlign: 'center', padding: '40px' }}>
			<AeroImage alt='email-country' src={IMAGES_SRC.EMAIL_COUNTRY_ICON} />
			<div className='signup-success-title'>Hi there, welcome to AerologixMaps!!</div>
			<div className='signup-success-subtitle social-email-text'>
				{showEmail ? (
					<>
						We noticed you're logging in with a social platform account linked to a phone number. To provide you with the best experience and keep your account secure,
						we need your email address and country details. <span style={{ fontWeight: '700' }}>Please enter your email address and choose your country below</span>. We
						respect your privacy and will treat your information in line with our Privacy Policy.
					</>
				) : (
					'We need your country details to provide you the best and regional specific experience.'
				)}
			</div>
			<div className='recovery-form'>
				<Form onFinish={recordValues} layout='vertical'>
					{showEmail ? (
						<Form.Item
							rules={[{ required: showEmail, message: 'Please input your email!', whitespace: true }, { validator: validateEmailAddress }]}
							name='email'
							label='Email'
						>
							<AeroInput type='email' placeholder='Type here' />
						</Form.Item>
					) : null}

					<Form.Item rules={[{ required: true, message: 'Please select your country!' }]} name='country' label='Country of Residence'>
						<Select
							size='large'
							showSearch
							placeholder='Type to search'
							optionFilterProp='children'
							filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}
						>
							{countryList?.map(item => (
								<Select.Option key={item.name} value={item.id}>
									<ReactCountryFlag countryCode={item.code} /> <span style={{ marginLeft: '5px' }}>{item.name}</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item style={{ marginTop: '40px' }}>
						<AeroButton htmlType='submit' type='primary' block>
							Continue
						</AeroButton>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		countryListData: state.UserSessionReducer.countryListData,
		profileUpdated: state.UserSessionReducer.profileUpdated,
		emailOtpSent: state.UserSessionReducer.emailOtpSent,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getCountryList: bindActionCreators(getCountryList, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
		sendEmailOtp: bindActionCreators(sendEmailOtp, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SocialLoginData)
