export const GET_ALL_MAPS = 'GET_ALL_MAPS'
export const CREATE_PROCESSING = 'CREATE_PROCESSING'
export const GET_PROCESSING = 'GET_PROCESSING'
export const SHARE_PROCESSING = 'SHARE_PROCESSING'
export const DELETE_PROCESSING = 'DELETE_PROCESSING'
export const UPDATE_PROCESSING = 'UPDATE_PROCESSING'
export const UPLOAD_FLIGHT_LOG_FILE = 'UPLOAD_FLIGHT_LOG_FILE'
export const GET_BOX_CREDS = 'GET_BOX_CREDS'
export const DELETE_FLIGHT_LOG = 'DELETE_FLIGHT_LOG'
export const CHECK_TRANSFER_STATUS = 'CHECK_TRANSFER_STATUS'
export const UPDATE_STAGE = 'UPDATE_STAGE'
export const START_PROCESSING = 'START_PROCESSING'
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const CHARGE_USER = 'CHARGE_USER'
export const UPLOAD_CONTROL_POINTS_FILE = 'UPLOAD_CONTROL_POINTS_FILE'
export const DELETE_CONTROL_POINTS = 'DELETE_CONTROL_POINTS'
