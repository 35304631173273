import { Button } from 'antd'
import React from 'react'

const AeroButton = props => {
	const { width, color, size, height, otherstyles } = props
	return (
		<Button size={size ? size : 'large'} style={{ borderRadius: '8px', width: width, color: color, height: height, ...otherstyles }} {...props}>
			{props.children}
		</Button>
	)
}
export default AeroButton
