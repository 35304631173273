import { PoweroffOutlined } from '@ant-design/icons'
import { Button, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { BTN_LOG_OUT, SIDE_MENU_LIST } from './side-bar-menu-constants'
import AeroImage from '../../AeroComponents/Image'

const WebSidebar = props => {
	const { logOut } = props
	const [currentPathName, setCurrentPathName] = useState('MY_MAPS')
	useEffect(() => {
		const pathFinder = SIDE_MENU_LIST.filter(item => item.pathName === window.location.pathname)
		setCurrentPathName(pathFinder?.length ? pathFinder[0].key : 'MY_MAPS')
	}, [window.location.pathname])

	const changeCurrentPath = e => {
		setCurrentPathName(e.key)
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
			<AeroImage src={IMAGES_SRC.SIDEBAR_LOGO} otherstyles={{ margin: '40px 20px 30px 20px' }} />
			<Menu onClick={changeCurrentPath} selectedKeys={[currentPathName]} mode='inline' theme='light'>
				{SIDE_MENU_LIST.map(menu => (
					<Menu.Item key={menu.key}>
						<NavLink style={{ display: 'flex' }} to={menu.pathName}>
							<AeroImage src={IMAGES_SRC[menu.key === currentPathName ? `${menu.icon}_SELECTED` : menu.icon]} />
							<span style={{ display: 'inline-block', marginLeft: '10px', fontSize: '16px', color: menu.key === currentPathName ? '#00B3FF' : '#84818A' }}>
								{menu.displayValue}
							</span>
						</NavLink>
					</Menu.Item>
				))}
			</Menu>

			<div style={{ position: 'fixed', bottom: '1%' }}>
				<Button
					onClick={() => logOut()}
					type='primary'
					style={{ background: '#FFFFFF', border: '2px solid #00B3FF', borderRadius: '8px', width: '252px', minHeight: '48px', margin: '20px' }}
				>
					<PoweroffOutlined style={{ color: '#1890ff', fontSize: '16px', position: 'relative', bottom: '4px', right: '5px' }} />
					<span style={{ marginLeft: '5px', color: '#1890ff', fontSize: '16px' }}>{BTN_LOG_OUT}</span>
				</Button>
			</div>
		</div>
	)
}

export default WebSidebar
