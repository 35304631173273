import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import store from './store'
import 'sanitize.css/sanitize.css'
import './index.sass'
import App from './App'
import ErrorBoundary from './AeroComponents/ErrorBoundary'
//import MobileScreen from './mobile-screen'

const target = document.querySelector('#root')

render(
	<Provider store={store}>
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_CLIENT_KEY}>
			<>
				{/* <div className='app-screen-mobile'>
					<MobileScreen />
				</div> */}
				<div style={{ height: '100%', backgroundColor: '#FFF' }}>
					<ErrorBoundary>
						<App />
					</ErrorBoundary>
				</div>
			</>
		</StripeProvider>
	</Provider>,
	target,
)
