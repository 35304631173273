import { deleteMethod, get, post, put } from '../../Api/http-interceptor'
import * as ACTION from './actionTypes'

export const getAllMapsApi = queryParams => {
	return get(ACTION.GET_ALL_MAPS, null, queryParams)
}

export const createProcessingApi = body => {
	return post(ACTION.CREATE_PROCESSING, body)
}

export const getProcessingApi = queryParams => {
	return get(ACTION.GET_PROCESSING, null, queryParams)
}

export const shareProcessingApi = body => {
	return post(ACTION.SHARE_PROCESSING, body)
}

export const deleteProcessingApi = queryParams => {
	return deleteMethod(ACTION.DELETE_PROCESSING, null, queryParams)
}

export const updateProcessingApi = body => {
	return put(ACTION.UPDATE_PROCESSING, body)
}

export const uploadFlightLogFileApi = body => {
	return post(ACTION.UPLOAD_FLIGHT_LOG_FILE, body)
}

export const getBoxCredsApi = queryParams => {
	return get(ACTION.GET_BOX_CREDS, null, queryParams)
}

export const deleteFlightLogApi = queryParams => {
	return deleteMethod(ACTION.DELETE_FLIGHT_LOG, null, queryParams)
}

export const checkTransferStatusApi = queryParams => {
	return get(ACTION.CHECK_TRANSFER_STATUS, null, queryParams)
}

export const updateStageApi = body => {
	return put(ACTION.UPDATE_STAGE, body)
}

export const startProcessingApi = body => {
	return post(ACTION.START_PROCESSING, body)
}

export const createTransactionApi = body => {
	return post(ACTION.CREATE_TRANSACTION, body)
}

export const chargeUserApi = body => {
	return post(ACTION.CHARGE_USER, body)
}

export const uploadControlPointsFileApi = body => {
	return post(ACTION.UPLOAD_CONTROL_POINTS_FILE, body)
}

export const deleteControlPointsApi = queryParams => {
	return deleteMethod(ACTION.DELETE_CONTROL_POINTS, null, queryParams)
}
