import React from 'react'
import AeroImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { Form } from 'antd'
import AeroInput from '../../AeroComponents/Input'
import AeroButton from '../../AeroComponents/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validateEmailAddress } from '../../Services/UtilityService'
import { resendVerificationLink } from '../../Stores/UserSession/action'

const EmailVerificationFail = props => {
	const [form] = Form.useForm()

	const recordEmail = values => {
		const body = {
			email: values.email,
		}
		props.resendVerificationLink(body)
	}
	return (
		<div style={{ textAlign: 'center', padding: '40px' }}>
			<AeroImage alt='big-check' src={IMAGES_SRC.BROKEN_LINK} />
			<div className='signup-success-title'>Verification link has expired</div>
			<div style={{ width: 'fit-content', margin: 'auto' }} className='signup-success-footer'>
				<Form form={form} onFinish={recordEmail} layout='vertical'>
					<Form.Item
						name='email'
						rules={[
							{
								required: true,
								message: 'Email is required!',
								whitespace: true,
							},
							{ validator: validateEmailAddress },
						]}
						label='Enter your email'
					>
						<AeroInput placeholder='xx@yy.zz' />
					</Form.Item>
					<Form.Item style={{ marginTop: '20px' }}>
						<AeroButton htmlType='submit' block type='primary'>
							Resend Verification Link
						</AeroButton>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		resendVerificationLink: bindActionCreators(resendVerificationLink, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(EmailVerificationFail)
